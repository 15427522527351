import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ORIGIN;

const drawerWidth = 240;

export default function ConsultingMain() {
  const [open, setOpen] = useState(true);
  const [groups, setGroups] = useState([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [checkedGroups, setCheckedGroups] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const groupresponse = await fetch(`${API_URL}/groups`);
        const groupdata = await groupresponse.json();

        // Add a "전체" folder at the beginning
        const allGroups = [{ groupId: null, groupName: "전체" }, ...groupdata];
        setGroups(allGroups);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleGroupClick = (groupId,groupName) => {
    if (groupId === null) {
      navigate('/AdminConsulting');
    } else {
      navigate(`/FilteredConsulting/${groupId}/${groupName}`);
    }
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleAddGroup = async () => {
    try {
      await axios.post(`${API_URL}/groups`, { groupName: newGroupName });
      setNewGroupName('');
      setAddDialogOpen(false);
      // Refresh the groups list
      const groupresponse = await fetch(`${API_URL}/groups`);
      const groupdata = await groupresponse.json();
      setGroups([{ groupId: null, groupName: "전체" }, ...groupdata]);
    } catch (error) {
      console.error('Error adding group:', error);
    }
  };

  const handleDeleteGroups = async () => {
    try {
      await Promise.all(
        checkedGroups.map(groupId =>
          axios.delete(`${API_URL}/groups`, { data: { groupId } })
        )
      );
      setDeleteDialogOpen(false);
      setCheckedGroups([]);
      // Refresh the groups list
      const groupresponse = await fetch(`${API_URL}/groups`);
      const groupdata = await groupresponse.json();
      setGroups([{ groupId: null, groupName: "전체" }, ...groupdata]);
    } catch (error) {
      console.error('Error deleting groups:', error);
    }
  };

  const handleCheckboxChange = (event) => {
    const groupId = event.target.value;
    if (event.target.checked) {
      setCheckedGroups(prev => [...prev, groupId]);
    } else {
      setCheckedGroups(prev => prev.filter(id => id !== groupId));
    }
  };

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            padding: 3,
            position: 'relative',
          }}
        >
          <Toolbar />
          <Grid container spacing={3}>
            {groups.map((group, index) => (
              <Grid item xs={12} sm={6} md={4} key={group.groupId !== null ? group.groupId : `group-${index}`}>
                <Paper onClick={() => handleGroupClick(group.groupId, group.groupName)} 
                sx={{ display: 'flex', alignItems: 'center', padding: 2 ,    transition: 'box-shadow 0.3s', // 부드러운 전환 효과
                    ':hover': {
                      boxShadow: 10, // 호버 시 그림자 효과 (1 ~ 25 단계로 설정 가능)
                      cursor: 'pointer' // 호버 시 마우스 포인터를 손가락 모양으로 변경
                    }}}>
                  <IconButton >
                    <FolderIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ marginLeft: 2 }}>
                    {group.groupName}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>

          {/* Floating Action Buttons */}
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: 'absolute', bottom: 16, right: 120, width: 100, borderRadius: 5 }}
            onClick={handleAddDialogOpen}
          >
            그룹 생성
          </Fab>
          <Fab
            color="secondary"
            aria-label="delete"
            sx={{ position: 'absolute', bottom: 16, right: 10, width: 100, borderRadius: 5 }}
            onClick={handleDeleteDialogOpen}
          >
            그룹 삭제
          </Fab>

          {/* Add Group Dialog */}
          <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
            <DialogTitle>그룹 추가</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="그룹 이름"
                type="text"
                fullWidth
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddDialogClose}>취소</Button>
              <Button onClick={handleAddGroup} color="primary">생성</Button>
            </DialogActions>
          </Dialog>

          {/* Delete Group Dialog */}
          <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
            <DialogTitle>그룹 삭제</DialogTitle>
            <DialogContent>
              {groups.filter(group => group.groupId !== null).map(group => (
                <FormControlLabel
                  key={group.groupId}
                  control={
                    <Checkbox
                      value={group.groupId}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={group.groupName}
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose}>취소</Button>
              <Button onClick={handleDeleteGroups} color="secondary">삭제</Button>
            </DialogActions>
          </Dialog>

        </Box>
      </Box>
    </ThemeProvider>
  );
}
