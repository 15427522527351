import * as React from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import 'react-data-grid/lib/styles.css';
import { useEffect, useState, useMemo } from 'react';
import Table2 from './CTable2';
import '../../styles/ConsultingTable.css';
import Textarea from '@mui/joy/Textarea';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Window from '../Customer/Window/Window';
import FisaTool from './FisaTool';
import { updatestatus } from '../Utils';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const API_URL = process.env.REACT_APP_API_ORIGIN;

export default function InsightTable() {
  const [rows, setRows] = useState([]);
  const [clientUid, setClientUid] = useState('');
  const [editIdx, setEditIdx] = useState(-1);
  const [open, setOpen] = useState(false); // 제출확인창
  const [openSnackbar, setOpenSnackbar] = useState(false); // 수정완료창
  const navigate = useNavigate();
  const [navopen, setNavOpen] = useState(true);
  const [pdfPage, setPdfPage] = useState(2); // 현재 PDF 페이지를 저장
  const [windowOpen, setWindowOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientId = localStorage.getItem('clientId'); // 현재 보고자하는 uid로 변경해줄것!!!
        const clientUid = await getUidById(clientId);
        setClientUid(clientUid);
        const businessType = await getBusinessType(clientUid);
        const category = businessType.split('_')[0];

        const [response, answerResponse] = await Promise.all([
          fetch(`${API_URL}/api/insight`),
          fetch(`${API_URL}/api/answers/user/uid=${clientUid}`),
        ]);
        const data = await response.json();
        const savedAnswer = await answerResponse.json();
        console.log('savedAnswer', savedAnswer);
        console.log('response', data);

        console.log(category);
        const filtered = data.filter(question => question.questionType.includes(category));
        const questionsWithSavedAnswer = filtered.map((question, index) => {
          const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);
          if (matchingAnswer) {
            console.log(matchingAnswer);
            return {
              ...question,
              id: index + 1,
              answerText: matchingAnswer.answerText,
              asIs: matchingAnswer.asIs,
              keyword: matchingAnswer.keyword,
              insight: matchingAnswer.insight,
            };
          }
          return {
            ...question,
            id: index + 1,
          };
        });
        // 병합된 행을 처리하기 위한 로직
        const mergedRows = questionsWithSavedAnswer.reduce((acc, item, index, array) => {
          // 첫 번째 행이거나 이전 행과 'questionClass' 값이 다른 경우
          if (index === 0 || item.questionClass !== array[index - 1].questionClass) {
            // 'rowspan' 값을 계산
            let rowspan = 1;
            for (let i = index + 1; i < array.length; i++) {
              if (array[i].questionClass === item.questionClass) {
                rowspan++;
              } else {
                break;
              }
            }
            acc.push({ ...item, rowspan: rowspan });
          } else {
            // 이전 행과 'questionClass' 값이 같으면 생략 (병합된 행의 일부)
            acc.push({ ...item, isMerged: true });
          }
          return acc;
        }, []);
        console.log('merged', mergedRows);
        setRows(mergedRows);
        console.log('data', questionsWithSavedAnswer);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const getBusinessType = async uid => {
    try {
      const response = await axios.get(`${API_URL}/auth/getBusinessType`, {
        params: {
          uid: uid,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      // Handle errors
      console.error('Error getting businessType:', error);
    }
  };

  const getUidById = async clientId => {
    try {
      const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
      const clientUid = await clientUidResponse.text();
      return clientUid;
    } catch (error) {
      console.error('Error getting clientId:', error);
    }
  };

  const sendUpdateToServer = async updatedRow => {
    try {
      const updateData = { ...updatedRow, uid: clientUid };

      // uid 속성 추가
      console.log('받은인자', updatedRow);
      console.log('updateData', updateData);

      await fetch(`${API_URL}/api/answers/update`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });
      console.log('updated successfully');
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleEdit = idx => {
    setEditIdx(idx); // 편집할 행의 인덱스를 설정합니다.
  };

  const handleSave = async idx => {
    setEditIdx(-1); // 편집 모드를 종료합니다.
    await sendUpdateToServer(rows[idx]); // 변경된 행을 서버로 전송합니다.
    setOpenSnackbar(true);
  };

  const handleChange = (e, idx, column) => {
    const updatedData = rows.map((row, index) =>
      index === idx ? { ...row, [column]: e.target.value } : row,
    );
    setRows(updatedData); // 키워드 변경사항을 상태에 반영합니다.
  };

  const ConfirmDialog = ({ onClose, onConfirm }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = async () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시

      await onConfirm(); // 실제 작업 수행
      navigate('/ConsultantFile');
      setConfirming(false);
    };
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>저장된 내용들을 토대로 Insight단계 기본 PPT 보고서를 생성하시겠습니까?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="info">PPT가 생성되는데 약 1분이 걸릴 수 있습니다.</Alert>
          </DialogContentText>
          <DialogContentText>
            <Alert severity="info">상세한 질문과 답변에 대한 Insight 분석 자료가 download 됩니다. SOM특성 도출 및 Persona 개발에 사용하십시오.</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
            {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const makePPTOnServer = async () => {
    try {
      updateQuestionsInBatch();
      const create = await axios.post(`${API_URL}/persona/createPersona/uid_number=${clientUid}_1`);
      const create2 = await axios.post(`${API_URL}/persona/createPersona/uid_number=${clientUid}_2`);
      const create3 = await axios.post(`${API_URL}/persona/createPersona/uid_number=${clientUid}_3`);
      const response = await axios.get(`${API_URL}/file/ppt/fillPpt/insight_${clientUid}.pptx`);
      console.log(response.data);
      //updateUserStatus(16);
      updatestatus(clientUid, 'insight', 6);

      await downloadPDF();
      setOpen(false);
    } catch (error) {
      console.error('Error submitting documents:', error);
    }
  };

  const downloadPDF = async () => {
    const input = document.getElementById('hiddenPdfTable'); // 숨겨진 테이블 선택

    if (!input) {
      console.error("PDF table not found!");
      return;
    }

    // 테이블을 일시적으로 표시
    input.style.visibility = 'visible';
    input.style.position = 'relative';
    input.style.top = '0';
    input.style.left = '0';

    try {
      const canvas = await html2canvas(input); // 테이블 캡처
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190; // PDF 너비
      const pageHeight = 295; // A4 페이지 높이
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('table_report.pdf');
      console.log('PDF generated successfully!');
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      // 다시 테이블 숨기기
      input.style.visibility = 'hidden';
      input.style.position = 'absolute';
      input.style.top = '-9999px';
      input.style.left = '-9999px';
    }
  };

  const updateUserStatus = async updateValue => {
    try {
      const response = await axios.post(`${API_URL}/auth/setStatus`, {
        uid: clientUid,
        status: updateValue,
      });
      console.log('userStatus updated successfully');
      localStorage.setItem('userStatus', updateValue);
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  const updateQuestionsInBatch = async () => {
    try {
      // clientUid 1 ~ 10 순차적으로 요청
      for (let i = 0; i < 10; i++) {
        const response = await axios.post(
          `${API_URL}/persona/setPersonaQuestion/question_id=${clientUid}_${i + 1}`, // clientUid 1 ~ 10
          `항목${i + 1}`, // 항목1 ~ 항목10
          {
            headers: {
              'Content-Type': 'text/plain', // Content-Type 명시
            },
          }
        );
        console.log(`Question ${i + 1} updated successfully`, response.data);
      }
    } catch (error) {
      console.error("Error updating questions:", error);
    }
  };
  
  const toggleDrawer = () => {
    setNavOpen(!navopen);
  };

  const handleMenuItemClick = page => {
    setPdfPage(page);
    setWindowOpen(true);
  };

  const defaultTheme = createTheme();

  const analysisToolRows = [0, 3, 4, 6, 8, 12, 13];

  const autoResizeTextarea = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto'; // 높이를 초기화
      textarea.style.height = `${textarea.scrollHeight}px`; // 내용에 맞게 높이 조정
    }
  };
  return (
    <Box sx={{ display: 'flex', fontFamily: "Pretendard-Regular, Arial, Roboto" }}>
      <CssBaseline />
      <Navbar open={navopen} toggleDrawer={toggleDrawer} />
      <LeftNavbar open={navopen} toggleDrawer={toggleDrawer} />

      <Box sx={{ height: '100%', marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 20, backgroundColor: 'white' }}>
        <h2>Insight 단계 보고서 작성</h2>
        <table id="pdfTable" className="CS_MyTable">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>구분</th>
              <th style={{ width: "20%" }}>질문</th>
              <th style={{ width: "20%" }}>답변</th>
              <th className="column-style" style={{ width: "15%" }}>AS-IS 이슈</th>
              <th className="column-style" style={{ width: "20%" }}>Insight</th>
              <th style={{ width: "8%" }}>분석활동 및 도구</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item, idx) => {
              // 병합되어야 할 셀인지 확인
              const isMergedCell = item.rowspan >= 1;
              return (
                <tr key={idx}>
                  {isMergedCell && <td rowSpan={item.rowspan || 1}>{item.questionClass}</td>}
                  <td>{item.question}</td>
                  <td style={{ position: 'relative' }}>
                    <textarea
                      className="editable-textarea-style"
                      value={item.answerText}
                      onChange={e => {
                        handleChange(e, idx, 'answerText')
                        autoResizeTextarea(e.target);
                      }}
                      onBlur={() => handleSave(idx)}
                      ref={textareaRef => autoResizeTextarea(textareaRef)}
                    />
                  </td>
                  <td style={{ position: 'relative' }}>
                    <textarea
                      className="editable-textarea-style"
                      value={item.asIs}
                      onChange={e => {
                        handleChange(e, idx, 'asIs')
                        autoResizeTextarea(e.target);
                      }}
                      onBlur={() => handleSave(idx)}
                      ref={textareaRef => autoResizeTextarea(textareaRef)}
                    />
                  </td>
                  <td style={{ position: 'relative' }}>
                    <textarea
                      className="editable-textarea-style"
                      value={item.insight}
                      onChange={e => {
                        handleChange(e, idx, 'insight')
                        autoResizeTextarea(e.target);
                      }}
                      onBlur={() => handleSave(idx)}
                      ref={textareaRef => autoResizeTextarea(textareaRef)}
                    />
                  </td>
                  <td>
                    {analysisToolRows.includes(idx) && (
                      <Button
                        variant="text"
                        onClick={() => handleMenuItemClick(
                          idx === 0 ? 2 :
                            idx === 3 ? 5 :
                              idx === 4 ? 4 :
                                idx === 6 ? 6 :
                                  idx === 8 ? 3 :
                                    8
                        )}
                        style={{
                          textDecoration: 'underline',
                          color: 'inherit',
                          padding: '4px 8px', // 여백을 설정하여 버튼이 너무 작지 않도록 함
                          minWidth: '80px', // 최소 너비를 설정하여 버튼 크기 조정
                          textAlign: 'center' // 텍스트가 중앙에 오도록 정렬
                        }}
                      >
                        {idx === 0 ? "고객 페르소나 개발" :
                          idx === 3 ? "인접시장 탐색" :
                            idx === 4 ? "고객가치제안 개발" :
                              idx === 6 ? "전략캔버스 분석" :
                                idx === 8 ? "경쟁기반 분석" :
                                  "수익모델 개발"}
                      </Button>
                    )}

                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table id="hiddenPdfTable" style={{
          visibility: 'hidden',
          position: 'absolute',
          top: '-9999px',
          left: '-9999px',
        }}>
          <thead>
            <tr>
              <th style={{ width: "5%" }}>구분</th>
              <th style={{ width: "20%" }}>질문</th>
              <th style={{ width: "20%" }}>답변</th>
              <th className="column-style" style={{ width: "15%" }}>AS-IS 이슈</th>
              <th className="column-style" style={{ width: "20%" }}>Insight</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item, idx) => {
              // 병합되어야 할 셀인지 확인
              const isMergedCell = item.rowspan >= 1;
              return (
                <tr key={idx}>
                  {isMergedCell && <td rowSpan={item.rowspan || 1}>{item.questionClass}</td>}
                  <td>{item.question}</td>
                  <td style={{ position: 'relative' }}>
                    {item.answerText}
                  </td>
                  <td style={{ position: 'relative' }}>
                    {item.asIs}
                  </td>
                  <td style={{ position: 'relative' }}>
                    {item.insight}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>


        <Fab
          color="secondary"
          varient="extended"
          onClick={() => setOpen(true)}
          sx={{
            position: 'fixed',
            width: 100,
            bottom: 30,
            right: 30,
            borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
            boxShadow: '0, 0, 0, 0.5',
          }}
        >
          <span>제출</span>
          <RightArrow />
        </Fab>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" variant="filled" sx={{ width: '100%' }}>
            수정사항이 저장되었습니다!
          </Alert>
        </Snackbar>
        <ConfirmDialog onClose={() => setOpen(false)} onConfirm={makePPTOnServer} />
      </Box>
      {windowOpen && (
        <Window
          id="react-window2"
          height={window.innerHeight / 2}
          width={window.innerWidth / 2}
          left={window.innerWidth / 3}
          top={window.innerHeight / 3}
          resizable={true}
          titleBar={{
            title: 'TAM/SAM 도출 프롬프트 메뉴얼',
            buttons: { minimize: true, maximize: true, close: () => setWindowOpen(!windowOpen) },
          }}
          style={{ backgroundColor: 'white' }}
        >
          <FisaTool page={pdfPage} />
        </Window>
      )}
    </Box>
  );
}