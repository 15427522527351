import React, { useState, useRef, useEffect, memo } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ORIGIN;

function ChatwithGpt({initialMessages = [], externalMessages=[]}) {
    const [messages, setMessages] = useState([...initialMessages]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const chatContainerRef = useRef(null);  // 스크롤 컨테이너 ref

    // 이전 externalMessages 참조를 저장하여 중복 방지
    const prevExternalMessagesRef = useRef([]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);  // 메시지 목록이 변경될 때만 실행

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]); // 메시지 목록이 변경될 때만 실행

    useEffect(() => {
        console.log('externalMessages from ChatwithGpt:', externalMessages);

        if (externalMessages.length > 0) {
            const prevExternalMessages = prevExternalMessagesRef.current;

            // 새로운 메시지들만 필터링
            const newMessages = externalMessages.filter(
                (msg) => !prevExternalMessages.some((prevMsg) => prevMsg.text === msg.text && prevMsg.sender === msg.sender)
            );

            // 중복 방지를 위해 새로운 메시지만 추가
            if (newMessages.length > 0) {
                const formattedMessages = newMessages.map((msg) => ({
                    // text: msg.text,
                    text: renderContent(msg.text),
                    sender: msg.sender,
                    icon: msg.sender === 'user' ? userIcon : botIcon,
                }));
                setMessages((prevMessages) => [...prevMessages, ...formattedMessages]);

                // prevExternalMessagesRef를 최신 상태로 갱신
                prevExternalMessagesRef.current = [...prevExternalMessages, ...newMessages];
            }
        }
    }, [externalMessages]);

    const botIcon = '/gpt_icon.png';
    const userIcon = '/user_icon.png';

    function renderContent(message) {
        // if message starts with [json]:, parse the JSON data and render a table
        if (message.includes("[json]:")) {
            const textResult = message.split('[json]:')[0];
            const tableResult = message.split('[json]:')[1];

            const jsonData = JSON.parse(tableResult);
            console.log('Rendering JSON data:', jsonData);
        
    
            // 'Columns'와 'Data' 배열이 존재하는지 확인
            if (!jsonData || !jsonData.Columns || !jsonData.Data) {
                console.log('Invalid JSON data structure.');
                return null;
            }
        
            const columns = jsonData.Columns;
            const dataRows = jsonData.Data;
        
            console.log('Columns:', columns);
            console.log('Data rows:', dataRows);
        
            return (
                <div>
                <p>{textResult}</p>
                <table border="1" style={{ width: '100%', marginTop: '10px' }}>
                    <thead>
                        <tr>
                            {columns.map((col, index) => (
                                <th key={index} style={{ padding: '8px', border: '1px solid black' }}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataRows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ padding: '8px', border: '1px solid black' }}>{cell}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            );
            }

        if (message.startsWith("[image]:")) {
            const result4 = message.split('[image]:')[1];
            
            // imageUrls 배열에 여러 이미지 URL을 저장
            const imageUrls = result4.split('\n');

            // 이미지 URL 갯수만큼 img 태그 생성
            const images = imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Generated Image ${index}`} />
            ));
            return images;

        }

        if (message.includes("image")) {
            const result4 = message.split('image')[1];

            // imageUrls 배열에 여러 이미지 URL을 저장
            const imageUrls = result4.split('\n');

            // 이미지 URL 갯수만큼 img 태그 생성
            const images = imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Generated Image ${index}`} />
            ));
            return images;
        }

        if (message.includes("[이미지]")) {
            const result4 = message.split('[이미지]')[1];
            
            // imageUrls 배열에 여러 이미지 URL을 저장
            const imageUrls = result4.split('\n');

            // 이미지 URL 갯수만큼 img 태그 생성
            const images = imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Generated Image ${index}`} />
            ));
            return images;
        }

        else {
            return <p>{message}</p>;
        }
    }

    const renderTableFromJson = (jsonData) => {
        console.log('Rendering JSON data:', jsonData);
    
        // 'Columns'와 'Data' 배열이 존재하는지 확인
        if (!jsonData || !jsonData.Columns || !jsonData.Data) {
            console.log('Invalid JSON data structure.');
            return null;
        }
    
        const columns = jsonData.Columns;
        const dataRows = jsonData.Data;
    
        console.log('Columns:', columns);
        console.log('Data rows:', dataRows);
    
        return (
            <table border="1" style={{ width: '100%', marginTop: '10px' }}>
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index} style={{ padding: '8px', border: '1px solid black' }}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataRows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex} style={{ padding: '8px', border: '1px solid black' }}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const sendMessage = async () => {
        if (input.trim() === '') return;
        const userMessage = { text: input, sender: 'user', icon: userIcon };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        setInput('');
        setIsLoading(true);
    
        axios.post(`${API_URL}/ask-chatgpt`, { prompt: input }, {
            headers: {
                'Content-Type': 'application/json',
                'Connection': 'keep-alive',
            },
            withCredentials: true
        })
        .then(response => {
            const textResponse = response.data.choices[0].message.content;
            let componentToRender;

            componentToRender = renderContent(textResponse);
    
            // if (textResponse.includes('요청하신 이미지를 생성했습니다')) {
            //     const imageUrl = textResponse.split('[image]:')[1];
            //     componentToRender = <img src={imageUrl} alt="Generated Content"/>;
            // } else if (textResponse.startsWith("[json]:")) {
            //     // Assuming the response is JSON formatted correctly
            //     try {
            //         const jsonData = JSON.parse(textResponse.replace("[json]:", ""));
            //         console.log('Parsed JSON data:', jsonData);
            //         componentToRender = renderTableFromJson(jsonData);
            //     } catch (error) {
            //         console.log('Error parsing JSON data:', textResponse);
            //         componentToRender = <p>표를 정상적으로 파싱하지 못했습니다.<br/>{textResponse}</p>;
            //     }
            // } else {
            //     componentToRender = <p style={{ background: '#ECECEC', padding: '10px', borderRadius: '20px', display: 'inline-block', maxWidth: '80%' }}>{textResponse}</p>;
            // }
    
            const botMessage = {
                text: componentToRender,
                sender: 'bot',
                icon: botIcon
            };
    
            setMessages(prevMessages => [...prevMessages, botMessage]);
        })
        .catch(error => {
            console.error('Error fetching response: ', error);
            setMessages(prevMessages => [...prevMessages, { text: "서버와 통신 중 오류가 발생하였습니다!", sender: 'bot', icon: botIcon }]);
        }).finally(() => {
            setIsLoading(false);
        });
    };
    

    return (
        <div>
            <div ref={chatContainerRef} style={{ height: '400px', overflowY: 'auto', marginBottom: '10px' }}>
                {messages.map((msg, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start', alignItems: msg.sender === 'user' ? 'flex-end' : 'flex-start', margin: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={msg.icon} alt={`${msg.sender} icon`} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                            <strong>{msg.sender === 'user' ? 'User' : 'Bot'}</strong>
                        </div>
                        <div>
                            {msg.isImage ? (
                                <img src={msg.imageUrl} alt="Generated Content"/>
                            ) : (
                                // if msg.sender is 'user', background color is light green, otherwise no background color
                                msg.sender === 'user' ?
                                <p style={{background: '#DCF8C6', padding: '10px', borderRadius: '20px', display: 'inline-block', maxWidth: '80%' }}>
                                    {msg.text}
                                </p> :
                                <p>
                                    {msg.text}
                                </p>
                            )}
                        </div>
                    </div>
                ))}
                {isLoading && <div><p style={{ background: '#ECECEC', padding: '10px', borderRadius: '20px', display: 'inline-block', maxWidth: '80%' }}>답변 작성 중 (이미지를 생성하는 경우 시간이 다소 소요될 수 있습니다)...</p></div>}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            sendMessage();
                            e.preventDefault();  // 입력 필드에서 엔터를 눌렀을 때의 기본 동작(예: 폼 제출)을 방지
                        }
                    }}
                    style={{ width: 'calc(100% - 90px)', marginRight: '5px', padding: '10px' }}
                />
                <button onClick={sendMessage} style={{ width: '80px', padding: '10px' }}>Send</button>
            </div>
        </div>
    );
}

export default ChatwithGpt;
