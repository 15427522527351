import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment'; // Added
import IconButton from '@mui/material/IconButton'; // Added
import VisibilityOff from '@mui/icons-material/VisibilityOff'; // Added
import Visibility from '@mui/icons-material/Visibility'; // Added
import AuthLogin from './AuthLogin';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const emblem = require('../main/images/emblem.jpg');

// TODO remove, this demo shouldn't need to reset the theme.

/*
export default function SignIn() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };*/

export default function SignIn() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false); // 다이얼로그 열림 여부 상태
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // 다이얼로그 닫기
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    console.log(data.get('email'));
    const user = {
      name: data.get('email'),
      password: data.get('password'),
    };

    console.log(user);

    try {
      // Replace 'YOUR_SPRINGBOOT_API_ENDPOINT' with the actual endpoint of your Spring Boot API
      const response = await Axios.post(`${API_URL}/auth/signin`, user, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data);

      localStorage.setItem('userType', response.data.userType);
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('uid', response.data.uid);
      localStorage.setItem('idToken', response.data.idToken);

      // Move to consultant mainpage
      if (response.data.userType == 'consultant')
        navigate('/ConsultantConsulting');

      // Move to customer mainpage
      else if (response.data.userType == 'client'){
        navigate('/Home');
      }
      
      else if (response.data.userType == 'admin')
        navigate('/mainadmin');

    } catch (error) {
      // Handle errors
      console.error('Error submitting form:', error);
      setOpenDialog(true);
    }
  };

  return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={emblem} alt="Emblem" style={{ maxWidth: '80%', height: '50vh' }} />
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="ID 또는 이메일"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type={showPassword ? "text" : "password"}
              name="password"
              label="비밀번호"
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      onMouseDown={(e) => e.preventDefault()} // 클릭 시 input 포커스 방지
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              로그인
            </Button>
            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to="/forgot" variant="body2">
                  비밀번호를 잊어버리셨나요?
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to="/Agree" variant="body2">
                  {"계정이 없으신가요? 회원가입"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>로그인 실패</DialogTitle>
          <DialogContent>
            <Typography variant="body1">로그인에 실패하였습니다.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
  );
}
