import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { TextField, Paper, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import axios from 'axios';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import styles from '../../styles/factTable.module.css';
const API_URL = process.env.REACT_APP_API_ORIGIN;


const Documents = React.forwardRef(({ questions, moveToNextStep, isQASessionOver }, ref) => {
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedText, setEditedText] = useState('');
  const theme = useTheme();

  const [answerlist, setAnswerlist] = useState([]);
  const [answerStatus, setAnswerStatus] = useState(0);//모두 답변됐는지

  const updatedQuestions = useMemo(() => [...questions], [questions]);
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [saveSpinner, setSaveSpinner] = useState(false);
  const documentRef = useRef(null);



  useEffect(() => {
    //답변한 것만
    console.log("확인", updatedQuestions)
    // console.log("answerStatus",answerStatus)
    setAnswerStatus(0);

    const checkAnswerNumber = updatedQuestions.filter(q => q.answerText && q.answerText.trim() !== '').length;

    // const checkAnswerNumber = (updatedQuestions.filter(q => q.answerText)).length//답변 되어있는 개수 확인

    const submitdata = (updatedQuestions.filter(q => q.uid && q.answerText))// 수정한거 있으면 저장하기 위해
    setAnswerlist(submitdata)
    console.log("개수확인", checkAnswerNumber, updatedQuestions.length)

    if (checkAnswerNumber === updatedQuestions.length) {
      setAnswerStatus(1);
      console.log("호출")
    }
  }, [updatedQuestions, editingIndex])


  const handleEdit = (index) => {
    setEditedText(questions[index].answerText);
    setEditingIndex(index); // 수정 모드로 변경
  };

  const sendUpdatedAnswer = async (updatedQuestion) => {
    try {
      const uid = localStorage.getItem('uid');
      const response = await axios.patch(`${API_URL}/api/answers/update`, {
        uid: uid,
        questionId: updatedQuestion.questionId,
        answerText: updatedQuestion.answerText
      });
      console.log("answer updated successfully")
      // 요청이 성공하면 응답 데이터를 반환
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  const handleSave = (index) => {
    if (!editedText || editedText.trim() === '') {
      updatedQuestions[index].answerText = ''
    }
    else
      updatedQuestions[index].answerText = editedText; // 수정된 내용 반영

    console.log("updatedQuestions", updatedQuestions[index]);

    sendUpdatedAnswer(updatedQuestions[index])

    setEditingIndex(-1); // 수정 모드 종료

  };

  const ConfirmDialog = ({ onClose, onConfirm }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시

      setTimeout(() => {
        onConfirm(); // 실제 작업 수행
        setConfirming(false);
      }, 2000);
    };
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>제출하고 다음단계으로 이동하시겠습니까?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            답변이 최종 저장되고 다시 수정 할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
            {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const AlertDialog = ({ onClose }) => {
    return (
      <Dialog open={showAlert} onClose={onClose}>

        <DialogTitle>답변을 완료한 후 제출해주세요.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning" >
              답변 목록에 빈 답변이 있습니다.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const submitAndMoveNextStep = async () => {
    await saveAnswersToServer();
    classifyResult()
    moveToNextStep();
    setOpen(false);
  }
  const classifyResult = () => {
    console.log("이건 questions", questions)
    console.log("이건 updatedQuestion", updatedQuestions)
  }

  const hadleSaveAnswer = async () => { // 임시 저장버튼
    setSaveSpinner(true)
    await saveAnswersToServer();
    setTimeout(() => {
      setSaveSpinner(false)
      setOpenSnackbar(true)

    }, 1000);
  }

  const handleSubmit = () => { //제출 버튼
    if (answerStatus === 1) {
      setOpen(true)
    }
    else {
      setShowAlert(true)
    }
  };

  const saveAnswersToServer = async () => { //답변 저장 api호출
    try {
      const response = await axios.post(`${API_URL}/api/answers/submit`, answerlist);
      console.log(response.data);


    } catch (error) {
      console.error('Error submitting documents:', error);
    }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  React.useImperativeHandle(ref, () => ({
    submitDocument: () => {
      handleSubmit();
    },
  }));

  useEffect(() => {
    if (editingIndex !== -1) {
      const textField = document.getElementById(`text-field-${editingIndex}`);
      if (textField) {
        textField.focus();  // 포커스를 주고
        const length = textField.value.length;  // 입력 필드의 내용 길이를 구하고
        textField.setSelectionRange(length, length);  // 커서 위치를 맨 뒤로 설정
      }
    }
  }, [editingIndex]);
  
    const calculateRowSpan = (questions) => {
      let rowSpanMap = {};
      questions.forEach((item, index) => {
        if (rowSpanMap[item.questionClass]) {
          rowSpanMap[item.questionClass].count++;
        } else {
          rowSpanMap[item.questionClass] = { count: 1, startIndex: index };
        }
      });
      return rowSpanMap;
    };
  const rowSpanMap = useMemo(() => calculateRowSpan(questions), [questions]);

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '20%' }}>구분</TableCell>
              <TableCell style={{ width: '40%' }}>Question</TableCell>
              {isQASessionOver && <TableCell style={{ width: '40%' }}>Answer</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((item, index) => {
              const { count, startIndex } = rowSpanMap[item.questionClass];
              return (
                <TableRow key={index}>
                  {index === startIndex && (
                    <TableCell rowSpan={count}>{item.questionClass}</TableCell>
                  )}
                  <TableCell>{item.question}</TableCell>
                  {isQASessionOver && <TableCell
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave()}
                    onClick={() => handleEdit(index)}
                    style={{
                      cursor: hoveredIndex === index ? 'pointer' : 'auto',
                      backgroundColor: hoveredIndex === index ? theme.palette.action.hover : 'inherit',
                    }}
                  >
                    {editingIndex === index ? (
                      <TextField
                        fullWidth
                        id={`text-field-${index}`}
                        multiline
                        rows={10}
                        value={editedText}
                        onChange={(e) => setEditedText(e.target.value)}
                        onBlur={() => handleSave(index)}
                        inputProps={{ style: { fontSize: '14px' } }}
                      />
                    ) : (
                      <div>
                        {item.answerText}
                      </div>
                    )}
                  </TableCell>}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Fab
          varient="extended"
          onClick={hadleSaveAnswer}
          sx={{
            color: 'common.white',
            bgcolor: green[500],
            '&:hover': {
              bgcolor: green[600],
            },
            position: 'fixed',
            width: 100,
            bottom: 30,
            right: 150,
            borderRadius: 5,
            boxShadow: '0, 0, 0, 0.5',
          }}
          disabled={saveSpinner}
        >
          {saveSpinner ? <CircularProgress size={24} /> : <span>임시저장</span>}
        </Fab>
        <Fab
          color="secondary"
          varient="extended"
          onClick={handleSubmit}
          sx={{
            position: 'fixed',
            width: 100,
            bottom: 30,
            right: 30,
            borderRadius: 5,
            boxShadow: '0, 0, 0, 0.5',
          }}
        >
          <span>제출</span>
          <RightArrow />
        </Fab>
        <ConfirmDialog onClose={() => setOpen(false)} onConfirm={submitAndMoveNextStep} />
        <AlertDialog onClose={() => setShowAlert(false)} />
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            답변이 저장되었습니다!
          </Alert>
        </Snackbar>
      </div>
    </Paper>
  );
});

export default Documents;
