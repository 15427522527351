// AppBarComponent.js
import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import HomeIcon from '@mui/icons-material/Home';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const defaultTheme = createTheme();

const AppBarComponent = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null); // 상태 추가

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    // 로컬 스토리지 데이터 삭제
    localStorage.clear();
    
    // 로그인 페이지로 리다이렉트
    navigate('/SignIn');
  };

  const handleHomeClick = () => {
    // Home 페이지로 이동
    const userType = localStorage.getItem("userType");
    // Move to consultant mainpage
    if (userType == 'consultant')
      navigate('/ConsultantConsulting');

    // Move to customer mainpage
    else if (userType == 'client') {
      navigate('/Home');
    }

    else if (userType == 'admin') {
      navigate('/MainAdmin');
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (link) => {
    window.open(link, '_blank'); // 새 탭에서 열기
    handleMenuClose(); // 메뉴 닫기
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="absolute" open={open}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <div>
              SteppingStone
            </div>
          </Typography>
          <IconButton color="inherit" onClick={handleHomeClick}>
            <HomeIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <VideoChatIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuItemClick('https://userhub-by.webex.com/webappng/hub/meeting/home')}>
              Webex
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('https://meet.google.com/')}>
              Google Meet
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('https://zoom.us/ko/signin#/login')}>
              Zoom
            </MenuItem>
          </Menu>
          <IconButton color="inherit" onClick={() => handleNavigation('/UserMypage')}>
            <PersonIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleLogout}>
            <Logout />
          </IconButton>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default AppBarComponent;
