import * as React from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import 'react-data-grid/lib/styles.css';
import { useEffect, useState, useRef } from 'react';
import Table2 from './CTable2';
import '../../styles/ConsultingTable.css';
import Textarea from '@mui/joy/Textarea';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Window from '../Customer/Window/Window';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChatwithGpt from './ChatwithGpt';
const API_URL = process.env.REACT_APP_API_ORIGIN;

export default function TamSam() {
    const [rows, setRows] = useState([]);
    const [clientUid, setClientUid] = useState('');
    const [editIdx, setEditIdx] = useState(-1);
    const [open, setOpen] = useState(false);//제출확인창
    const [openSnackbar, setOpenSnackbar] = useState(false);//수정완료창
    const navigate = useNavigate();
    const [businessval, setBusinessVal] = useState("");
    const [navopen, setNavOpen] = useState(true);
    const [whoKeywords, setWhoKeywords]  = useState("null");
    const [whatKeywords, setWhatKeywords]  = useState("null");
    const [howKeywords, setHowKeywords]  = useState("null");
    const [externalMessages, setExternalMessages] = useState([]);
    const [initialMessages, setInitialMessages] = useState([]);
    const [promptFromDB, setPromptFromDB] = useState([]);

  
    //externalMessages가 바뀔 경우 console.log로 확인
    useEffect(() => {
        console.log(externalMessages);
    }, [externalMessages]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientId = localStorage.getItem('clientId');
                const clientUid = await getUidById(clientId);
                setClientUid(clientUid);
    
                const [response, answerResponse, promptResponse] = await Promise.all([
                    fetch(`${API_URL}/api/questions`),
                    fetch(`${API_URL}/api/answers/user/uid=${clientUid}`),
                    fetch(`${API_URL}/api/prompt/usageArea=f`)
                ]);
                const data = await response.json();
                const savedAnswer = await answerResponse.json();
                const promptFromDB = await promptResponse.json();
    
                const businessval2 = await getBusinessType();
                setBusinessVal(businessval2);
    
                const questionsWithSavedAnswer = data.map((question) => {
                    const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);
                    if (matchingAnswer) {
                        if (matchingAnswer.modifiedQuestion && matchingAnswer.modifiedQuestion.trim() !== "") {
                            question.question = matchingAnswer.modifiedQuestion;
                            question.isModified = true;
                        }
                        return {
                            ...question,
                            id: question.questionId,
                            answerText: matchingAnswer.answerText,
                            asIs: matchingAnswer.asIs,
                            keyword: matchingAnswer.keyword,
                        };
                    }
                    return {
                        ...question,
                        id: question.questionId,
                    };
                });
    
                setRows(questionsWithSavedAnswer);
                setPromptFromDB(promptFromDB);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    
    useEffect(() => {
        if (businessval) {  // Only run this effect if businessval is set
            const filteredQuestionsWithSavedAnswer = rows.filter(question => question.questionClass !== "추가질문" || question.isModified);
    
            const whoKeywordsString = calculateWhoKeywordsString(filteredQuestionsWithSavedAnswer, businessval);
            const whatKeywordsString = calculateWhatKeywordsString(filteredQuestionsWithSavedAnswer, businessval);
            const howKeywordsString = calculateHowKeywordsString(filteredQuestionsWithSavedAnswer, businessval);

            const whoAsIsString = calculateWhoAsIsString(filteredQuestionsWithSavedAnswer, businessval);
            const whatAsIsString = calculateWhatAsIsString(filteredQuestionsWithSavedAnswer, businessval);
            const howAsIsString = calculateHowAsIsString(filteredQuestionsWithSavedAnswer, businessval);

            console.log('whoKeywordsString:', whoKeywordsString);

            var generatedPrompt = generatePrompt(promptFromDB.find(prompt => prompt.promptId === 'fact_tamsam_keyword').text, '', whoAsIsString, whatAsIsString, howAsIsString, whoKeywordsString, whatKeywordsString, howKeywordsString);
            console.log('생성한 프롬프트:', generatedPrompt);
            fetch(`${API_URL}/ask-chatgpt`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Connection': 'keep-alive',
                },
                body: JSON.stringify({prompt: generatedPrompt}),
                // body: JSON.stringify({ prompt: `아래의 내용을 고객(시장), 제품(서비스) 그리고 유통(마케팅)에 대해서 1~2개의 핵심 keyword로 요약하시오\n###내용:${whoAsIsString}, ${whatAsIsString}, ${howAsIsString}, ${whoKeywordsString}, ${whatKeywordsString}, ${howKeywordsString}#\n\n##생성 양식(문자 하나하나 아주 엄격하게 준수할 것):\n고객(시장):...\n제품(서비스):,...\n유통(마케팅):...`  }),
                credentials: 'include', // Ensure cookies are sent with the request
              })
              .then(response => response.json())
              .then(data => {
                console.log('생성한 keywords:', data);
                // Display the response to the user

                // 생성한 문자열 중 고객(시장):으로 시작해서 제품(서비스): 이전까지의 문자열을 추출
                const whoKeywords = data.choices[0].message.content.split('고객(시장):')[1].split('제품(서비스):')[0].trim();
                setWhoKeywords(whoKeywords);

                // 생성한 문자열 중 제품(서비스):으로 시작해서 유통(마케팅): 이전까지의 문자열을 추출
                const whatKeywords = data.choices[0].message.content.split('제품(서비스):')[1].split('유통(마케팅):')[0].trim();
                setWhatKeywords(whatKeywords);

                // 생성한 문자열 중 유통(마케팅):으로 시작해서 끝까지의 문자열을 추출
                const howKeywords = data.choices[0].message.content.split('유통(마케팅):')[1].trim();
                setHowKeywords(howKeywords);
              })
              .catch((error) => {
                console.error('Error sending prompt:', error);
              });
              
        }
    }, [businessval, rows]);  // Depend on businessval and rows


    // 각 카테고리의 키워드 문자열을 계산하는 함수 예시
    function calculateWhoKeywordsString(questions, businessval) {
        return questions.filter(q => q.questionClass === "누구(고객/시장)")
            .filter(q => q.questionType === `사업아이디어진단(${businessval})`)
            .map(q => q.keyword)
            .join(", ");
    }

    function calculateWhatKeywordsString(questions, businessval) {
        return questions.filter(q => q.questionClass === "무엇(어떤제품/서비스/가치)")
            .filter(q => q.questionType === `사업아이디어진단(${businessval})`)
            .map(q => q.keyword)
            .join(", ");
    }

    function calculateHowKeywordsString(questions, businessval) {
        return questions.filter(q => q.questionClass === "어떻게 제공(판매) 할 것인가?")
            .filter(q => q.questionType === `사업아이디어진단(${businessval})`)
            .map(q => q.keyword)
            .join(", ");
    }

    function calculateWhoAsIsString(questions, businessval) {
        return questions.filter(q => q.questionClass === "누구(고객/시장)")
            .filter(q => q.questionType === `사업아이디어진단(${businessval})`)
            .map(q => q.asIs)
            .join(", ");
    }

    function calculateWhatAsIsString(questions, businessval) {
        return questions.filter(q => q.questionClass === "무엇(어떤제품/서비스/가치)")
            .filter(q => q.questionType === `사업아이디어진단(${businessval})`)
            .map(q => q.asIs)
            .join(", ");
    }

    function calculateHowAsIsString(questions, businessval) {
        return questions.filter(q => q.questionClass === "어떻게 제공(판매) 할 것인가?")
            .filter(q => q.questionType === `사업아이디어진단(${businessval})`)
            .map(q => q.asIs)
            .join(", ");
    }



    const getUidById = async (clientId) => {
        try {
            const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
            const clientUid = await clientUidResponse.text();
            return clientUid;
        }
        catch (error) {
            console.error('Error getting clientId:', error);
        }
    }

    const sendUpdateToServer = async (updatedRow) => {

        try {
            if (updatedRow.isModified)
                updatedRow = { ...updatedRow, modifiedQuestion: updatedRow.question }

            const updateData = { ...updatedRow, uid: clientUid };

            // uid 속성 추가
            console.log("받은인자", updatedRow);
            console.log("updateData", updateData);

            await fetch(`${API_URL}/api/answers/update`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
            console.log('updated successfully')
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    // const handleEdit = (idx) => {
    //     setEditIdx(idx); // 편집할 행의 인덱스를 설정합니다.
    // };

    // const handleSave = async (idx) => {
    //     setEditIdx(-1); // 편집 모드를 종료합니다.
    //     await sendUpdateToServer(rows[idx]); // 변경된 행을 서버로 전송합니다.
    //     setOpenSnackbar(true);
    // };

    // const handleChange = (e, idx, column) => {
    //     const updatedData = rows.map((row, index) =>
    //         index === idx ? { ...row, [column]: e.target.value } : row
    //     );
    //     setRows(updatedData); // 키워드 변경사항을 상태에 반영합니다.
    // };

    const getBusinessType = async () => {

        const clientId = localStorage.getItem('clientId');//현재 보고자하는 uid로 변경해줄것!!!
        const clientUid = await getUidById(clientId);
        try {
            const response = await axios.get(`${API_URL}/auth/getBusinessType`, {
                params: {
                    uid: clientUid,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(response.data);
            return (response.data);
        } catch (error) {
            // Handle errors
            console.error('Error getting businessType:', error);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [windowOpen, setWindowOpen] = useState(true);
    const [gptWindowOpen, setGptWindowOpen] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (item) => {
        if (item === 'manual') {
            setWindowOpen(!windowOpen); // 프롬프트 메뉴얼 창 열기
        }
        if (item === 'gpt') {
            setGptWindowOpen(!gptWindowOpen);
        }
        handleMenuClose(); // 메뉴 닫기
    };

    const toggleDrawer = () => {
        setNavOpen(!navopen);
    };

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        marginLeft: "10px",
        marginRight: "10px",
    };

    const tableStyle = {
        marginBottom: "20px",
    };

    const [selectedGoal, setSelectedGoal] = useState('TAM');

    const [prompt, setPrompt] = useState('프롬프트 생성 버튼을 눌러 생성해주세요');
    const [prompt2, setPrompt2] = useState('프롬프트 생성 버튼을 눌러 생성해주세요');
    const [newPrompt, setNewPrompt] = useState('');
    const [newPrompt2, setNewPrompt2] = useState('');
    const goalRef = useRef(null);
    const roleRef = useRef(null);
    const [promptResult, setPromptResult] = useState('프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.');
    const [promptResult2, setPromptResult2] = useState('프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.');    


    const setRole = (role) => {
        roleRef.current.value = role;
    };

    function generatePrompt(prompt, role='', whoAsIsString='', whatAsIsString='', howAsIsString='', whoKeywordsString='', whatKeywordsString='', howKeywordsString='', whoKeywords='', whatKeywords='', howKeywords='') {
        prompt = prompt.replace(/\${role}/g, role);
        prompt = prompt.replace(/\${whoAsIs}/g, whoAsIsString);
        prompt = prompt.replace(/\${whatAsIs}/g, whatAsIsString);
        prompt = prompt.replace(/\${howAsIs}/g, howAsIsString);
        prompt = prompt.replace(/\${whoKeywordsString}/g, whoKeywordsString);
        prompt = prompt.replace(/\${whatKeywordsString}/g, whatKeywordsString);
        prompt = prompt.replace(/\${howKeywordsString}/g, howKeywordsString);
        prompt = prompt.replace(/\${whoKeywords}/g, whoKeywords);
        prompt = prompt.replace(/\${whatKeywords}/g, whatKeywords);
        prompt = prompt.replace(/\${howKeywords}/g, howKeywords);
        return prompt;
    }
    
    

    const handleButtonClick = () => {
        const role = roleRef.current.value;
        const goal = goalRef.current.value.trim().toUpperCase();

        if (goal === 'TAM') {
            // const generatedPrompt = `너는 ${role}. 한국에서 ${whoKeywords}를 대상으로 ${whatKeywords} 을 할려고 합니다. 한국에서 ${whatKeywords}의 전체 시장 규모가 얼마인지 알려진 자료를 근거로 알려주세요. 알려진 자료가 없다면 간접자료를 활용하여 페르미 추정을 해줘.`;
            // setPrompt(generatedPrompt);
            
            //fact_tamsam_tam
            //promptFromDB 중 prompt_id가 fact_tamsam_tam
            var selectedPrompt = promptFromDB.find(prompt => prompt.promptId === 'fact_tamsam_tam1');
            setPrompt(generatePrompt(selectedPrompt.text, role, '', '', '', '', '', '', whoKeywords, whatKeywords, howKeywords));


            // const generatedPrompt2 = `한국에서 ${whatKeywords}의 주요 사업자는 누구인지 알려주세요. 그리고 그들의 매출 규모가 얼마인지도 알려주세요.`;
            // setPrompt2(generatedPrompt2);

            selectedPrompt = promptFromDB.find(prompt => prompt.promptId === 'fact_tamsam_tam2');
            setPrompt2(generatePrompt(selectedPrompt.text, role, '', '', '', '', '', '', whoKeywords, whatKeywords, howKeywords));
        }

        else if (goal === 'SAM') {
            // const generatedPrompt = `너는 ${role}. 한국에서 ${whoKeywords}을 대상으로 ${whatKeywords}을 할려고 합니다. ${whatKeywords}은  ${howKeywords}을 통해서 합니다. 목표 규모가 얼마나  
            // 되는지 추정하세오. 그리고 추정 근거를 알려주세오.`;
            // setPrompt(generatedPrompt);

            var selectedPrompt = promptFromDB.find(prompt => prompt.promptId === 'fact_tamsam_sam1');
            setPrompt(generatePrompt(selectedPrompt.text, role, '', '', '', '', '', '', whoKeywords, whatKeywords, howKeywords));

            
            // const generatedPrompt2 = `${whoKeywords}을 대상으로 하는 ${whatKeywords}에 대한  목표의 고객 특징, 경쟁 현황 ,향후 2~3년 시장 전망 그리고 기타 사업 특성 항목 순으로 분석해서 알려줘`;
            // setPrompt2(generatedPrompt2);

            selectedPrompt = promptFromDB.find(prompt => prompt.promptId === 'fact_tamsam_sam2');
            setPrompt2(generatePrompt(selectedPrompt.text, role, '', '', '', '', '', '', whoKeywords, whatKeywords, howKeywords));
        }

        else {
            setPrompt('추천 프롬프트는 현재 목표: TAM과 SAM에 대해서만 지원합니다.');
            setPrompt2('추천 프롬프트는 현재 목표: TAM과 SAM에 대해서만 지원합니다.');
        }
    };

    const handleSendButtonClick = async () => {
        try {
            // 1. 첫 번째 메시지 추가
            setPromptResult('생성을 시작합니다...');
            setPromptResult2('생성을 시작합니다...');
    
            setPromptResult('생성 중...');
            
            const newMessage = {
                text: newPrompt !== '' ? newPrompt : prompt,
                sender: 'user',
                icon: null
            };
            setExternalMessages(prevMessages => [...prevMessages, newMessage]);
    
            // 2. 첫 번째 fetch 요청
            const response1 = await fetch(`${API_URL}/ask-chatgpt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Connection': 'keep-alive',
                },
                body: JSON.stringify({ prompt: newPrompt !== '' ? newPrompt : prompt }),
                credentials: 'include',
            });
            const data1 = await response1.json();
            console.log('Success:', data1);
            const promptResult1 = data1.choices[0].message.content;
            setPromptResult(promptResult1);
    
            // 3. 첫 번째 fetch 결과를 externalMessages에 추가
            const botMessage1 = {
                text: promptResult1,
                sender: 'bot',
                icon: null
            };
            setExternalMessages(prevMessages => [...prevMessages, botMessage1]);
    
            // 4. 두 번째 메시지 추가
            setPromptResult2('생성 중...');
            
            const newMessage2 = {
                text: newPrompt2 !== '' ? newPrompt2 : prompt2,
                sender: 'user',
                icon: null
            };
            setExternalMessages(prevMessages => [...prevMessages, newMessage2]);
    
            // 5. 두 번째 fetch 요청
            const response2 = await fetch(`${API_URL}/ask-chatgpt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Connection': 'keep-alive',
                },
                body: JSON.stringify({ prompt: newPrompt2 !== '' ? newPrompt2 : prompt2 }),
                credentials: 'include',
            });
            const data2 = await response2.json();
            console.log('Success:', data2);
            const promptResult2Text = data2.choices[0].message.content;
            setPromptResult2(promptResult2Text);
    
            // 6. 두 번째 fetch 결과를 externalMessages에 추가
            const botMessage2 = {
                text: promptResult2Text,
                sender: 'bot',
                icon: null
            };
            setExternalMessages(prevMessages => [...prevMessages, botMessage2]);
    
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    

    const defaultTheme = createTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Navbar open={navopen} toggleDrawer={toggleDrawer} />
            <LeftNavbar open={navopen} toggleDrawer={toggleDrawer} initialMessages={initialMessages} externalMessages={externalMessages}/>
            <Box sx={{ height: "100%", marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 20, backgroundColor: 'white' }}>
                {/* <Table2/> */}
                <h2>Fact단계 프롬프트 생성</h2>
                기본으로 생성된 프롬프트를 수정하거나 그대로 Send 버튼을 눌러 챗봇에 보낸 후, 결과를 확인할 수 있습니다.
                {/* <table className="MyTable" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th style={{width: "50%"}}>프롬프트</th>
                            
                            <th style={{width: "50%"}}
                                className='column-style'>
                                    ChatGPT 생성 결과</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{newPrompt != '' ? newPrompt :prompt} </td>
                            <td>{promptResult} </td>
                        </tr>
                        <tr>
                            <td>{newPrompt2 != '' ? newPrompt2 :prompt2} </td>
                            <td>{promptResult2} </td>
                        </tr>
                    </tbody>
                </table> */}

                <Fab color="secondary"
                    varient="extended"
                    onClick={() => navigate('/consultantfile')}
                    sx={{
                        position: 'fixed',
                        width: 100,
                        bottom: 30,
                        right: 30,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>나가기</span>
                    <RightArrow />
                </Fab>
                {/* <Fab color="primary"
                    varient="extended"
                    onClick={handleMenuOpen}
                    sx={{
                        position: 'fixed',
                        width: 100,
                        bottom: 30,
                        right: 140,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>도구</span>
                </Fab> */}
                <Fab color="primary"
                    varient="extended"
                    onClick={() => { window.location.href = `${API_URL}/file/download/fact_${clientUid}.pptx`; }}
                    sx={{
                        position: 'fixed',
                        width: 100,
                        bottom: 30,
                        right: 140,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>Fact pptx <br /> 다운로드</span>
                </Fab>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleMenuItemClick('manual')}>
                        프롬프트 생성기
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('gpt')}>
                        챗 GPT
                    </MenuItem>
                </Menu>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}>
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        수정사항이 저장되었습니다!
                    </Alert>
                </Snackbar>
                {/* {windowOpen && (
                    <Window
                        id="react-window2"
                        height={window.innerHeight / 2}
                        width={1000}
                        left={window.innerWidth / 4}
                        top={window.innerHeight / 2.6}
                        resizable={true}
                        titleBar={{
                            // icon: "R",
                            title: "프롬프트 생성기",
                            buttons: { minimize: true, maximize: true, close: () => setWindowOpen(!windowOpen) }
                        }}
                        style={{ backgroundColor: 'white', height: '100%' }}
                    >
                        <div style={containerStyle}>
                            <table style={tableStyle} className="custom-table">
                                <thead>
                                    <tr>
                                        <th style={{width:"10%"}}>목표</th>
                                        <th style={{width:"25%"}}>역할 정의</th>
                                        <th style={{width:"25%"}}>핵심내용 1</th>
                                        <th style={{width:"25%"}}>핵심내용 2</th>
                                        <th style={{width:"25%"}}>핵심내용 3</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        <input
                                                style={{ width: '90%', alignItems: 'center' }}
                                                type="text"
                                                defaultValue="ex) TAM, SAM"
                                                onChange={(e) => setSelectedGoal(e.target.value)}
                                                ref={goalRef}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                style={{ width: '90%', alignItems: 'center', newline: 'true' }}
                                                type="text"
                                                defaultValue="스타업을 자문하는 시장 분석 전문가"
                                                onChange = {(e) => setRole(e.target.value)}
                                                ref={roleRef}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                style={{ width: '90%', alignItems: 'center' , newline: true }}
                                                type="text"
                                                onChange = {(e) => setWhoKeywords(e.target.value)}
                                                value={whoKeywords != "null" ? whoKeywords : '로딩 중...'}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                style={{ width: '90%', alignItems: 'center' }}
                                                type="text"
                                                onChange = {(e) => setWhatKeywords(e.target.value)}
                                                value={whatKeywords != "null" ? whatKeywords : '로딩 중...'}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                style={{ width: '90%', alignItems: 'center' }}
                                                type="text"
                                                onChange = {(e) => setHowKeywords(e.target.value)}
                                                value={howKeywords != "null" ? howKeywords : '로딩 중...'}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button onClick={handleButtonClick}>프롬프트 생성</button>
                        </div>

                        <div style={containerStyle}>
                            <table style={tableStyle} className="custom-table">
                                <thead>
                                    <tr>
                                        <th style={{width:"50%"}}>추천 프롬프트</th>
                                        <th style={{width:"50%"}}>수정 프롬프트</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{prompt}</td>
                                        <td>
                                            <textarea 
                                            style={{width:"100%", height:"100%"}}
                                            onChange={(e) => setNewPrompt(e.target.value)}
                                            >
                                            {newPrompt}
                                            </textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{prompt2}</td>
                                        <td>
                                            <textarea 
                                            style={{width:"100%", height:"100%"}}
                                            onChange={(e) => setNewPrompt2(e.target.value)}
                                            >
                                            {newPrompt2}
                                            </textarea>
                                            </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button onClick={handleSendButtonClick}>Send</button>
                        </div>
                    </Window>
                )} */}

<div style={{ marginTop: '20px', padding: '20px', backgroundColor: 'white', borderRadius: '8px' }}>
        {/* 첫 번째 테이블: 목표 및 핵심내용 입력 */}
        <div style={containerStyle}>
            <table style={tableStyle} className="custom-table">
                <thead>
                    <tr>
                        <th style={{ width: "10%" }}>목표</th>
                        <th style={{ width: "25%" }}>역할 정의</th>
                        <th style={{ width: "25%" }}>핵심내용 1</th>
                        <th style={{ width: "25%" }}>핵심내용 2</th>
                        <th style={{ width: "25%" }}>핵심내용 3</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                style={{ width: '90%', alignItems: 'center' }}
                                type="text"
                                defaultValue="ex) TAM, SAM"
                                onChange={(e) => setSelectedGoal(e.target.value)}
                                ref={goalRef}
                            />
                        </td>
                        <td>
                            <input
                                style={{ width: '90%', alignItems: 'center', newline: 'true' }}
                                type="text"
                                defaultValue="스타업을 자문하는 시장 분석 전문가"
                                onChange={(e) => setRole(e.target.value)}
                                ref={roleRef}
                            />
                        </td>
                        <td>
                            <input
                                style={{ width: '90%', alignItems: 'center', newline: true }}
                                type="text"
                                onChange={(e) => setWhoKeywords(e.target.value)}
                                value={whoKeywords !== "null" ? whoKeywords : '로딩 중...'}
                            />
                        </td>
                        <td>
                            <input
                                style={{ width: '90%', alignItems: 'center' }}
                                type="text"
                                onChange={(e) => setWhatKeywords(e.target.value)}
                                value={whatKeywords !== "null" ? whatKeywords : '로딩 중...'}
                            />
                        </td>
                        <td>
                            <input
                                style={{ width: '90%', alignItems: 'center' }}
                                type="text"
                                onChange={(e) => setHowKeywords(e.target.value)}
                                value={howKeywords !== "null" ? howKeywords : '로딩 중...'}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <button onClick={handleButtonClick}>프롬프트 생성</button>
        </div>

        {/* 두 번째 테이블: 추천 프롬프트와 수정 프롬프트 입력 */}
        <div style={containerStyle}>
            <table style={tableStyle} className="custom-table">
                <thead>
                    <tr>
                        <th style={{ width: "50%" }}>추천 프롬프트</th>
                        <th style={{ width: "50%" }}>수정 프롬프트</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{prompt}</td>
                        <td>
                            <textarea
                                style={{ width: "100%", height: "100%" }}
                                onChange={(e) => setNewPrompt(e.target.value)}
                            >
                                {newPrompt}
                            </textarea>
                        </td>
                    </tr>
                    <tr>
                        <td>{prompt2}</td>
                        <td>
                            <textarea
                                style={{ width: "100%", height: "100%" }}
                                onChange={(e) => setNewPrompt2(e.target.value)}
                            >
                                {newPrompt2}
                            </textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button onClick={handleSendButtonClick}>Send</button>
        </div>
    </div>
                

            </Box>
        </Box>

    );
}
