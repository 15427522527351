import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
const emblem = require('../main/images/initial.png');


export default function TermsAndConditions() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // States to manage checkbox status
  const [agreedTerms1, setAgreedTerms1] = React.useState(false);
  const [agreedTerms2, setAgreedTerms2] = React.useState(false);
  const [agreedTerms3, setAgreedTerms3] = React.useState(false);

  const handleCheckboxChange = (event) => {
    // Update the corresponding state based on the checkbox
    if (event.target.name === 'terms1') {
      setAgreedTerms1(event.target.checked);
    } else if (event.target.name === 'terms2') {
      setAgreedTerms2(event.target.checked);
    } else if (event.target.name === 'terms3') {
      setAgreedTerms3(event.target.checked);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if both terms are agreed upon
    if (agreedTerms1 && agreedTerms2) {
      navigate('/SignUp');
    } else {
      // Display an error message or prevent form submission
      console.error('Please agree to both terms before proceeding.');
      alert("약관에 동의해주세요.");
    }
  };

  return (
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={emblem} alt="Emblem" style={{ maxWidth: '30%', height: 'auto' }} />
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '60%'}}>
            {/* 첫 번째 약관에 대한 그리드와 체크박스 */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ border: '2px solid #ccc', padding: '10px', marginTop: '5px', maxHeight: '200px', overflowY: 'auto' }}>
                  {/* 실제 약관 내용을 여기에 추가하세요. */}
                  <h4>1. 개인정보의 수집목적 및 이용</h4>
                  스타업 진단 tool은 회원님들이 하고자 하는 사업에 대한 시장성을 진단하여 전략을 수립하고 그를 바탕으로 실행 과제 및 계획을 세우는데 도움을 제공하고자 하는 목적으로 하고 있습니다. 회원님이 제공하신 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며 수집 정보의 범위나 사용 목적, 용도가 변경될 시에는 반드시 회원님께 사전 동의를 구할 것입니다.
                  <h4>2. 개인정보 수집항목 및 보유, 이용기간</h4>
                  스타업 진단 tool 제공을 위해 제공받는 회원정보는 ID(E-mail), 비밀번호, 연락처, 화상 채팅 프로그램, 주소(선택사항) 등입니다. 회원님이 스타업 진단 tool 사용을 하는 동안 받는 회원님의 개인정보는 스테핑스톤에서 계속 보유하며 스타업 진단 tool 제공을 위해 이용하게 됩니다. 단, 탈퇴를 요청하거나 표명된 회원 자격 상실 사유에 의해 회원자격을 제한 및 정지시키는 경우에는 해당 개인의 정보는 재생할 수 없는 기술적 방법에 의해 삭제되며 어떠한 용도로도 열람 또는 이용될 수 없도록 처리됩니다.
                  <h4>3. 개인정보 제공 및 공유</h4>
                  원칙적으로 스테핑스톤은 회원님의 개인정보를 스타업 진단 tool과 무관한 타 기업, 기관에 공개하지 않습니다. 다만, 회원님이 공개에 동의한 경우 또는 스테핑스톤의 이용약관을 위배하거나 스테핑스톤의 스타업 진단 tool을 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 위법행위를 한 사람 등에게 법적인 조치를 취하기 위해 개인 정보를 공개해야 한다고 판단되는 경우에는 예외로 합니다. 스테핑스톤이 회원님의 개인정보를 공유하는 경우는 통계작성, 학술연구, 시장 조사, 정보 제공 및 공지 안내 메일 발송의 경우로서 특정 개인을 식별할 수 없는 형태로 제공되는 경우가 있습니다.
                  <h4>4. 개인 정보 열람, 정정 및 삭제 처리</h4>
                  회원님은 언제든지 제공된 개인 정보를 열람, 정정, 삭제를 요청하실 수 있습니다. 개인 정보 열람 및 정정은 스테핑스톤의 "회원 정보/수정" 메뉴를 선택한 다음 스스로 정정하실 수 있으며 삭제는 “회원 탈퇴” 메뉴를 선택하여 스스로 할 수 있거나 '스테핑스톤' 담당자에게 요청하고 본인임을 확인한 이후에 탈퇴처리를 하고 있습니다. 개인 정보 관련하여 불만이나 의견이 있으신 분은 '스테핑스톤' 개인 정보 관리 담당자에게 의견을 주시면 접수 즉시 조치하고 처리 결과를 통보해 드리겠습니다. 회원님이 사용하고 계시는 ID와 비밀번호는 원칙적으로 회원님만이 사용하도록 되어 있습니다. '스테핑스톤'의 고의 또는 과실이 없는 경우에 회원님의 ID와 비밀번호 도용 또는 기타 타인의 사용으로 의해 발생된 문제에 대하여 '스테핑스톤'이 책임지지 않습니다. 어떠한 경우에도 비밀번호는 타인에게 알려 주지 마시고 로그온(log-on) 상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록 특별한 주의를 기울여 주시기 바랍니다.

                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedTerms1}
                      onChange={handleCheckboxChange}
                      name="terms1"
                      color="primary"
                    />
                  }
                  label="(필수) 개인정보 처리방침에 동의합니다."
                />
              </Grid>
            </Grid>

            {/* 두 번째 약관에 대한 그리드와 체크박스 */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ border: '2px solid #ccc', padding: '10px', marginTop: '5px', maxHeight: '200px', overflowY: 'auto' }}>
                  {/* 실제 약관 내용을 여기에 추가하세요. */}
                  <h3>제1장 총칙</h3>
<h4>제1조 목적</h4>
이 약관은 전기통신사업법령 및 정보통신망이용촉진 등에 관한 법령에 의하여 ㈜스테핑스톤(이하 "스테핑 스톤"이라 합니다)이 제공하는 SteppingStone 스타트업 진단 tool(이하 " 스타업 진단 tool "라 합니다.)의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.<br></br>
<h4>제2조 약관의 효력 및 변경</h4>
1.	본 스타업 진단 tool를 이용하고자 하는 모든 이용자에 대하여 그 효력을 발생합니다.<br></br>
2.	이 약관의 내용은 스타업 진단 tool의 일부 화면 또는 기타 방법 등에 의하여 그 내용을 게재함으로써 그 효력을 발생합니다.<br></br>
3.	스테핑스톤은 운영 또는 영업상 중요한 사유가 있을 경우 본 약관을 임의로 변경할 수 있으며, 변경된 약관은 본 조 제2항과 같은 방법으로 공지함으로써 그 효력을 발생합니다.<br></br>
4.	이용자는 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며, 변경된 약관의 효력 발생일 이후에도 스타업 진단 tool를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주합니다.<br></br>
<h4>제3조 약관 외 준칙</h4>
이 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망이용촉진 등에 관한 법률 및 기타 관련법령, 스테핑스톤이 별도로 정한 지침 등에 의합니다.<br></br>
<h4>제4조 용어의 정의</h4>
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br></br>
1.	회원: 스타업 진단 tool를 제공받기 위하여 스테핑스톤과 이용계약을 체결하고 이용자 아이디를 부여받은 자<br></br>
2.	아이디(ID): 회원 식별과 회원의 스타업 진단 tool  이용을 위하여 회원이 선정하고 스테핑스톤이 승인하는 문자와 숫자의 조합<br></br>
3.	비밀번호: 회원이 부여받은 아이디(ID)와 일치된 회원임을 확인하고 회원의 비밀보호를 위해 회원 자신이 설정한 문자와 숫자의 조합<br></br>
4.	운영자: 스타업 진단 tool의 전반적인 관리와 원활한 운영을 위하여 스테핑스톤에서 선정한 사람<br></br>
5.	해지: 스테핑스톤 또는 회원이 스타업 진단 tool 사용 후 이용계약을 해약하는 것<br></br>
<h4>제5조 스타업 진단 tool의 내용</h4>
스테핑스톤은 회원에게 스테핑스톤이 자체 개발하는 스타업 진단 tool, 타 업체와 협력 개발한 스타업 진단 tool, 타 업체가 개발한 스타업 진단 tool 및 기타 스테핑스톤에서 별도로 정하는 각종 스타업 진단 tool 등을 제공합니다. 단, 스테핑스톤의 사정상 각 스타업 진단 tool 별로 제공일정 및 제공방법이 변경되거나 지연, 미 제공될 수도 있습니다.<br></br>

<h3>제2장 스타업 진단 tool 이용계약</h3>
<h4>제6조 이용계약의 성립</h4>
1.	스타업 진단 tool 가입 신청 시 본 약관을 읽고 "동의함" 단추를 누르면 이 약관에 동의하는 것으로 간주합니다.<br></br>
2.	이용계약은 제7조에 규정한 회원의 이용신청에 대하여 스테핑스톤이 승낙함으로써 성립합니다.<br></br>
<h4>제7조 이용신청</h4>
1.	본 스타업 진단 tool를 이용하기 위해서는 이용신청은 스테핑스톤이 요청하는 소정의 가입신청 양식에서 요구하는 모든 이용자 정보를 기록하여 신청합니다.<br></br>
2.	가입신청 양식에 기재하는 모든 이용자 정보는 모두 실제 데이터인 것으로 간주됩니다. 실명이나 실제 정보를 입력하지 않은 사용자는 법적인 보호를 받을 수 없으며 스타업 진단 tool의 서비스 이용 제한을 받을 수 있습니다.<br></br>
<h4>제8조 회원정보 사용에 대한 동의</h4>
1.	스테핑스톤은 제7조에 따라 가입 신청 양식에 기재된 회원의 개인정보를 본 이용계약의 이행과 본 이용계약상의 스타업 진단 tool 제공을 위한 목적으로 이용합니다.<br></br>
2.	회원 정보는 스테핑스톤과 제휴한 업체에 제공될 수 있습니다. 단, 스테핑스톤은 회원 정보 제공 이전에 제휴 업체, 제공 목적, 제공할 회원 정보의 내용 등을 사전에 공지하고 회원의 동의를 얻어야 합니다.<br></br>
3.	회원은 회원 정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.<br></br>
4.	회원이 가입 신청 양식에 회원정보를 기재하고, 스테핑스톤에 본 약관에 따라 이용신청을 하는 것은 스테핑스톤이 본 약관에 따라 이용신청서에 기재된 회원정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.<br></br>
<h4>제9조 이용신청의 승낙</h4>
1.	스테핑스톤은 회원이 제7조에서 정한 모든 사항을 정확히 기재하여 이용신청을 하였을 때 다음 제2항, 제3항의 경우를 예외로 하여 특별한 사정이 없는 한 접수 순서대로 이용신청을 승낙합니다.<br></br>
2.	스테핑스톤은 다음의 경우 이용신청에 대하여는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.<br></br>
&nbsp;&nbsp;&nbsp;가.	기술상 스타업 진단 tool 제공이 불가능한 경우<br></br>
&nbsp;&nbsp;&nbsp;나.	기타 스테핑스톤의 사정상 이용승낙이 곤란한 경우<br></br>
&nbsp;&nbsp;&nbsp;다.	스테핑스톤은 다음 각 호에 해당하는 이용신청에 대하여는 이를 승낙하지 아니할 수 있습니다.<br></br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A.	다른 사람의 명의를 사용하여 신청한 경우<br></br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;B.	이용 신청 시 필요내용을 허위로 기재하여 신청한 경우<br></br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C.	기타 스테핑스톤이 정한 이용신청요건이 만족되지 않았을 경우<br></br>
<h4>제10조 계약사항의 변경</h4>
회원은 개인정보수정을 통해 언제든지 개인정보를 열람하고 수정할 수 있습니다. 회원은 이용 신청 시 기재한 사항이 변경되었을 경우에는 온라인으로 수정을 해야 하고 미 변경으로 인하여 발생되는 문제의 책임은 회원에게 있습니다.<br></br>

<h3>제3장 계약 당사자의 의무</h3>
<h4>제11조 스테핑스톤의 의무</h4>
1.	스테핑스톤은 제19조, 제21조에서 정한 경우를 제외하고 이 약관에서 정한 바에 따라 계속적, 안정적으로 스타업 진단 tool 를 제공할 수 있도록 지속적으로 노력할 의무가 있습니다.<br></br>
2.	스테핑스톤은 스타업 진단 tool에 관련된 설비를 항상 운용 가능한 상태로 유지보수 해야 하며, 장애가 발생하는 경우 지체 없이 이를 수리 복구할 수 있도록 최선의 노력을 다하여야 합니다.<br></br>
<h4>제12조 사생활의 보호</h4>
1.	스테핑스톤은 스타업 진단 tool 와 관련하여 기득한 회원의 개인정보를 본인의 사전 승낙 없이 타인에게 누설, 공개 또는 배포할 수 없으며, 스타업 진단 tool 관련 업무 이외의 상업적 목적으로 사용할 수 없습니다. 다만, 다음 각 호에 해당하는 경우에는 그러하지 아니합니다.<br></br>
&nbsp;&nbsp;&nbsp;가.	관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구 받은 경우<br></br>
&nbsp;&nbsp;&nbsp;나.	정보통신윤리위원회의 요청이 있는 경우<br></br>
&nbsp;&nbsp;&nbsp;다.	스타업 진단 tool 제공에 따른 스타업 진단 tool 이용계약 이행을 위하여 필요한 경우<br></br>
&nbsp;&nbsp;&nbsp;라.	통계작성, 학술 연구 또는 시장 조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br></br>
2.	제1항의 범위 내에서, 스테핑스톤은 광고업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 이를 사용할 수 있고, 스타업 진단 tool를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있습니다.<br></br>
3.	스테핑스톤은 이용회원의 개인정보를 취급함에 있어서 안정성확보에 필요한 기술적 및 관리적 대책을 강구하여야 합니다.<br></br>
4.	스테핑스톤은 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. 단, 즉시 처리가 곤란한 경우는 회원에게 그 사유와 처리일정을 휴대폰문자, 스타업 진단 tool 또는 이메일를 통해 고지하여야 합니다. 다만, 스테핑스톤의 귀책사유 없이 회원에게 통지할 수 없는 경우는 예외로 합니다.<br></br>
5.	스테핑스톤은 이용 계약의 체결, 계약사항의 변경 및 해지 등 회원의 계약관련 절차 및 내용 등에 있어 회원의 편의를 제공하도록 노력합니다.<br></br>
6.	스테핑스톤은 다음 각 호에 해당하는 경우에 책임을 지지 않습니다.<br></br>
&nbsp;&nbsp;&nbsp;가.	회원이 일부 스타업 진단 tool의 이용목적으로 정보제공자 또는 제3자에게 개별적으로 제공한 개인 정보를 정보제공자 또는 제3자가 누출하거나 이용한 경우<br></br>
 
<h4>제13조 회원의 의무</h4>
1.	회원은 스타업 진단 tool를 이용할 때 다음 각 호에 해당하는 행위를 하지 않아야 합니다.<br></br>
&nbsp;&nbsp;&nbsp;가.	다른 회원의 아이디(ID)와 비밀번호 등을 부정하게 사용하는 행위<br></br>
&nbsp;&nbsp;&nbsp;나.	스타업 진단 tool를 이용하여 얻은 정보를 회원의 개인적인 이용 외에 복사, 가공, 번역, 2차적 저작 등을 통하여 복제, 공연, 방송, 전시, 배포, 출판 등에 사용하거나 제3자에게 제공하는 행위<br></br>
&nbsp;&nbsp;&nbsp;다.	스테핑스톤의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위<br></br>
&nbsp;&nbsp;&nbsp;라.	타인의 명예를 손상시키거나 불이익을 주는 행위<br></br>
&nbsp;&nbsp;&nbsp;마.	공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위<br></br>
&nbsp;&nbsp;&nbsp;바.	범죄와 결부된다고 객관적으로 인정되는 행위<br></br>
&nbsp;&nbsp;&nbsp;사.	스타업 진단 tool 와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위<br></br>
&nbsp;&nbsp;&nbsp;아.	스타업 진단 tool의 안정적 운영을 방해할 수 있는 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 전송하는 행위<br></br>
&nbsp;&nbsp;&nbsp;자.	정보통신윤리위원회, 소비자보호단체 등 공신력 있는 기관으로부터 시정요구를 받는 행위<br></br>
&nbsp;&nbsp;&nbsp;차.	선거관리위원회의 중지, 경고 또는 시정명령을 받는 선거법 위반 행위<br></br>
&nbsp;&nbsp;&nbsp;카.	기타 관계법령에 위배되는 행위<br></br>
2.	회원은 이 약관에서 규정하는 사항과 스타업 진단 tool 이용안내 또는 주의사항을 준수하여야 합니다.<br></br>
3.	회원은 내용별로 스테핑스톤이 스타업 진단 tool 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.<br></br>
4.	회원은 스테핑스톤의 사전 승낙 없이는 스타업 진단 tool를 이용하여 어떠한 영리행위도 할 수 없으며, 영리행위에 의해 발생한 결과에 대하여 스테핑스톤은 책임을 지지 않습니다.<br></br>
5.	회원은 이와 같은 영리행위에 대하여 스테핑스톤에 손해배상의무를 집니다.<br></br>
6.	회원은 스타업 진단 tool의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.<br></br>
7.	회원은 스테핑스톤의 사전승낙 없이는 스타업 진단 tool의 전부 또는 일부 내용 및 기능을 전용할 수 없습니다.<br></br>
<h3>제4장 스타업 진단 tool의 이용</h3>
<h4>제14조 회원 아이디(ID)와 비밀번호 관리에 대한 회원의 의무와 책임</h4>
1.	아이디(ID)와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.<br></br>
2.	자신의 아이디(ID)가 타인에 의해 무단 이용되는 경우 회원은 반드시 스테핑스톤에 그 사실을 통보해야 합니다.<br></br>
3.	회원의 아이디(ID)는 스테핑스톤의 사전 동의 없이 변경할 수 없습니다.<br></br>
 
<h4>제15조 정보의 제공</h4>
스테핑스톤은 회원이 스타업 진단 tool의 이용 및 각종 행사 등의 다양한 스타업 진단 tool관련 내용을 전자우편이나 서신우편, 휴대폰 문자 메세지(SMS), 기타의 방법으로 제공할 수 있습니다. 단, 회원이 원하지 않는 경우 스테핑스톤이 제공하는 정보를 거부할 수 있습니다.<br></br>
<h4>제16조 회원의 게시물</h4>
1.	스테핑스톤은 회원이 게시하거나 전달하는 스타업 진단 tool 내의 모든 내용물이 다음 각 호에 해당된다고 판단되는 경우 사전통지 없이 삭제할 수 있습니다.<br></br>
&nbsp;&nbsp;&nbsp;가.	다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우<br></br>
&nbsp;&nbsp;&nbsp;나.	스타업 진단 tool의 안정적인 운영에 지장을 주거나 줄 우려가 있는 경우<br></br>
&nbsp;&nbsp;&nbsp;다.	공공질서 및 미풍양속에 위반되는 내용인 경우<br></br>
&nbsp;&nbsp;&nbsp;라.	범죄적 행위에 결부된다고 인정되는 내용인 경우<br></br>
&nbsp;&nbsp;&nbsp;마.	스테핑스톤의 지적재산권, 제3자의 지적재산권 등 기타 권리를 침해하는 내용인 경우<br></br>
&nbsp;&nbsp;&nbsp;바.	스테핑스톤에서 규정한 게시기간을 초과한 경우<br></br>
&nbsp;&nbsp;&nbsp;사.	기타 관계법령 및 스테핑스톤의 세부 지침 등에 위반된다고 판단되는 경우<br></br>
2.	스테핑스톤은 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며 회원은 그 지침에 따라 각종 게시물(회원간 전달 포함)을 등록, 삭제 등을 하여야 합니다.<br></br>
<h4>제17조 게시물의 대한 권리 및 책임</h4>
스타업 진단 tool에 게재된 모든 자료에 대한 권리는 다음과 같습니다.<br></br>
1.	게시물에 대한 권리와 책임은 게시자에게 있으며 스테핑스톤은 게시자의 동의 없이는 이를 스타업 진단 tool 내 게재 이외에 영리적 목적으로 사용할 수 없습니다. 단, 비영리적인 경우에는 그러하지 아니하며 스테핑스톤은 타 스타업 진단 tool 내의 게재권을 갖습니다.<br></br>
2.	회원은 스타업 진단 tool를 이용하여 얻은 정보를 가공, 판매하는 행위 등 스타업 진단 tool에 게재된 자료를 스테핑스톤의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 사용할 수 없습니다.<br></br>
<h4>제18조 스타업 진단 tool 이용기간</h4>
1.	스타업 진단 tool의 이용은 스테핑스톤의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검, 긴급조치 등 스테핑스톤이 스타업 진단 tool 점검 및 조치를 필요로 하는 시간은 그러하지 않습니다.<br></br>
2.	스테핑스톤은 스타업 진단 tool를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.<br></br>
<h4>제19조 스타업 진단 tool 이용책임</h4>
회원은 스테핑스톤에서 공식적으로 인정한 경우를 제외하고는 스타업 진단 tool를 이용하여 상품을 판매하는 영업 활동을 할 수 없으며 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용 S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 스테핑스톤이 책임을 지지 않습니다.<br></br>
<h4>제20조 스타업 진단 tool 제공의 중지</h4>
1.	스테핑스톤은 다음 각 호에 해당하는 경우 스타업 진단 tool 제공을 중지할 수 있습니다.<br></br>
&nbsp;&nbsp;&nbsp;가.	스타업 진단 tool 용 설비의 보수 등 공사로 인한 부득이한 경우<br></br>
&nbsp;&nbsp;&nbsp;나.	기타 불가항력적 사유가 있는 경우<br></br>
2.	스테핑스톤은 국가비상사태, 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 스타업 진단 tool 이용에 지장이 있는 때에는 스타업 진단 tool의 전부 또는 일부를 제한하거나 정지할 수 있습니다.<br></br>
<h4>제21조 광고게재 및 광고주와의 거래</h4>
스테핑스톤은 본 스타업 진단 tool 상에 게재되어 있거나 본 스타업 진단 tool를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않습니다.<br></br>
<h4>제22조 링크</h4>
본 스타업 진단 tool 또는 제3자는 월드와이드웹 사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 스테핑스톤은 그러한 사이트 및 자료에 대한 아무런 통제권이 없으므로, 회원은 스테핑스톤이 그와 같은 외부 사이트나 자료를 이용하는 것에 대해 책임이 없으며, 그러한 사이트나 자료에 대한, 또는 그로부터 이용 가능한 내용, 광고, 제품이나 재료에 대해 스테핑스톤이 아무런 보증도 하지 않고, 그에 대해 책임이 없음을 인정하고 이에 동의합니다. 또한, 회원은 그러한 사이트나 자료에 대한, 또는 그를 통하여 이용 가능한 내용, 상품 또는 스타업 진단 tool를 이용하거나 이를 신뢰함으로 인해, 또는 이와 관련하여 야기되거나 야기되었다고 주장되는 어떠한 손해나 손실에 대하여 스테핑스톤은 직접적 또는 간접적으로 책임을 지지 않음을 인정하고 이에 동의합니다.<br></br>

<h3>제5장 계약해지 및 이용제한</h3>
<h4>제23조 계약해지 및 이용제한</h4>
1.	회원이 스타업 진단 tool 이용계약을 해지하고자 할 경우에는 본인이 온라인 또는 스테핑스톤이 정한 별도의 이용방법을 통해 스테핑스톤에 해지 신청을 하여야 합니다.<br></br>
2.	스테핑스톤은 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 스타업 진단 tool 이용을 중지할 수 있습니다.<br></br>
&nbsp;&nbsp;&nbsp;가.	타인의 스타업 진단 tool 아이디(ID) 및 비밀번호를 도용한 경우<br></br>
&nbsp;&nbsp;&nbsp;나.	스타업 진단 tool 운영을 고의로 방해한 경우<br></br>
&nbsp;&nbsp;&nbsp;다.	가입한 이름이 실명이 아닌 경우<br></br>
&nbsp;&nbsp;&nbsp;라.	동일 사용자가 다른 아이디(ID)로 이중등록을 한 경우<br></br>
&nbsp;&nbsp;&nbsp;마.	공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우<br></br>
&nbsp;&nbsp;&nbsp;바.	회원이 국익 또는 사회적 공익을 저해할 목적으로 스타업 진단 tool 이용을 계획 또는 실행하는 경우<br></br>
&nbsp;&nbsp;&nbsp;사.	사. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우<br></br>
&nbsp;&nbsp;&nbsp;아.	스타업 진단 tool의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우<br></br>
&nbsp;&nbsp;&nbsp;자.	통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우<br></br>
&nbsp;&nbsp;&nbsp;차.	스테핑스톤, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우<br></br>
&nbsp;&nbsp;&nbsp;카.	정보통신윤리위원회 등 외부기관의 시정 요구가 있거나 선거관리위원회의 중지, 경고 또는 시정명령을 받는 선거법 위반행위가 있는 경우<br></br>
&nbsp;&nbsp;&nbsp;타.	타인의 개인정보, 회원 아이디(ID) 및 비빌번호를 부정하게 사용하는 경우<br></br>
&nbsp;&nbsp;&nbsp;파.	스테핑스톤의 스타업 진단 tool 정보를 이용하여 얻은 정보를 스테핑스톤의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우<br></br>
&nbsp;&nbsp;&nbsp;하.	관련 법령이나, 본 약관을 포함하여 기타 스테핑스톤이 정한 이용조건에 위반한 경우<br></br>
3.	스테핑스톤은 회원이 이용계약을 체결하여 아이디(ID) 및 비밀번호를 부여 받은 후에라도 회원의 조건에 따른 스타업 진단 tool 이용을 제한할 수 있습니다.<br></br>
4.	본 조 제2항, 제3항의 스테핑스톤조치에 대하여 회원은 스테핑스톤이 정한 절차에 따라 이의신청을 할 수 있습니다.<br></br>
5.	전 항의 이의신청이 정당하다고 스테핑스톤이 인정하는 경우, 스테핑스톤은 즉시 스타업 진단 tool의 이용을 재개해야 합니다.<br></br>

<h3>제6장 손해배상 등</h3>
<h4>제24조 손해배상</h4>
스테핑스톤은 스타업 진단 tool 이용요금이 무료인 동안의 스타업 진단 tool 이용과 관련하여 스테핑스톤의 고의과실에 의한 것이 아닌 한 회원에게 발생한 어떠한 손해에 대하여도 책임을 지지 않습니다. 회원이 스타업 진단 tool를 이용함에 있어 행한 불법행위로 인하여 스테핑스톤이 당해 회원 이외의 제3자로부터 손해배상청구, 소송을 비롯한 각종의 이의제기를 받는 경우 당해 회원은 스테핑스톤의 면책을 위하여 노력하여야 하며, 만일 스테핑스톤이 면책되지 못한 경우는 당해 회원은 그로 인하여 스테핑스톤에 발생한 모든 손해를 배상하여야 합니다.<br></br>
<h4>제25조 손해배상의 청구</h4>
손해배상은 스테핑스톤에 청구사유, 청구금액 및 산출 근거를 기재하여 서면으로 청구하여야 합니다.<br></br>
<h4>제26조 회원에 대한 손해배상</h4>
1.	회원이 이 약관에서 규정한 의무사항을 위반하여 스테핑스톤에 손해를 끼친 경우 스테핑스톤은 해당 회원에 대해 손해 배상을 청구할 수 있습니다.<br></br>
2.	스테핑스톤이 회원에 대해 손해배상을 청구하는 경우의 절차에 대해서는 제25조의 규정을 따릅니다.<br></br>
<h4>제27조 면책사항</h4>
1.	스테핑스톤은 천재지변 또는 이에 준하는 불가항력으로 인하여 스타업 진단 tool를 제공할 수 없는 경우에는 스타업 진단 tool 제공에 관한 책임이 면제됩니다.<br></br>
2.	스테핑스톤은 회원의 귀책사유로 인한 스타업 진단 tool 이용의 장애에 대하여 책임을 지지 않습니다.<br></br>
3.	스테핑스톤은 회원이 스타업 진단 tool 제공으로부터 기대되는 이익을 얻지 못하였거나 스타업 진단 tool 자료에 대한 취사선택 또는 이용으로 인하여 손해가 발생한 경우에 그에 대해 책임을 지지 않습니다.<br></br>
4.	스테핑스톤은 회원이 스타업 진단 tool에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.<br></br>
5.	스테핑스톤은 회원 상호간 또는 회원과 제3자 상호간에 스타업 진단 tool를 매개로 하여 물품거래 등을 한 경우에는 책임을 지지 아니합니다.<br></br>
<h4>제28조 관할법원</h4>
1.	스타업 진단 tool 이용과 관련하여 스테핑스톤과 회원 사이에 분쟁이 발생한 경우, 우선 쌍방간에 분쟁의 해결을 위해 성실히 협의하여야 합니다.<br></br>
2.	스타업 진단 tool 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 스테핑스톤의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.<br></br>


                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedTerms2}
                      onChange={handleCheckboxChange}
                      name="terms2"
                      color="primary"
                    />
                  }
                  label="(필수) 서비스 이용 약관에 동의합니다."
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ border: '2px solid #ccc', padding: '10px', marginTop: '5px', maxHeight: '200px', overflowY: 'auto' }}>
                  {/* 실제 약관 내용을 여기에 추가하세요. */}
                  <h4>개인정보 수집 및 제공(선택)</h4>
                  스테핑스톤 및 제휴사 서비스의 이벤트, 혜택 등의 정보 발송을 위해 제공한 ID(E-mail), 연락처를 동의하에 제공합니다. 
  동의하지 않더라도 스타업 진단 tool과 관련 서비스를 제공받을 수 있습니다.

                  <h4>1. 개인정보의 수집 목적 및 이용</h4>
                  스타업 진단 tool은 회원님들이 하고자 하는 사업에 대한 시장성을 진단하여 전략을 수립하고 그를 바탕으로 실행 과제 및 계획을 세우는데 도움을 제공하고자 하는 목적으로 하고 있습니다. 회원님이 제공하신 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며 수집 정보의 범위나 사용 목적, 용도가 변경될 시에는 반드시 회원님께 사전 동의를 구할 것입니다.                  <h4>3. 개인정보 제공 및 공유</h4>
                  원칙적으로 스테핑스톤은 회원님의 개인정보를 스타업 진단 tool과 무관한 타 기업, 기관에 공개하지 않습니다. 다만, 회원님이 공개에 동의한 경우 또는 스테핑스톤의 이용약관을 위배하거나 스테핑스톤의 스타업 진단 tool을 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 위법행위를 한 사람 등에게 법적인 조치를 취하기 위해 개인 정보를 공개해야 한다고 판단되는 경우에는 예외로 합니다. 스테핑스톤이 회원님의 개인정보를 공유하는 경우는 통계작성, 학술연구, 시장 조사, 정보 제공 및 공지 안내 메일 발송의 경우로서 특정 개인을 식별할 수 없는 형태로 제공되는 경우가 있습니다.
                  <h4>2. 개인 정보 수집항목 및 보유, 이용기간</h4>
                  스타업 진단 tool 제공을 위해 제공받는 회원정보는 ID(E-mail), 비밀번호, 연락처, 화상 채팅 프로그램, 주소(선택사항) 등입니다. 회원님이 스타업 진단 tool 사용을 하는 동안 받는 회원님의 개인정보는 스테핑스톤에서 계속 보유하며 스타업 진단 tool 제공을 위해 이용하게 됩니다. 단, 탈퇴를 요청하거나 표명된 회원 자격 상실 사유에 의해 회원자격을 제한 및 정지시키는 경우에는 해당 개인의 정보는 재생할 수 없는 기술적 방법에 의해 삭제되며 어떠한 용도로도 열람 또는 이용될 수 없도록 처리됩니다.
                  <h4>3. 개인 정보 제공 및 공유</h4>
                  원칙적으로 스테핑스톤은 회원님의 개인정보를 스타업 진단 tool과 무관한 타 기업, 기관에 공개하지 않습니다. 다만, 회원님이 공개에 동의한 경우 또는 스테핑스톤'의 이용약관을 위배하거나 스테핑스톤의 스타업 진단 tool을 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 위법행위를 한 사람 등에게 법적인 조치를 취하기 위해 개인 정보를 공개해야 한다고 판단되는 경우에는 예외로 합니다. 스테핑스톤이 회원님의 개인정보를 공유하는 경우는 통계작성, 학술연구, 시장 조사, 정보 제공 및 공지 안내 메일 발송의 경우로서 특정 개인을 식별할 수 없는 형태로 제공되는 경우가 있습니다.

                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedTerms3}
                      onChange={handleCheckboxChange}
                      name="terms3"
                      color="primary"
                    />
                  }
                  label="(선택) 개인정보 수집 및 제공에 동의합니다."
                />
              </Grid>
            </Grid>

            {/* 제출 버튼 */}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 8 }}>
              제출하기
            </Button>
          </Box>
        </Box>
      </Container>
  );
}
