import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { verifyLogin } from './AuthService'; // authService to verify the token
import { useState, useEffect } from 'react';

const AuthLogin = ({ children, requiredType }) => {
  const location = useLocation();
  const [isValidToken, setIsValidToken] = useState(null);
  const idToken = localStorage.getItem('idToken');
  const uid = localStorage.getItem('uid');

  const getTokenPayload = (token) => {
    const base64Url = token.split('.')[1]; // id_token의 페이로드 부분 추출
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Base64 URL을 일반 Base64로 변환
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const isLoginValid = await verifyLogin(idToken, uid);
        const payload = getTokenPayload(idToken);
        const userType = payload['custom:user_type'];

        if (userType === 'admin' || (isLoginValid && requiredType.includes(userType))) {
          setIsValidToken(true);
          console.log("Login Successful and user type is authorized!");
        } else {
          setIsValidToken(false);
          console.log("UserType: ", userType);
          console.log("Something went wrong with Login or user type not authorized. Please relogin.");
        }
      } catch (error) {
        console.error('Error verifying login:', error);
        setIsValidToken(false);
      }
    };
    checkTokenValidity();
  }, [idToken, uid, requiredType]);

  if (isValidToken === null) {
    return <div></div>; // 로딩 중일 때 빈 div 렌더링
  }

  return isValidToken ? children : <Navigate to='/SignIn' state={{ from: location }} replace />;
};

export default AuthLogin;
