import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { Container, Typography, Divider } from '@mui/material'
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const drawerWidth = 240;

export default function ConsultingEdit() {
  const [open, setOpen] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  
  const [consultantData, setConsultantData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [secondConfirm, setSecondConfirm] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;
  const consulting = state.consulting;
  const consultantid = consulting.consultantId;
  const clientid = consulting.clientId;
  const [clientUid, setClientUid] = useState('');
  console.log(state);

  const deleteConsultingHandler = async () => {
    try {
      await Axios.delete(`${API_URL}/consultant/engagement/consultId=${consulting.consultId}`);
      alert("삭제되었습니다")
      navigate('/AdminConsulting');
    } catch (error) {
      console.error('Error deleting consulting:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`${API_URL}/consultant/getUserList`);
        const data = response.data;

        const consultantData1 = data.filter((user) => user.id === consultantid);
        setConsultantData(consultantData1);

        const clientData1 = data.filter((user) => user.id === clientid);
        setClientData(clientData1);
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const response = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientid}`);
        const data = response.data;
        setClientUid(data);

      } catch (error) {
        console.error('Error fetching client uid:', error);
      }
    };

    fetchData();
  }, [consultantid, clientid]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const defaultTheme = createTheme();

  // const handleConsultantSelect = (status) => {
  //   navigate(`/ProgressConsultant/${status}`);
  //   console.log(status);
  // };
  const handleConsultantSelect = (status) => {
    localStorage.setItem('uid', clientUid);
    localStorage.setItem('clientId', clientid);
    console.log("clientUid", clientUid);
    navigate(`/ConsultantFile`);
  }

  // const handleClientSelect = (status) => {
  //   navigate(`/ProgressUser/${status}`);
  //   console.log(status);
  // };

  const handleClientSelect = (status) => {
    localStorage.setItem('uid', clientUid);
    console.log("clientUid", clientUid);
    navigate(`/Home`);
  }

  const ConfirmDialog = ({ onClose, onConfirm }) => {
    return (
      <Dialog open={openDeleteDialog} onClose={onClose}>
        <DialogTitle>{`정말로 컨설팅을 삭제하시겠습니까?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="error">컨설팅이 삭제되며 복구가 불가능합니다.</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={onConfirm} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const FinalConfirmDialog = ({ onClose, onConfirm }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = async () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
      try {
        await onConfirm();
      } catch (error) {
        console.error('Error during final confirmation:', error);
      }
      setConfirming(false);
      setSecondConfirm(false);
    };

    return (
      <Dialog open={secondConfirm} onClose={onClose}>
        <DialogTitle>{`삭제를 다시 한번 확인해주세요`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="error">컨설팅을 정말로 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSecondConfirm(false)} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
            {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <div>
              <Typography variant="h4" component="h2" gutterBottom>
                컨설팅명: {consulting.businessName}
              </Typography>
              <Divider sx={{ borderBottomWidth: 2, mb: 2 }} />
            </div>

            <Grid container spacing={3}>
              {/* 컨설턴트 그리드 */}
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  컨설턴트
                </Typography>
                {consultantData ? (
                  consultantData
                    .filter((user) => user.user_type === 'consultant')
                    .map((consultantUser) => (
                      <Paper
                      sx={{ p: 2 ,    transition: 'box-shadow 0.3s', // 부드러운 전환 효과
                        ':hover': {
                          boxShadow: 10, // 호버 시 그림자 효과 (1 ~ 25 단계로 설정 가능)
                          cursor: 'pointer' // 호버 시 마우스 포인터를 손가락 모양으로 변경
                        }}}
                        key={consultantUser.id}
                        onClick={() => handleConsultantSelect(consultantUser.status)}
                      >
                        <Typography variant="h6" gutterBottom>
                          {consultantUser.username}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          이메일: {consultantUser.email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          전화번호: {consultantUser.phone_number}
                        </Typography>
                      </Paper>
                    ))
                ) : (
                  <div>Loading...</div>
                )}
              </Grid>
              {/* 고객 그리드 */}
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  고객
                </Typography>
                {clientData ? (
                  clientData
                    .filter((user) => user.user_type === 'client')
                    .map((clientUser) => (
                      <Paper
                        sx={{ p: 2 ,    transition: 'box-shadow 0.3s', // 부드러운 전환 효과
                        ':hover': {
                          boxShadow: 10, // 호버 시 그림자 효과 (1 ~ 25 단계로 설정 가능)
                          cursor: 'pointer' // 호버 시 마우스 포인터를 손가락 모양으로 변경
                        }}}
                        key={clientUser.id}
                        onClick={() => handleClientSelect(clientUser.status)}
                      >
                        <Typography variant="h6" gutterBottom>
                          {clientUser.username}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          이메일: {clientUser.email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          전화번호: {clientUser.phone_number}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          화상 프로그램: {clientUser.program}
                        </Typography>
                      </Paper>
                    ))
                ) : (
                  <div>Loading...</div>
                )}
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <Fab
              color="secondary"
              aria-label="delete"
              sx={{ position: 'absolute', bottom: 40, right: 50, width: 100, borderRadius: 5 }}
              onClick={() => setOpenDeleteDialog(true)}
            >
              컨설팅 삭제
            </Fab>
            <ConfirmDialog
              onClose={() => setOpenDeleteDialog(false)}
              onConfirm={() => {setOpenDeleteDialog(false); setSecondConfirm(true)}}
            />
            <FinalConfirmDialog
              onClose={() => setSecondConfirm(false)}
              onConfirm={deleteConsultingHandler}
            />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
