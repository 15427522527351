import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import InsightsIcon from '@mui/icons-material/Insights';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { useTheme } from '@mui/material/styles';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const API_URL = process.env.REACT_APP_API_ORIGIN;


const Home = () => {
  const uid = localStorage.getItem('uid');
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState('-1'); // 초기 상태값은 0로 설정
  const navigate = useNavigate();
  const theme = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const user = {
    uid: uid
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('user:', user);
        // Replace 'YOUR_SPRINGBOOT_API_ENDPOINT' with the actual endpoint of your Spring Boot API
        const response = await Axios.get(`${API_URL}/auth/getStatus`, {
          params: {
            uid: uid
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Handle the response as needed
        console.log(response.data);
        setStatus(response.data);
        localStorage.setItem('userStatus', response.data);
      } catch (error) {
        // Handle errors
        console.error('Error submitting form:', error);
      }
    };

    // fetchData 함수를 호출하여 비동기 작업 실행
    fetchData();
  }, []); // 빈 배열을 두어 마운트될 때 한 번만 실행되도록 함

  const handleButtonClick = (route) => {
    if (route.check === 99) {
      // 상태가 충분히 높으면 해당 경로로 이동
      navigate(route.path);
    } else {
      // 그 외의 경우 사용자에게 메시지를 표시하거나 다른 작업 수행
      console.log("이 작업을 수행할 수 있는 충분한 권한이 없습니다.");
    }
  };
  const factstatus =  status%100;
  const insightstatus = Math.floor((status % 10000) / 100);
  const strategystatus = Math.floor((status % 1000000) / 10000);
  // const actionstatus = Math.floor(status / 100000);
  const actionstatus = 0;

  const routes = [
    { path: `/FactFile/${uid}`, label: <p>Fact 보고서 <br /> 확인하기</p>, check:factstatus},
    { path: `/InsightFile/${uid}`, label: <p>Insight 보고서 <br /> 확인하기</p>, check:insightstatus},
    { path: `/StrategyFile/${uid}`, label: <p>Strategy 보고서 <br /> 확인하기</p>, check:strategystatus},
    { path: `/ActionDetail/${uid}`, label: <p>Action 보고서 <br /> 확인하기</p>, check:actionstatus},
  ];


  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column', // 세로 방향으로 정렬
            alignItems: 'center', // 가로 중앙 정렬
            height: '100vh',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} style={{ height: '100%' }}>
              {/* 그리드 1: Fact */}
              <Grid item xs={3}>
                <div style={{ textAlign: 'center' }}>
                  <TroubleshootIcon style={{ fontSize: '10vw', maxWidth: '200px', margin: '0 auto' }} />
                  <h3>Fact</h3>

                  {/* 사업 / 시장 유형 진단 */}
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: factstatus >= 1 && factstatus < 2 ? theme.palette.primary.main : 
                      factstatus >= 2 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: factstatus >= 1 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: factstatus >= 1 && factstatus < 2 ? 'pointer' : 'default'
                    }}
                    onClick={() => {
                      if (factstatus >= 1 && factstatus < 2) {
                        if (factstatus === 1) {
                          navigate('/FactDetail');
                        } else if (factstatus> 1) {
                          navigate('/MainCustomer');
                        }
                      }
                    }}
                  >
                    사업 / 시장 유형 진단
                  </p>

                  {/* 사업아이디어 진단 */}
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: factstatus >= 3 && factstatus < 4 ? theme.palette.primary.main : 
                      factstatus >= 4 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: factstatus >= 3 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: factstatus >= 3 && factstatus < 4 ? 'pointer' : 'default'
                    }}
                    onClick={() => factstatus >= 3 && factstatus < 4 && navigate('/MainCustomer')}
                  >
                    사업아이디어 진단
                  </p>
                </div>
              </Grid>

              {/* 그리드 2: Insight */}
              <Grid item xs={3}>
                <div style={{ textAlign: 'center' }}>
                  <InsightsIcon style={{ fontSize: '10vw', maxWidth: '200px', margin: '0 auto' }} />
                  <h3>Insight</h3>

                  {/* SOM 후보 도출 */}
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: insightstatus >= 1 && insightstatus < 5 ? theme.palette.primary.main : 
                      insightstatus >= 5 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: insightstatus >= 1 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: insightstatus >= 1 && insightstatus < 5 ? 'pointer' : 'default'
                    }}
                    onClick={() => {
                      if (insightstatus >= 1 && insightstatus < 5) {
                        if (insightstatus === 1) {
                          navigate('/InsightDetail');
                        } else if (insightstatus > 1) {
                          navigate('/MainInsight');
                        }
                      }
                    }}
                  >
                    SOM 후보 도출
                  </p>
                </div>
              </Grid>

              {/* 그리드 3: Strategy */}
              <Grid item xs={3}>
                {/* 여기에 Strategy 컴포넌트 또는 내용을 추가 */}
                <div style={{ textAlign: 'center' }}>
                  <EmojiObjectsIcon style={{ fontSize: '10vw', maxWidth: '200px', margin: '0 auto' }} />
                  <h3>Strategy</h3>
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: strategystatus >= 1 && strategystatus < 2 ? theme.palette.primary.main : 
                      strategystatus >= 2 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: strategystatus >= 1 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: strategystatus >= 1 && strategystatus < 2 ? 'pointer' : 'default'
                    }}
                    onClick={() => strategystatus >= 1 && strategystatus < 2 && navigate('/evaluatepersona')}
                  >
                    SOM 후보 평가 & <br/>최종 SOM 구체화
                  </p>
                  <p
                    style={{
                      padding: '10px',
                      backgroundColor: strategystatus >= 2 && strategystatus < 99 ? theme.palette.primary.main : 
                      strategystatus >= 99 ? '#75a7db' : theme.palette.grey[300],
                      borderRadius: '10px',
                      boxShadow: theme.shadows[2],
                      color: strategystatus >= 2 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                      transition: 'background-color 0.3s, color 0.3s',
                      cursor: strategystatus >= 2 && strategystatus < 99 ? 'pointer' : 'default'
                    }}
                    onClick={() => strategystatus >= 2 && strategystatus < 99 && navigate('/strategydetail')}
                  >
                    GTM 전략
                  </p>
                </div>
              </Grid>

              {/* 그리드 4: Action */}
              <Grid item xs={3} style={{ cursor: actionstatus > 0 ? 'pointer' : 'default' }} onClick={() => actionstatus > 0 && navigate('/ActionDetail')}>
                {/* 여기에 Action 컴포넌트 또는 내용을 추가 */}
                <div style={{ textAlign: 'center' }}>
                  <PlayCircleFilledWhiteIcon style={{ fontSize: '10vw', maxWidth: '120px', margin: '0 auto' }} />
                  <h3>Action</h3>
                  <p style={{
                    padding: '10px',
                    backgroundColor: actionstatus > 0 ? theme.palette.primary.main : theme.palette.grey[300],
                    borderRadius: '10px',
                    boxShadow: theme.shadows[2],
                    color: actionstatus > 0 ? theme.palette.primary.contrastText : theme.palette.text.primary,
                    transition: 'background-color 0.3s, color 0.3s'
                  }}>GTM 실행 과제 수립</p>
                </div>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} style={{ height: '100%' }}>
              {routes.map((route, index) => (
                <Grid item xs={3} key={index}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={route.check < 99}
                    onClick={() => handleButtonClick(route)}
                    style={{
                      height: '100%',
                      cursor: route.check === 99 ? 'pointer': 'not-allowed',
                      boxShadow: route.check === 99 ? theme.shadows[5] : 'none',
                      backgroundColor: route.check === 99 ? theme.palette.primary.main : theme.palette.grey[300],
                      color: route.check === 99 ? theme.palette.primary.contrastText : theme.palette.text.primary
                    }}
                  >
                    {route.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Box>
  );
};

export default Home;
