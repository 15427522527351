import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Chat from './Chat_insight';
import Documents from './Document';
import Toolbar from '@mui/material/Toolbar';
import Window from './Window/Window';
import '../../styles/ProgressBar.css'
import { Typography, Divider } from '@mui/material';
import axios from 'axios';
import { updatestatus } from '../Utils'


const API_URL = process.env.REACT_APP_API_ORIGIN;



const MainInsight = () => {
  const uid = localStorage.getItem('uid');
  const [open, setOpen] = useState(true);

  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [totalFilteredQuestions, setTotalFilteredQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [preMessage, setPreMessage] = useState([]);
  const [isQASessionOver, setIsQASessionOver] = React.useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [userStatus, setUserStatus] = React.useState(Math.floor((parseInt(localStorage.getItem('userStatus')) % 10000) / 100));
  console.log(userStatus);
  const navigate = useNavigate();
  const [questionList, setQuestionList] = useState([]);
  const [questionList1, setQuestionList1] = useState([]);
  const [questionList2, setQuestionList2] = useState([]);
  const [questionList3, setQuestionList3] = useState([]);
  const [questionList4, setQuestionList4] = useState([]);
  const [question1Index, setQuestion1Index] = useState(0);
  const [question2Index, setQuestion2Index] = useState(0);
  const [question3Index, setQuestion3Index] = useState(0);
  const [question4Index, setQuestion4Index] = useState(0);
  const [userData, setUserData] = useState(null);
  const infoMessage = [
    "고객님께서 하고자 하는 사업이 어떤 고객과 시장을 대상으로 하고 무엇을 어떻게 제공할 것인지 그리고 어떻게 수익을 창출할 것인지에 대해서 알고자 합니다. 먼저 어떤 고객과 시장을 대상으로 하는지 알고자 합니다.",
    "다음으로 목표 고객/시장에 무엇(제품/서비스)을 제공할 것인지에 대해서 알고자 합니다.",
    "마지막으로 목표 고객/시장에 어떻게 제공하고 수익을 창출할 것인지에 대해서 알고자 합니다."
  ];

  const documentRef = useRef(null);

  const sendEmails = async (body) => {
    console.log(userData);
    if (userData) {
      try {
        for (const user of userData) {
          const emailData = new URLSearchParams();
          emailData.append('from', 'FISA@stepstonekr.com');
          emailData.append('to', user.email);
          emailData.append('subject', '알림');
          emailData.append('body', body);

          await axios.post(`${API_URL}/api/email/send`, emailData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
        }
        console.log('Emails sent successfully');
      } catch (error) {
        console.error('Error sending emails:', error);
      }
    }
  };


  const moveToNextStep = async () => {
    if (currentStep === 3) { // profit_밸류체인
      await updatestatus(uid, 'insight', 5);
      // 이중 배열이 아닌 단일 배열로 설정
      setTotalFilteredQuestions([
        ...questionList1,
        ...questionList2,
        ...questionList3,
        ...questionList4
      ]);
      sendEmails('고객의 insight 답변이 완료되었습니다. 확인해주세요. <br><br> 이 이메일은 발신 전용 이메일 입니다. 회신하시더라도 답변되지 않습니다.');
      fillInsightKeywordWithGpt();
      navigate("/InsightReport");
      return;
    }
    if (currentStep < 3) {
      if (userStatus < currentStep + 2) { // 최초 접근 -> status 업데이트
        await updatestatus(uid, 'insight', userStatus + 1);
        setUserStatus(userStatus + 1);
      }
      switch (currentStep) {
        case 0:
          setFilteredQuestions(questionList2);
          setCurrentQuestionIndex(question2Index);
          setIsQASessionOver(question2Index >= questionList2.length - 1);
          break;
        case 1:
          console.log("question3index",question3Index);
          setFilteredQuestions(questionList3);
          setCurrentQuestionIndex(question3Index);
          setIsQASessionOver(question3Index >= questionList3.length - 1);
          break;
        case 2:
          console.log("question4index",question4Index);
          setFilteredQuestions(questionList4);
          setCurrentQuestionIndex(question4Index);
          setIsQASessionOver(question4Index >= questionList4.length - 1);
          break;
      }
      setCurrentStep(currentStep + 1);
    }
  };


  const handleStepClick = (step) => {
    let questionList, questionIndex;

    if (step === 0) {
      questionList = questionList1;
      questionIndex = question1Index;
    } else if (step === 1) {
      questionList = questionList2;
      questionIndex = question2Index;
    } else if (step === 2) {
      questionList = questionList3;
      questionIndex = question3Index;
    } else if (step === 3) {
      questionList = questionList4;
      questionIndex = question4Index;
    }
    console.log("바뀔 step", step)
    console.log("바뀔 질문리스트", questionList)
    setFilteredQuestions(questionList);
    setCurrentQuestionIndex(questionIndex);
    setIsQASessionOver(questionList.every(item => 'answerText' in item));
    // setIsQASessionOver(questionIndex >= questionList.length - 1);
    setCurrentStep(step);
  };


  const toggleDrawer = () => {
    setOpen(!open);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        let questionTypeList = []
        const uid = localStorage.getItem('uid');
        const businessTypeData = await getBusinessType();
        if (businessTypeData.includes('밸류체인')) {
          questionTypeList = ['who_밸류체인', 'what_밸류체인', 'how_밸류체인', 'profit_밸류체인'];
        }
        else {
          questionTypeList = ['who_플랫폼', 'what_플랫폼', 'how_플랫폼', 'profit_플랫폼'];
        }
        const [response, answerResponse] = await Promise.all([
          fetch(`${API_URL}/api/insight`),
          fetch(`${API_URL}/api/answers/user/uid=${uid}`),
        ]);
        const data = await response.json();
        const savedAnswer = await answerResponse.json();
        console.log("questions", data);
        console.log("savedAnswer", savedAnswer);

        const engageresponse = await axios.get(`${API_URL}/consultant/getConsultEngagement/clientUid=${uid}`);
        console.log(engageresponse);

        // Assuming response.data contains an array of consultants
        const consultantIds = new Set(engageresponse.data.map(item => item.consultantId));

        const userdata = await fetch(`${API_URL}/consultant/getUserList`);
        const engagedata = await userdata.json();
        console.log(engagedata);

        // Filter to keep only users with ids in the consultantIds set
        const filteredUsers = engagedata.filter(user => consultantIds.has(user.id));
        setUserData(filteredUsers);


        const questionsWithSavedAnswer = data.map(question => {
          const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);

          if (matchingAnswer) {
            return {
              ...question,
              answerText: matchingAnswer.answerText,
            };
          }

          return question;
        });

        let questionData = [];
        let questionData1 = [];
        let questionData2 = [];
        let questionData3 = [];
        let questionData4 = [];
        let totalQuestionData = [];
        let initialStep = 0;

        const statusData = userStatus;
        console.log("status", statusData)
        // if (statusData>=4)
        // {
        //   setCurrentStep(3);
        //   initialStep = 3;
        // }
        // else if(statusData === 3){
        //   setCurrentStep(2);
        //   initialStep = 2;
        // }
        // else if(statusData === 2){
        //   setCurrentStep(1);
        //   initialStep = 1;
        // }
        // else if(statusData === 1){
        //   setCurrentStep(0);
        //   initialStep = 0;
        // }
        setCurrentStep(0);

        questionsWithSavedAnswer.forEach(question => {
          if (question.questionType === questionTypeList[0]) {
            questionData1.push(question);
            questionData.push(question);
          } else if (question.questionType === questionTypeList[1]) {
            questionData2.push(question);
            questionData.push(question);
          } else if (question.questionType === questionTypeList[2]) {
            questionData3.push(question);
            questionData.push(question);
          } else if (question.questionType === questionTypeList[3]) {
            questionData4.push(question);
            questionData.push(question);
          }
          totalQuestionData.push(question);
        });

        console.log("list3", questionData3);
        console.log("list4", questionData4);
        console.log("questionData", questionData);

        setQuestionList(questionData);
        setQuestionList1(questionData1);
        setQuestionList2(questionData2);
        setQuestionList3(questionData3);
        setQuestionList4(questionData4);
        setTotalFilteredQuestions(totalQuestionData);

        //단계 구분 있이 질문 답변할때
        if (initialStep === 0) {
          setFilteredQuestions(questionData1);
          setIsQASessionOver(questionData1.every(item => 'answerText' in item));
        }
        else if (initialStep === 1) {
          setFilteredQuestions(questionData2);
          setIsQASessionOver(questionData2.every(item => 'answerText' in item));

        }
        else if (initialStep === 2) {
          setFilteredQuestions(questionData3);
          setIsQASessionOver(questionData3.every(item => 'answerText' in item));
        }
        else if (initialStep === 3) {
          setFilteredQuestions(questionData4);
          setIsQASessionOver(questionData4.every(item => 'answerText' in item));
        }

        //단계 구분없이 한번에 질문 답변할때
        setFilteredQuestions(questionData);
        setIsQASessionOver(questionData.every(item => 'answerText' in item));


        // 이전의 저장된 답변있을 경우 chat형식으로 데이터 변환
        const preMessages = generateSavedChatMessages(questionData);
        console.log("preMessages", preMessages);
        setPreMessage(preMessages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // useEffect가 마운트 될 때 한 번만 호출

  const generateSavedChatMessages = (questionData) => {
    let preMessage = [];
    let step = 0;
    let previousQuestionClass = null;

    // 첫 번째 infoMessage 추가
    preMessage.push({
      isUser: false,
      step: step,
      text: infoMessage[step]
    });

    // questionData 순회
    for (let i = 0; i < questionData.length; i++) {
      const item = questionData[i];
      if (!('answerText' in item)) {
        setCurrentQuestionIndex(i);
        setCurrentQuestion(questionData[i]);
        setCurrentStep(step);
        break; // 반복문 종료
      }
      // questionClass가 변경되었는지 확인
      if (previousQuestionClass !== item.questionClass) {
        if (previousQuestionClass !== null) {
          // step 변경 시 새로운 infoMessage 추가
          step++;
          if (step != 3) {
            preMessage.push({
              isUser: false,
              step: step,
              text: infoMessage[step]
            });
          }
        }
        previousQuestionClass = item.questionClass; // questionClass 업데이트
      }
      if (i == questionData.length - 1)
        setCurrentStep(3);

      // 질문 추가
      preMessage.push({
        isUser: false,
        step: step,
        text: item.question
      });

      // 답변 추가
      preMessage.push({
        isUser: true,
        step: step,
        text: item.answerText
      });
    };

    console.log("Generated preMessage:", preMessage);

    return preMessage;
  };


  useEffect(() => {
    setCurrentQuestion(filteredQuestions[currentQuestionIndex]);
    console.log("filteredquestionedit")
  }, [filteredQuestions, currentQuestionIndex]);


  // const getUserStatus = async () => {
  //   try {
  //     const response = await axios.get(`${API_URL}/auth/getStatus`, {
  //       params: {
  //         uid: uid
  //       },
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     console.log(response.data);
  //     await setUserStatus(Math.floor((response.data % 10000) / 100));
  //     return (response.data);

  //   } catch (error) {
  //     // Handle errors
  //     console.error('Error getting UserStatus:', error);
  //   }
  // };

  const fillInsightKeywordWithGpt = async () => {
    console.log("keyword 추출될 질문들", totalFilteredQuestions);
    const postData = totalFilteredQuestions.map(question => ({
      uid: uid,
      question_id: question.questionId,
      question: question.question,
      answer: question.answerText
    }));

    axios.post(`${API_URL}/api/insight/keyword/as-is`, postData)
      .then(response => {
        console.log("keyword asis 생성 성공");
      })
      .catch(error => {
        console.error('Error:', error.response);
      });


  }

  const getBusinessType = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/getBusinessType`, {
        params: {
          uid: uid
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
      return (response.data);
    } catch (error) {
      // Handle errors
      console.error('Error getting businessType:', error);
    }
  };

  const handleAnswerSubmit = (answeredQuestion) => {
    if (!isQASessionOver) {
      const updatedQuestions = filteredQuestions.map((q) => {
        if (q.questionId === answeredQuestion.questionId) {
          return { ...q, uid: uid, answerText: answeredQuestion.answer };
        }
        return q;
      });

      setFilteredQuestions(updatedQuestions);

      if (currentStep === 0) {
        setQuestionList1(updatedQuestions);
        setTotalFilteredQuestions([
          ...updatedQuestions,
          ...questionList2,
          ...questionList3,
          ...questionList4
        ]);
      } else if (currentStep === 1) {
        setQuestionList2(updatedQuestions);
        setTotalFilteredQuestions([
          ...questionList1,
          ...updatedQuestions,
          ...questionList3,
          ...questionList4
        ]);
      } else if (currentStep === 2) {
        setQuestionList3(updatedQuestions);
        setTotalFilteredQuestions([
          ...questionList1,
          ...questionList2,
          ...updatedQuestions,
          ...questionList4
        ]);
      } else {
        setQuestionList4(updatedQuestions);
        setTotalFilteredQuestions([
          ...questionList1,
          ...questionList2,
          ...questionList3,
          ...updatedQuestions
        ]);
      }
    }
  };




  const setNextQuestion = () => {
    console.log("setnextquestion");
    //질문끝났는지 확인
    if (currentQuestionIndex >= filteredQuestions.length - 1) {
      setIsQASessionOver(true);
      return;
    }

    const nextIndex = currentQuestionIndex + 1;
    // if(currentStep === 0){
    //   setQuestion1Index(question1Index+1);
    // }
    // else if(currentStep ===1){
    //   setQuestion2Index(question2Index+1);
    // }
    // else if(currentStep ===2){
    //   setQuestion3Index(question3Index+1);
    // }
    // else{
    //   setQuestion4Index(question4Index+1);
    // }

    setCurrentQuestion(filteredQuestions[nextIndex]);
    setCurrentQuestionIndex(nextIndex);
    console.log("nextIndex", nextIndex);

    //questionClass(who-what-how) 변경될시 progress바 변경
    if (filteredQuestions[nextIndex - 1].questionClass != filteredQuestions[nextIndex].questionClass) {
      setCurrentStep(currentStep + 1);
      // if(currentStep<3){
      //   setMessages([...messages,
      //     { text: infoMessage[step], isUser: false, step: step }
      //   ]);
      // }
    }
  };

  useEffect(() => {
    console.log("Filtered Questions:", filteredQuestions);
    console.log("Current Question Index:", currentQuestionIndex);
    console.log("Current Step:", currentStep);
    console.log("Is QA Session Over:", isQASessionOver);
  }, [filteredQuestions, currentQuestionIndex, currentStep, isQASessionOver]);




  if (!currentQuestion) {
    console.log("No question")
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Navbar open={open} toggleDrawer={toggleDrawer} />
      <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom align="left" marginBottom={2}>
              Insight단계
              <Divider />
            </Typography>
          </Grid>
          <div className="progress-bar" style={{ display: 'flex', pointerEvents: 'none' }}>
            <div
              className={`step ${currentStep === 0 ? 'active' : currentStep > 0 ? 'completed' : ''}`}
            // className={`step ${currentStep === 0 ? 'active' : userStatus >= 1 ? 'completed' : ''}`}
            // style={{ pointerEvents: currentStep === 0 || userStatus >= 1 ? 'auto' : 'none' }}
            // onClick={() => {
            //   if (currentStep == 0 || userStatus >= 1) {
            //     handleStepClick(0);
            //   }
            // }}
            >
              Who
            </div>
            <div
              className={`step ${currentStep === 1 ? 'active' : currentStep > 1 ? 'completed' : ''}`}
            // className={`step ${currentStep === 1 ? 'active' : userStatus >= 2 ? 'completed' : ''}`}
            // style={{ pointerEvents: currentStep === 1 || userStatus >= 2 ? 'auto' : 'none' }}
            // onClick={() => {
            //   if (currentStep == 1 || userStatus >= 2) {
            //     handleStepClick(1);
            //   }
            // }}
            >
              What
            </div>
            <div
              className={`step ${currentStep === 2 ? 'active' : currentStep > 2 ? 'completed' : ''}`}
            // className={`step ${currentStep === 2 ? 'active' : userStatus >= 3 ? 'completed': ''}`}
            // style={{ pointerEvents: currentStep === 2 || userStatus >= 3 ? 'auto' : 'none' }}
            // onClick={() => {
            //   if (currentStep == 2 || userStatus >= 3) {
            //     handleStepClick(2);
            //   }
            // }}
            >
              How
            </div>
            <div
              className={`step ${currentStep === 3 ? 'active' : currentStep > 3 ? 'completed' : ''}`}
            // className={`step ${currentStep === 3 ? 'active' : userStatus >= 4 ? 'completed': ''}`}
            // style={{ pointerEvents: currentStep === 3 || userStatus >= 4 ? 'auto' : 'none' }}
            // onClick={() => {
            //   if (currentStep == 3 || userStatus >= 4) {
            //     handleStepClick(3);
            //   }
            // }}
            >
              Profit
            </div>
          </div>
          <Window
            id="react-window2"
            width={750}
            height={400}
            left={window.innerWidth / 2}
            top={window.innerHeight / 3.2}
            // height={window.innerHeight/2}
            // width={window.innerWidth/2}
            // left={window.innerWidth/2.1}
            // top={window.innerHeight/3}
            resizable={true}
            titleBar={{
              // icon: "R",
              title: "대답 목록",
              buttons: { minimize: true, maximize: false, close: false }
            }}
            style={{ backgroundColor: 'white', paddingLeft: 10 }}
          >
            <Documents questions={filteredQuestions} moveToNextStep={moveToNextStep} isQASessionOver={isQASessionOver} ref={documentRef} />

          </Window>
        </Container>
      </Box>
      <Window
        id="react-window3"
        height={470}
        width={630}
        left={window.innerWidth / 5.5}
        top={window.innerHeight / 3.2}
        resizable={true}
        titleBar={{
          // icon: "R",
          title: "Chat",
          buttons: { minimize: true, maximize: false, close: false }
        }}
        style={{ backgroundColor: 'white', paddingLeft: 10 }}
      ><Chat
          preMessages={preMessage}
          currentQuestion={currentQuestion}
          onAnswerSubmit={handleAnswerSubmit}
          setNextQuestion={setNextQuestion}
          isQASessionOver={isQASessionOver}
          step={currentStep}
          buttonType={false}
        />
      </Window>
    </Box>

  );
};

export default MainInsight;

