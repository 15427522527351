// Documents.js
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { TextField, Paper, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import axios from 'axios';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import {updatestatus} from '../Utils';

const API_URL = process.env.REACT_APP_API_ORIGIN;

const PlatformGtmCanvas2 = React.forwardRef(({ questions, moveToNextStep},ref) => {
  const [editingIndex, setEditingIndex] = useState(-1); 
  const [editedText, setEditedText] = useState(''); 
  const theme = useTheme();
  const navigate = useNavigate();

  const [answerlist,setAnswerlist] = useState([]);
  const [answerStatus,setAnswerStatus] = useState(1);//모두 답변됐는지 - 1완료

  const updatedQuestions = useMemo(() => [...questions], [questions]);
  const [open,setOpen] = useState(false);
  const [showAlert,setShowAlert] = useState(false);
  const [openSnackbar,setOpenSnackbar] = useState(false);
  const [saveSpinner,setSaveSpinner] = useState(false);
  const documentRef = useRef(null);
  const [uid,setUid] = useState('');


  useEffect(() => {
    const fetchUid = async () => {
      let BStype;
      if (localStorage.getItem('userType') === 'consultant')
      {
        const clientId = localStorage.getItem('clientId');
        const clientUidResponse = await axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        setUid(clientUidResponse.data);
      }
      else//client가 접속
      {
        setUid(localStorage.getItem('uid'));
      }
  }
    fetchUid();
  },[]);

  //모두 답변했는지 확인하는 로직
  // useEffect(() =>{
  //   //답변한 것만
  //   console.log("확인",updatedQuestions)
  //   // console.log("answerStatus",answerStatus)
  //   setAnswerStatus(0);

  //   const checkAnswerNumber = updatedQuestions.filter(q => q.answerText && q.answerText.trim() !== '').length;

  //   // const checkAnswerNumber = (updatedQuestions.filter(q => q.answerText)).length//답변 되어있는 개수 확인
  
  //   const submitdata = (updatedQuestions.filter(q => q.uid && q.answerText))// 수정한거 있으면 저장하기 위해
  //   setAnswerlist(submitdata)
  //   console.log("개수확인",checkAnswerNumber,updatedQuestions.length)
    
  //   if (checkAnswerNumber === updatedQuestions.length){
  //     setAnswerStatus(1);
  //     console.log("호출")
  //   }
  // },[updatedQuestions,editingIndex])

  const updateUserStatus = async (updateValue) => {
    try {
      const response = await axios.post(`${API_URL}/auth/setStatus`, {
        uid: uid,
        status: updateValue,
      });
      console.log("userStatus updated successfully");
      localStorage.setItem('userStatus',updateValue);
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  
  const handleEdit = (index) => {
    setEditedText(questions[index].answerText);
    setEditingIndex(index); // 수정 모드로 변경
  };

  const sendUpdatedAnswer = async (updatedQuestion) => {
    try {
      const response = await axios.patch(`${API_URL}/api/answers/update`, {
        uid: uid,
        questionId: updatedQuestion.questionId,
        answerText: updatedQuestion.answerText,
        feedback: updatedQuestion.feedback,
      });
      console.log("answer updated successfully")
      // 요청이 성공하면 응답 데이터를 반환
      setOpenSnackbar(true)
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  const handleSave = (index, key) => {
    if (!editedText || editedText.trim() === '') {
      updatedQuestions[index][key] = ''
    }
    else 
      updatedQuestions[index][key] = editedText; // 수정된 내용 반영

    console.log("updatedQuestions",updatedQuestions[index]);

    sendUpdatedAnswer(updatedQuestions[index])
    setEditingIndex(-1); // 수정 모드 종료

  };

  const ConfirmDialog = ({onClose, onConfirm }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
  
      setTimeout(() => {
        onConfirm(); // 실제 작업 수행
        setConfirming(false);
      }, 2000);
    };
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>제출하고 다음단계으로 이동하시겠습니까?</DialogTitle>
        <DialogContent>
          <DialogContentText>
              답변이 최종 저장되고 다시 수정 할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
          {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

const AlertDialog = ({onClose }) => {
  return (
    <Dialog open={showAlert} onClose={onClose}>

      <DialogTitle>답변을 완료한 후 제출해주세요.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="warning" >
              답변 목록에 빈 답변이 있습니다.
          </Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const makePPTOnServer = async () => {
  try {
    const response = await axios.get(`${API_URL}/file/ppt/fillPpt/strategy_${uid}.pptx`);
    console.log(response.data);
    setOpen(false);
  } catch (error) {
    console.error('Error submitting documents:', error);
  }
};

  const submitAndMoveNextStep = async () => {
    await saveAnswersToServer();
    await makePPTOnServer();
    
    const userType = localStorage.getItem("userType");
    // updateUserStatus(29);
    updatestatus(uid, 'strategy', 9);
    // Move to consultant mainpage
    if (userType == 'consultant')
      navigate('/ConsultantFile');

    // Move to customer mainpage
    else if (userType == 'client') {
      navigate('/StrategyReport');
    }
    setOpen(false);
  }
    
  const hadleSaveAnswer = async() => { // 임시 저장버튼
    setSaveSpinner(true)
    await saveAnswersToServer();
    setTimeout(() => {
      setSaveSpinner(false)
      setOpenSnackbar(true)

    }, 1000);
  }

  const handleSubmit = () => { //제출 버튼
    if (answerStatus === 1) {
      setOpen(true)
    }
    else 
    {
      setShowAlert(true)
    }
  };

  const saveAnswersToServer = async () => { //답변 저장 api호출
    console.log("answerlist",answerlist);
    try {
      const response = await axios.post(`${API_URL}/api/answers/submit`, answerlist );
      console.log(response.data); 


    } catch (error) {
      console.error('Error submitting documents:', error);
    }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  React.useImperativeHandle(ref, () => ({
    submitDocument: () => {
      handleSubmit();
    },
  }));
  
  return (
    <Paper>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "center",
        }}
      >
        <thead>
          <tr>
            <th
              colSpan="3"
              style={{
                backgroundColor: "#e2e4ed",
                color: "#333",
                fontWeight: "bold",
                fontSize: "18px",
                padding: "10px",
                border: "1px solid #ccc",
              }}
            >
              플랫폼형 사업 GTM Canvas - 초기 핵심상호작용 설계
            </th>
          </tr>
        </thead>
        <tbody>
          {questions.map((item, idx) => (
            <tr key={idx}>
              {/* 질문 컬럼 */}
              <td
                style={{
                  width: "30%",
                  backgroundColor: "#2f6eba",
                  color: "yellow",
                  fontSize: "15px",
                  padding: "10px",
                  border: "1px solid #ccc",
                  textAlign: "left",
                }}
              >
                <div style={{whiteSpace: 'pre-line'}}>{item.question.replace(/\\n/g, '\n')}</div>
              </td>

              {/* 고객답변과 최종의견 */}
              <td
                style={{
                  width: "10%",
                  padding: 0,
                  border: "1px solid #ccc",
                  textAlign: "center",
                  fontSize:"15px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    justifyContent: "center", // 세로 정렬
                    alignItems: "center",    // 가로 정렬
                    height: "100%", // 셀 높이 채우기
                    padding: 0,
                  }}
                >
                  <div
                    style={{
                      padding: "8px",
                      fontWeight: "bold",
                      borderBottom: "1px solid #ccc",
                      justifyContent: "center", // 세로 중앙 정렬
                      alignItems: "center", // 가로 중앙 정렬
                      width: "100%", // 텍스트가 전체 셀에 맞게 배치
                      height: "50%", // 부모 높이의 절반
                      textAlign: "center", // 텍스트 가운데 정렬
                    }}
                  >
                    고객답변 :
                  </div>
                  <div style={{
                        padding: "8px",
                        fontWeight: "bold",
                        justifyContent: "center", // 세로 중앙 정렬
                        alignItems: "center", // 가로 중앙 정렬
                        width: "100%", // 텍스트가 전체 셀에 맞게 배치
                        height: "50%", // 부모 높이의 절반
                        textAlign: "center", // 텍스트 가운데 정렬
                      }}>
                    최종의견 :
                  </div>
                </div>
              </td>

              {/* textarea 영역 */}
              <td
                style={{
                  width: "35%",
                  padding: 0,
                  border: "1px solid #ccc",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    padding: 0,
                  }}
                >
                  <TextField
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    resize: "none",
                    margin: 0,
                  }}
                  multiline // 여러 줄의 텍스트 입력
                  rows={4} // 텍스트 필드의 행 수
                  value={item.answerText}
                  onChange={(e) => {
                    item.answerText = e.target.value;
                    setEditedText(e.target.value);
                  }}
                  onFocus={(e)=>{setEditedText(e.target.value)}}
                  onBlur={()=>handleSave(idx, "answerText")}
                  />
                  <TextField
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    resize: "none",
                    margin: 0,
                  }}
                  multiline // 여러 줄의 텍스트 입력
                  rows={4} // 텍스트 필드의 행 수
                  value={item.feedback}
                  onChange={(e) => {
                    item.feedback = e.target.value;
                    setEditedText(e.target.value);
                  }}
                  onFocus={(e)=>{setEditedText(e.target.value)}}
                  onBlur={()=>handleSave(idx, "feedback")}
                  />
                  
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      {/* <Button variant="contained" onClick={saveAnswersToServer} style={{ marginTop: '10px' }}>
        답변 제출하기
      </Button> */}
      <Fab 
      varient ="extended"
      onClick ={hadleSaveAnswer}
      sx={{
        color: 'common.white',
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[600],
        },
        position: 'fixed',
        width: 100,
        bottom: 30,
        right: 150,
        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
        boxShadow: '0, 0, 0, 0.5',
      }} 
      disabled={saveSpinner} // 작업이 진행 중일 때 버튼을 비활성화합니다.
      >
        {saveSpinner ? <CircularProgress size={24} /> : <span>임시저장</span>} {/* 스피너 또는 텍스트 표시 */}
      </Fab>
      <Fab color="secondary"
      varient ="extended"
      onClick ={handleSubmit}
      sx={{
        position: 'fixed',
        width: 100,
        bottom: 30,
        right: 30,
        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
        boxShadow: '0, 0, 0, 0.5',
      }} >
        <span>제출</span>
        <RightArrow />
      </Fab>
      <ConfirmDialog onClose={() => setOpen(false)} onConfirm={submitAndMoveNextStep}/>
      <AlertDialog onClose={() => setShowAlert(false)}/>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: 'bottom', 
          horizontal: 'center',
        }}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          답변이 저장되었습니다!
        </Alert>
      </Snackbar>
    </Paper>
    
  );
});
export default PlatformGtmCanvas2;