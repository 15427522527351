import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import axios from 'axios';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { TextField, Paper, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Window from './Window/Window';
import { useNavigate } from 'react-router-dom';
import '../../styles/ConsultingTable.css';
import {updatestatus} from '../Utils';
const API_URL = process.env.REACT_APP_API_ORIGIN;



const PlatformGtmCanvas1 = React.forwardRef(({ questions, savedAnswers, insights, moveToNextStep }, ref) => {

  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedText, setEditedText] = useState('');
  const theme = useTheme();

  const [answerlist, setAnswerlist] = useState([]);
  const [answerStatus, setAnswerStatus] = useState(1);//모두 답변됐는지 - 1일경우 모두 완료

  const updatedQuestions = useMemo(() => [...questions], [questions]);
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [saveSpinner, setSaveSpinner] = useState(false);
  const documentRef = useRef(null);
  const [whoAnswers, setWhoAnswers] = useState([]);
  const [whatAnswers, setWhatAnswers] = useState([]);
  const [howAnswers, setHowAnswers] = useState([]);
  const [popUpTableData, setPopUpTableData] = useState(null);
  const [showPopUpTable, setShowPopUpTable] = useState(false);
  const [windowTitle, setWindowTitle] = useState('');
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [transformedQuestions, setTransformedQuestions] = useState();

  const [isMinimized, setIsMinimized] = useState(true);


  useEffect(() => {
    const fetchUid = async () => {
      let BStype;
      if (localStorage.getItem('userType') === 'consultant') {
        const clientId = localStorage.getItem('clientId');
        const clientUidResponse = await axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        setUid(clientUidResponse.data);
      }
      else//client가 접속
      {
        setUid(localStorage.getItem('uid'));
      }
    }
    fetchUid();
  }, []);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const updateUserStatus = async (updateValue) => {
    try {
      const response = await axios.post(`${API_URL}/auth/setStatus`, {
        uid: uid,
        status: updateValue,
      });
      console.log("userStatus updated successfully");
      localStorage.setItem('userStatus',updateValue);
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  useEffect(() => {
    if (isMinimized) {
      // You might want to set specific styles or actions when minimized
    }
  }, [isMinimized]);

//모두 답변했는지 확인하는 로직
  // useEffect(() => {
  //   //답변한 것만
  //   console.log("확인", updatedQuestions)
  //   // console.log("answerStatus",answerStatus)
  //   setAnswerStatus(0);

  //   const checkAnswerNumber = updatedQuestions.filter(q => q.answerText && q.answerText.trim() !== '').length;

  //   // const checkAnswerNumber = (updatedQuestions.filter(q => q.answerText)).length//답변 되어있는 개수 확인

  //   const submitdata = (updatedQuestions.filter(q => q.uid && q.answerText))// 수정한거 있으면 저장하기 위해
  //   setAnswerlist(submitdata)
  //   console.log("개수확인", checkAnswerNumber, updatedQuestions.length)

  //   if (checkAnswerNumber === updatedQuestions.length) {
  //     setAnswerStatus(1);
  //     console.log("호출")
  //   }

  //   // console.log("updatedQuestions set QuestionClassList",updatedQuestions)

  // }, [updatedQuestions, editingIndex]);

  useEffect(() => {

    //for each savedAnswer in savedAnswers, 
    //if the questionId matches, and questionType includes (lower) who, what, or how, add savedAnswer to the corresponding array
    const whoAnswerList = [];
    const whatAnswerList = [];
    const howAnswerList = [];

    savedAnswers.forEach((savedAnswer) => {
      const question = insights.find(q => q.questionId === savedAnswer.questionId);
      if (question) {
        if (question.questionType.toLowerCase().includes('who') && question.questionType.toLowerCase().includes('플랫폼')) {
          savedAnswer.question = question.question;
          savedAnswer.questionType = question.questionType;
          whoAnswerList.push(savedAnswer);
        } else if (question.questionType.toLowerCase().includes('what') && question.questionType.toLowerCase().includes('플랫폼')) {
          savedAnswer.question = question.question;
          savedAnswer.questionType = question.questionType;
          whatAnswerList.push(savedAnswer);
        } else if (question.questionType.toLowerCase().includes('how') && question.questionType.toLowerCase().includes('플랫폼')) {
          savedAnswer.question = question.question;
          savedAnswer.questionType = question.questionType;
          howAnswerList.push(savedAnswer);
        }
      }
    });
    setWhoAnswers(whoAnswerList);
    setWhatAnswers(whatAnswerList);
    setHowAnswers(howAnswerList);

    console.log("whoAnswers", whoAnswerList);
    // console.log("whatAnswers", whatAnswers);
    // console.log("howAnswers", howAnswers);

  }, []); // useEffect가 마운트 될 때 한 번만 호출

  const handleEdit = (index) => {
    setEditedText(questions[index].answerText);
    setEditingIndex(index); // 수정 모드로 변경
  };

  const transformQuestions = (questions) => {
    const tableMap = {};

    console.log("savedanswer", savedAnswers)
    console.log("insights", insights)

    questions.forEach((question) => {
      // if questionClassList is not 2, set questionClassList to [questionClass, N/A]
      if (question.questionClassList.length !== 2) {
        console.error(`Invalid question class list: ${question.questionClassList}`);
        return;
      }
      const [rowKey, colKey] = question.questionClassList;
      if (!tableMap[rowKey]) {
        tableMap[rowKey] = {};
      }
      if (!tableMap[rowKey][colKey]) {
        tableMap[rowKey][colKey] = [];
      }
      tableMap[rowKey][colKey].push(question);
    });
    return tableMap;
  };

  useEffect(() => {
  const transformedData = transformQuestions(updatedQuestions);
  setTransformedQuestions(transformedData);
  console.log("transformedQuestions",transformedData);
  }, [updatedQuestions]);

  // inside rowHeaaders, drop the element 'TCR' and sort in descending order
  const rowHeaders = [...new Set(questions.flatMap(q => q.questionClassList[0]))].filter(header => header !== 'TCR').sort();
  const rowTCR = [...new Set(questions.flatMap(q => q.questionClassList[0]))].filter(header => header === 'TCR').sort();
  const columnHeaders = [...new Set(questions.flatMap(q => q.questionClassList[1]))].sort().reverse();

  // function to return index of transformed question in the updatedQuestions array
  const getUpdatedQuestionIndex = (rowKey, colKey) => {
    const question = transformedQuestions[rowKey][colKey][0];
    return updatedQuestions.findIndex(q => q.questionId === question.questionId)
  };

  const sendUpdatedAnswer = async (updatedQuestion) => {
    try {
      const response = await axios.patch(`${API_URL}/api/answers/update`, {
        uid: uid,
        questionId: updatedQuestion.questionId,
        answerText: updatedQuestion.answerText,
        feedback: updatedQuestion.feedback
      });
      console.log("answer updated successfully")
      // 요청이 성공하면 응답 데이터를 반환
      setOpenSnackbar(true)
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  }

  const handleSave = (rowHeader, colHeader, cellIndex) => {
    const question = transformedQuestions[rowHeader][colHeader][cellIndex];
    console.log("question",question);
    const index =  updatedQuestions.findIndex(q => q.questionId === question.questionId)
    console.log("updatedQuestions",updatedQuestions);

    const editFeedback = transformedQuestions[rowHeader][colHeader][cellIndex].feedback;
    const { answerText, feedback } = transformedQuestions[rowHeader][colHeader][cellIndex];


    // 조건에 따라 업데이트
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      answerText: answerText?.trim() || '',
      feedback: feedback?.trim() || '',
    };
    console.log("updatedQuestions", updatedQuestions[index]);

    sendUpdatedAnswer(updatedQuestions[index])

    setEditingIndex(-1); // 수정 모드 종료

  };

  // function to handle onclick of as-is issue button or insight button
  // when keyword is given (who, what, how) get the corresponding answers
  const handleButtonClick = (wwh, keyword) => {
    setWindowTitle(wwh + " " + keyword + "에 대한 질문과 답변 목록")
    wwh = wwh.toLowerCase();
    let answers = [];

    if (wwh === 'who') {
      answers = whoAnswers;
    } else if (wwh === 'what') {
      answers = whatAnswers;
    } else if (wwh === 'how') {
      answers = howAnswers;
    } else {
      console.log("Invalid keyword", wwh, keyword);
      setPopUpTableData([]);
      return;
    }

    let tableData;
    // 테이블 데이터 설정
    if (keyword === "AsIs") {
      tableData = answers.map((answer) => ({
        question: answer.question,
        answerText: answer.answerText,
        asIs: answer.asIs,
      }));
    }
    else {
      tableData = answers.map((answer) => ({
        question: answer.question,
        answerText: answer.answerText,
        insight: answer.insight,
      }));
    }
    setPopUpTableData(tableData);
    setShowPopUpTable(true)

  };


  const ConfirmDialog = ({ onClose, onConfirm }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시

      setTimeout(() => {
        onConfirm(); // 실제 작업 수행
        setConfirming(false);
      }, 2000);
    };
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>제출하고 다음단계으로 이동하시겠습니까?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            답변이 최종 저장되고 다시 수정 할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
            {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const AlertDialog = ({ onClose }) => {
    return (
      <Dialog open={showAlert} onClose={onClose}>

        <DialogTitle>답변을 완료한 후 제출해주세요.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning" >
              답변 목록에 빈 답변이 있습니다.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const makePPTOnServer = async () => {
    try {
      const response = await axios.get(`${API_URL}/file/ppt/fillPpt/strategy_${uid}.pptx`);
      console.log(response.data);
      setOpen(false);
    } catch (error) {
      console.error('Error submitting documents:', error);
    }
  };

  const submitAndMoveNextStep = async () => {
    await saveAnswersToServer();
    classifyResult();
    const userType = localStorage.getItem("userType");
    updateUserStatus(29);
    updatestatus(uid, 'strategy', 9);
    // Move to consultant mainpage
    if (userType == 'consultant')
      navigate('/ConsultantFile');

    // Move to customer mainpage
    else if (userType == 'client') {
      
      navigate('/StrategyReport');
    }
    setOpen(false);
  }
  const classifyResult = () => {
    console.log("이건 questions", questions)
    console.log("이건 updatedQuestion", updatedQuestions)
  }

  const hadleSaveAnswer = async () => { // 임시 저장버튼
    setSaveSpinner(true)
    await saveAnswersToServer();
    setTimeout(() => {
      setSaveSpinner(false)
      setOpenSnackbar(true)

    }, 1000);
  }

  const handleSubmit = () => { //제출 버튼
    if (answerStatus === 1) {
      setOpen(true)
    }
    else {
      setShowAlert(true)
    }
  };

  const renderHeader = (header) => {
    switch (header) {
      case 'WHO':
        return '1. 참여자';
      case 'WHAT':
        return '2. 가치단위';
      case 'HOW':
        return '3. 필터';
      // 여기에 더 많은 조건을 추가할 수 있습니다.
      default:
        return header; // 기본값으로 header 자체를 반환
    }
  };


  const saveAnswersToServer = async () => { //답변 저장 api호출
    console.log("anwerlist",answerlist);
    try {
      const response = await axios.post(`${API_URL}/api/answers/submit`, answerlist);
      console.log(response.data);


    } catch (error) {
      console.error('Error submitting documents:', error);
    }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  React.useImperativeHandle(ref, () => ({
    submitDocument: () => {
      handleSubmit();
    },
  }));

if (!transformedQuestions) return null;


  return (
    <Paper style={{marginBottom:'100px'}} ref={documentRef} sx={{ display: 'flex', flexDirection: 'column' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={columnHeaders.length + 1} style={{ textAlign: "center", backgroundColor: "#e2e4ed", color: 'black', fontWeight: 'bold', fontSize: '18px', color: '#333' }}>플랫폼형 사업 GTM Canvas-초기 핵심상호작용 설계</TableCell>
            </TableRow>
            <TableRow>
              {columnHeaders.map((header) => (
                <TableCell key={header} style={{ backgroundColor: '#2f6eba', color: 'yellow', textAlign: 'center', fontWeight: 'bold' }}>{renderHeader(header)}
                  <br></br>
                  <Button
                    style={{ backgroundColor: "#bbbfc3", color: "black", margin: '1px' }}
                    onClick={() => handleButtonClick(header, 'AsIs')}>
                    AS-IS 이슈
                  </Button>
                  <Button
                    style={{ backgroundColor: "#f9d77e", color: "black", }}
                    onClick={() => handleButtonClick(header, 'insight')}>
                    INSIGHT
                  </Button>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowHeaders.map((rowHeader) => (
              <TableRow key={rowHeader}>
                {columnHeaders.map((colHeader) => (
                  <TableCell key={`${rowHeader}-${colHeader}`}>
                    {/* Render question items or an indication of absence */}
                    {transformedQuestions[rowHeader]?.[colHeader]?.map((item, index) => (
                      <div key={index} style={{ fontWeight: 'bold', fontSize: '16px', color: '#333', marginBottom: '10px' }}>
                        <div style={{whiteSpace: 'pre-line'}}>{item.question.replace(/\\n/g, '\n')}</div>
                        <div style={{ marginTop: '10px', display: 'block' }}>
                        <td style={{ fontWeight: 'bold', fontSize: '13px',textAlign:'center'}}>고객 답변 :</td>
                        <TableCell
                          onMouseEnter={() => handleMouseEnter(getUpdatedQuestionIndex(rowHeader, colHeader))}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => handleEdit(getUpdatedQuestionIndex(rowHeader, colHeader))}
                          style={{
                            width: '300px',
                            cursor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? 'pointer' : 'auto',
                            backgroundColor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? theme.palette.action.hover : 'inherit',
                          }}
                        >
                            <TextField
                              multiline // 여러 줄의 텍스트 입력
                              rows={4} // 텍스트 필드의 행 수
                              value={item.answerText}
                              // onChange={(e) => setEditedText(e.target.value)}
                              onChange={(e) => {
                                // 깊은 복사를 통해 데이터 구조를 안전하게 복사
                                const updatedQuestions = JSON.parse(JSON.stringify(transformedQuestions));
                              
                                // 복사된 객체에 변경 사항 반영
                                updatedQuestions[rowHeader][colHeader][index].answerText = e.target.value;
                              
                                // 상태 업데이트
                                setTransformedQuestions(updatedQuestions);
                              }}
                              onBlur={() => handleSave(rowHeader, colHeader,index)}
                              inputProps={{ style: { fontSize: '14px' } }}
                            />
                        </TableCell>
                        </div>
                        <div style={{ marginTop: '10px', display: 'block' }}>
                        <td style={{ fontWeight: 'bold', fontSize: '13px',textAlign:'center'}}>최종 의견 :</td>
                        <TableCell
                          onMouseEnter={() => handleMouseEnter(getUpdatedQuestionIndex(rowHeader, colHeader))}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => handleEdit(getUpdatedQuestionIndex(rowHeader, colHeader))}
                          style={{
                            width: '300px',
                            cursor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? 'pointer' : 'auto',
                            backgroundColor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? theme.palette.action.hover : 'inherit',
                          }}
                        >
                            <TextField
                              multiline // 여러 줄의 텍스트 입력
                              rows={4} // 텍스트 필드의 행 수
                              value={item.feedback}
                              // onChange={(e) => setEditedText(e.target.value)}
                              onChange={(e) => {
                                // 깊은 복사를 통해 데이터 구조를 안전하게 복사
                                const updatedQuestions = JSON.parse(JSON.stringify(transformedQuestions));
                              
                                // 복사된 객체에 변경 사항 반영
                                updatedQuestions[rowHeader][colHeader][index].feedback = e.target.value;
                              
                                // 상태 업데이트
                                setTransformedQuestions(updatedQuestions);
                              }}
                              onBlur={() => handleSave(rowHeader, colHeader,index)}
                              inputProps={{ style: { fontSize: '14px' } }}
                            />
                        </TableCell>
                        </div>
                      </div>
                    )) || ' '}
                    
                  </TableCell>
                ))}
              </TableRow>
            ))}

            <TableRow>
              <TableCell colSpan={columnHeaders.length + 1} style={{ fontSize:'16px', backgroundColor: '#2f6eba', color: 'yellow', textAlign: 'center' }}>
                4. 초기 상호작용 활성화를 위한 TCR 전략
              </TableCell>
            </TableRow>
            {rowTCR.map((rowHeader) => (
              <TableRow key={rowHeader}>
                {columnHeaders.map((colHeader) => (
                  <TableCell key={`${rowHeader}-${colHeader}`}>
                    {/* Render question items or an indication of absence */}
                    {transformedQuestions[rowHeader]?.[colHeader]?.map((item, index) => (
                      <div key={index} style={{ fontWeight: 'bold', fontSize: '16px', color: '#333', marginBottom: '10px' }}>
                        {item.question}
                        <div style={{ marginTop: '10px', display: 'block' }}>
                          <td style={{ fontWeight: 'bold', fontSize: '13px',textAlign:'center'}}>고객 답변 :</td>
                          <TableCell
                            onMouseEnter={() => handleMouseEnter(getUpdatedQuestionIndex(rowHeader, colHeader))}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleEdit(getUpdatedQuestionIndex(rowHeader, colHeader))}
                            style={{
                              width: '300px',
                              cursor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? 'pointer' : 'auto',
                              backgroundColor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? theme.palette.action.hover : 'inherit',
                            }}
                          >
                            <TextField
                              multiline // 여러 줄의 텍스트 입력
                              rows={4} // 텍스트 필드의 행 수
                              value={item.answerText}
                              // onChange={(e) => setEditedText(e.target.value)}
                              onChange={(e) => {
                                // 깊은 복사를 통해 데이터 구조를 안전하게 복사
                                const updatedQuestions = JSON.parse(JSON.stringify(transformedQuestions));
                              
                                // 복사된 객체에 변경 사항 반영
                                updatedQuestions[rowHeader][colHeader][index].answerText = e.target.value;
                              
                                // 상태 업데이트
                                setTransformedQuestions(updatedQuestions);
                              }}
                              onBlur={() => handleSave(rowHeader, colHeader,index)}
                              inputProps={{ style: { fontSize: '14px' } }}
                            />
                          </TableCell>
                        </div>
                        <div style={{ marginTop: '10px', display: 'block' }}>
                        <td style={{ fontWeight: 'bold', fontSize: '13px',textAlign:'center'}}>최종 의견 :</td>
                        <TableCell
                          onMouseEnter={() => handleMouseEnter(getUpdatedQuestionIndex(rowHeader, colHeader))}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => handleEdit(getUpdatedQuestionIndex(rowHeader, colHeader))}
                          style={{
                            width: '300px',
                            cursor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? 'pointer' : 'auto',
                            backgroundColor: hoveredIndex === getUpdatedQuestionIndex(rowHeader, colHeader) ? theme.palette.action.hover : 'inherit',
                          }}
                        >
                            <TextField
                              multiline // 여러 줄의 텍스트 입력
                              rows={4} // 텍스트 필드의 행 수
                              value={item.feedback}
                              // onChange={(e) => setEditedText(e.target.value)}
                              onChange={(e) => {
                                // 깊은 복사를 통해 데이터 구조를 안전하게 복사
                                const updatedQuestions = JSON.parse(JSON.stringify(transformedQuestions));
                              
                                // 복사된 객체에 변경 사항 반영
                                updatedQuestions[rowHeader][colHeader][index].feedback = e.target.value;
                              
                                // 상태 업데이트
                                setTransformedQuestions(updatedQuestions);
                              }}
                              onBlur={() => handleSave(rowHeader, colHeader,index)}
                              inputProps={{ style: { fontSize: '14px' } }}
                            />
                        </TableCell>
                        </div>
                      </div>
                    )) || ' '}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
      <Fab
        varient="extended"
        onClick={hadleSaveAnswer}
        sx={{
          color: 'common.white',
          bgcolor: green[500],
          '&:hover': {
            bgcolor: green[600],
          },
          position: 'fixed',
          width: 100,
          bottom: 30,
          right: 150,
          borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
          boxShadow: '0, 0, 0, 0.5',
        }}
        disabled={saveSpinner} // 작업이 진행 중일 때 버튼을 비활성화합니다.
      >
        {saveSpinner ? <CircularProgress size={24} /> : <span>임시저장</span>} {/* 스피너 또는 텍스트 표시 */}
      </Fab>
      
      <ConfirmDialog onClose={() => setOpen(false)} onConfirm={submitAndMoveNextStep} />
      <AlertDialog onClose={() => setShowAlert(false)} />
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          답변이 저장되었습니다!
        </Alert>
      </Snackbar>
      {showPopUpTable ? 
      <Window
        id="react-window2"
        height={window.innerHeight / 2}
        width={window.innerWidth / 2}
        left={window.innerWidth / 3}
        top={window.innerHeight / 3}
        resizable={true}
        
        titleBar={{
          // icon: "R",
          title: `${windowTitle}`,
          buttons: { minimize: true, maximize: false, close() { setShowPopUpTable(false) } },
        }}
        
        style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20, isMinimized: true}}
      >
        
        <div>
          {popUpTableData ?
            <table className="MyTable">
              <thead>
                <tr>
                  <th>질문</th>
                  <th>답변</th>
                  {windowTitle.includes("AsIs") ? <th>AS_IS</th> : <th>Insight</th>}
                </tr>
              </thead>
              <tbody>
                {popUpTableData ? popUpTableData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.question}</td>
                    <td>{item.answerText}</td>
                    {windowTitle.includes("AsIs") ? <td>{item.asIs}</td> : <td>{item.insight}</td>}
                  </tr>
                )) : <tr><td colSpan="4">보고자하는 답변의 버튼을 눌러주세요</td></tr>}
              </tbody>
            </table>
            : <div>테이블의 버튼을 눌러 이전 답변들을 확인 가능합니다.</div>}
        </div>
        
      </Window>
      : null}

    </Paper>

  );
});

export default PlatformGtmCanvas1;
