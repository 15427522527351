import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from "react";
import "./Window.css";
const nextZIndex = () => {
    let maxZ = 0;
    for (let w of document.querySelectorAll(".window-container")) {
        let z = parseInt(w.style.zIndex);
        maxZ = Math.max(isNaN(z) ? 0 : z, maxZ);
    }
    return maxZ + 1;
};
const Window = (props) => {
    let properties = Object.assign({
        id: props.id && props.id.length ? props.id : Date.now().toString(),
        children: null,
        height: 0,
        width: 0,
        top: 0,
        left: 0,
        resizable: false,
        titleBar: Object.assign({
            icon: " ",
            title: "Untitled window",
            buttons: Object.assign({
                minimize: true,
                maximize: true,
                close: true,
            }, (props.titleBar && props.titleBar.buttons) || {}),
        }, props.titleBar),
        style: {},
    }, props);
    if (!properties.id) {
        properties.id = Date.now().toString();
    }
    Object.freeze(properties);
    const [height, setHeight] = React.useState(properties.height);
    const [width, setWidth] = React.useState(properties.width);
    const [top, setTop] = React.useState(properties.top || 0);
    const [left, setLeft] = React.useState(properties.left || 0);
    const [xOffset, setXOffset] = React.useState(0);
    const [yOffset, setYOffset] = React.useState(0);
    const [minimized, setMinimized] = React.useState(false);
    const [maximized, setMaximized] = React.useState(false);
    const [minimizeIcon, setMinimizeIcon] = React.useState("―");
    const [maximizeIcon, setMaximizeIcon] = React.useState("□");
    const [contentDisplay, setContentDisplay] = React.useState(true);
    const [windowTransition, setWindowTransition] = React.useState("");
    const [level, setLevel] = React.useState(nextZIndex());
    const [visibility, setWindowVisibility] = React.useState(1.0);
    const container = React.useRef(null);
    const windowTitle = React.useRef(null);
    const effectiveHeight = useRef(height);
    const effectiveWidth = useRef(width);
    const animationDuration = 500;
    const handleDragStart = (e) => {
        setYOffset(e.clientY - top);
        setXOffset(e.clientX - left);
        setLevel(nextZIndex());
        setWindowVisibility(0.5);
    };
    
    const handleDrag = (e) => {
        // 클라이언트 화면의 크기를 가져옴
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
    
        // 드래그로 계산된 새 위치
        let newLeft = (e.clientX || e.screenX || left + xOffset) - xOffset;
        let newTop = (e.clientY || e.screenY || top + yOffset) - yOffset;
    
        // 윈도우가 화면을 벗어나지 않도록 새 위치를 조정
        newLeft = Math.max(0, Math.min(newLeft, screenWidth - effectiveWidth.current));
        newTop = Math.max(screenHeight*0.1, Math.min(newTop, screenHeight - effectiveHeight.current));
    
        // 업데이트된 위치를 상태에 설정
        setLeft(newLeft);
        setTop(newTop);
    };
    
    const handleDragEnd = (e) => {
        // 드래그 종료 시 위치를 다시 계산하여 화면을 벗어나지 않도록 함
        let newLeft = (e.clientX || e.screenX) - xOffset;
        let newTop = (e.clientY || e.screenY) - yOffset;
    
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
    
        // 위치 재조정
        newLeft = Math.max(0, Math.min(newLeft, screenWidth - effectiveWidth.current));
        newTop = Math.max(screenHeight*0.1, Math.min(newTop, screenHeight - effectiveHeight.current));
    
        setLeft(newLeft);
        setTop(newTop);
        setWindowVisibility(1.0);
    };
    
    const minimize = () => {
        var _a, _b;
        setWindowTransition(`${animationDuration}ms ease-in-out`);
        const parent = (_a = document.getElementById(properties.id)) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (minimized) {
            setContentDisplay(true);
            effectiveHeight.current = height;
            console.log(window.innerHeight);
            console.log(window.innerWidth);
            setTop(window.innerHeight/3);
            setLeft(window.innerWidth/4);
            setMinimized(false);
            setMinimizeIcon("―");
            setMaximized(false);
        }
        else {
            setContentDisplay(false);
            effectiveHeight.current = 32;
            const parent = (_b = document.getElementById(properties.id)) === null || _b === void 0 ? void 0 : _b.parentElement;
            effectiveWidth.current = width;
            let topPosition = ((parent === null || parent === void 0 ? void 0 : parent.clientHeight) || window.innerHeight) -
                effectiveHeight.current -
                4;
            let leftPosition = ((parent === null || parent === void 0 ? void 0 : parent.clientWidth) || window.innerWidth) - effectiveWidth.current - 4;
            const minimizedWindow = document.elementFromPoint(leftPosition + effectiveWidth.current / 2, topPosition + effectiveHeight.current / 2);
            if (minimizedWindow &&
                ["window-container", "windowTitle"].includes((minimizedWindow === null || minimizedWindow === void 0 ? void 0 : minimizedWindow.className) || "")) {
                topPosition -= (minimizedWindow === null || minimizedWindow === void 0 ? void 0 : minimizedWindow.clientHeight) + 4;
            }
            setTop(window.innerHeight / 1.1);
            setLeft(window.innerWidth / 4);
            setMinimized(true);
            setMinimizeIcon("◰");
            setMaximized(false);
        }
        setLevel(nextZIndex());
        setTimeout(setWindowTransition, animationDuration + 1, "");
    };
    const maximize = () => {
        var _a;
        setWindowTransition(`${animationDuration}ms ease-in-out`);
        const parent = (_a = document.getElementById(properties.id)) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (maximized) {
            setContentDisplay(true);
            effectiveHeight.current = height;
            effectiveWidth.current = width;
            setTop(window.innerHeight/3);
            setLeft(window.innerWidth/4);
            setMaximized(false);
            setMaximizeIcon("□");
            setMinimized(false);
            setMinimizeIcon("―");
        }
        else {
            setContentDisplay(true);
            effectiveHeight.current = window.innerHeight / 1.2;
            effectiveWidth.current = window.innerWidth / 1.5;
            // 상단 위치를 화면 높이의 1/6로 설정
            setTop(window.innerHeight / 8);

            // 좌측 위치를 화면 너비의 1/6로 설정
            setLeft(window.innerWidth / 5);
            setMaximized(true);
            setMaximizeIcon("❐");
            setMinimized(false);
            setMinimizeIcon("―");
        }
        setLevel(nextZIndex());
        setTimeout(setWindowTransition, animationDuration + 1, "");
    };
    return (_jsxs("div", Object.assign({
        id: properties.id, className: "window-container", style: {
            height: effectiveHeight.current,
            width: effectiveWidth.current,
            top,
            left,
            resize: properties.resizable ? "both" : "none",
            transition: windowTransition,
            zIndex: level,
            opacity: visibility,
        }, ref: container, onClick: () => {
            setLevel(nextZIndex());
        }
    }, {
        children: [properties.titleBar && (_jsxs("div", Object.assign({
            className: "title-bar", "data-parent": properties.id, style: {
                opacity: visibility,
            }
        }, { children: [properties.titleBar.icon && (_jsx("span", Object.assign({ className: "icon" }, { children: properties.titleBar.icon }))), _jsx("span", Object.assign({ className: "windowTitle", draggable: true, onDragStart: handleDragStart, onDrag: handleDrag, onDragEnd: handleDragEnd, style: { opacity: Math.floor(visibility) }, ref: windowTitle }, { children: properties.titleBar.title })), properties.titleBar.buttons && (_jsxs("span", Object.assign({ className: "buttonContainer" }, { children: [properties.titleBar.buttons.minimize && (_jsx("span", Object.assign({ className: "windowButton", onClick: minimize }, { children: minimizeIcon }))), properties.titleBar.buttons.maximize && (_jsx("span", Object.assign({ className: "windowButton", onClick: maximize }, { children: maximizeIcon }))), !!properties.titleBar.buttons.close && (_jsx("span", Object.assign({ className: "windowButton", onClick: properties.titleBar.buttons.close }, { children: "\u2A2F" })))] })))] }))), _jsx("div", Object.assign({ className: "content", draggable: "false", style: Object.assign({ height: contentDisplay ? "auto" : 0, opacity: visibility }, properties.style) }, { children: properties.children }))]
    })));
};
export default Window;
