import React, { useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';

const API_URL = process.env.REACT_APP_API_ORIGIN;
const drawerWidth = 240;

const PromptEditor = () => {
    const [prompts, setPrompts] = useState([]);
    const [notice, setNotice] = useState(''); // You can set the default notice text here
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch prompt data from backend
        const fetchPrompts = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/prompt/prompts`);
                setPrompts(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch prompts');
                setLoading(false);
            }
        };

        fetchPrompts();
    }, []);

    const handleTextChange = (index, newText) => {
        setPrompts(prevPrompts => {
            const updatedPrompts = [...prevPrompts]; // 이전 상태를 복사합니다.
            updatedPrompts[index] = { ...updatedPrompts[index], text: newText }; // 특정 인덱스만 업데이트합니다.
            return updatedPrompts;
        });
    };
    
    

    const handleSave = async (index) => {
        const promptToSave = prompts[index]; // 인덱스를 사용해 특정 프롬프트를 찾습니다.
        try {
            await axios.put(
                `${API_URL}/api/prompt/promptId=${promptToSave.promptId}&apiName=${promptToSave.apiName}`,
                promptToSave.text,  // 단순 문자열 전달
                {
                    headers: {
                        'Content-Type': 'text/plain'  // Content-Type을 text/plain으로 설정
                    }
                }
            );
            alert('Prompt saved successfully!');
        } catch (err) {
            alert('Failed to save prompt.');
        }
    };
    
    

    const defaultTheme = createTheme();

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={true} toggleDrawer={() => {}} />
                <LeftNavbar open={true} toggleDrawer={() => {}} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                        padding: 3,
                        position: 'relative',
                    }}
                >
                    <Toolbar />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{ padding: 3, marginBottom: 3 }}>
                                <Typography variant="h4" gutterBottom>
                                    주의 사항
                                </Typography>
                                <Typography variant="body1">
                                    본 프롬프트 수정 페이지에서 수정한 내용은 즉시 적용되며, 고객과 컨설턴트의 상담 전반에 큰 영향을 미칠 수 있습니다.
                                    다음의 사항은 반드시 숙지하시고, 최고관리자의 승인을 받은 후 수정을 진행해 주시기 바랍니다.

                                    <br></br><br></br>1. 프롬프트 내의 %s, ${'{}'} 등의 특수문자는 반드시 그대로 유지해야 합니다.
                                    <br></br><br></br>2. 프롬프트 내의 "json 형태로 반환하라"는 문구는 반드시 그대로 유지해야 시스템에서 정상적으로 동작합니다.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{ padding: 3 }}>
                                <Typography variant="h4" gutterBottom>
                                    프롬프트 수정
                                </Typography>
                                <Grid container spacing={3}>
                                {prompts.map((prompt, index) => (
                                <Grid item xs={12} key={`${prompt.id}-${prompt.apiName}`}>
                                    <Paper sx={{ padding: 2 }}>
                                        <Typography variant="h6">
                                            {prompt.promptId}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            value={prompt.text}
                                            onChange={(e) => handleTextChange(index, e.target.value)}
                                            onFocus={(e) => e.target.select()}
                                        />
                                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                                            API Name: {prompt.apiName}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ marginTop: 2 }}
                                            onClick={() => handleSave(index)}
                                        >
                                            Save
                                        </Button>
                                    </Paper>
                                </Grid>
                            ))}

                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PromptEditor;
