import React, { useState, useEffect } from "react";
import { useRef } from "react";


const Chat2 = ({ currentQuestion, onAnswerSubmit, setNextQuestion, chatbotStep, setChatbotStep, step, buttonType}) => {
  const [messages, setMessages] = useState([
    {text: "다음으로 구상하고 있는 사업이 어떤 시장 유형(블루오션, 신규 고객, 신제품, 기존 시장)인지 알고자 합니다. 보여지는 화면에 4개의 시장 유형에 대한 정의와 주요 특징에 대한 설명이 표시되어 있습니다. 4가지 중에서 구상하고 있는 사업에 가장 적합하다고 생각되는 한 가지 시장 유형을 선택해주십시오.", isUser: false , step: step }
  ]);
  const [showInputField, setShowInputField] = useState(true);
  const [showSendButton, setShowSendButton] = useState(true);
  const offeringMessage = "그리고  어떤 고객(기업, 정부, 개인)을 대상으로 하는지 그리고 어떤 유형의 제품/서비스(유형의 제품/서비스, 무형의 서비스)를 제공할 것인지를 고객과 오퍼링 항목에서 선택해주십시오(복수 선택 가능).";
  const completionMessage = "모든 답변을 완료했습니다. 답변한 내용이 맞는지 확인해주시고 수정사항이 있으면 수정한 후 제출해주십시오.";


  useEffect(() => {
        console.log("chatbotstep in chat2",chatbotStep);
    if (chatbotStep[1] == 1) {
      checkDuplicateAndAdd(offeringMessage);
    }
    if (chatbotStep[1] == 2) {
      checkDuplicateAndAdd(offeringMessage);
      checkDuplicateAndAdd(completionMessage);
    }
  }, [chatbotStep]);


  const checkDuplicateAndAdd = (checkMessage) => {
    setMessages(prevMessages => {
      // 이전 메시지들의 텍스트와 현재 step을 기준으로 중복 체크
      const isMessageDuplicate = prevMessages.some(message => 
        message.text === checkMessage && message.step === step
      );
  
  
      // 중복되지 않은 경우에만 새 메시지 추가
      if (!isMessageDuplicate) {
        return [...prevMessages, { text: checkMessage, isUser: false, step: step }];
      } else {
        return prevMessages; // 중복이면 이전 메시지 상태 그대로 반환
      }
    });
  };
  
  return (
    <div className="app">
      <div className="chat-box" style={{ fontFamily: '"Pretendard-Regular", Arial, Roboto' }}>
      <h1 style={{marginLeft:'20px'}}>🤖 Step Bot</h1>
            <MessageList
              messages={messages}
              step={step}
            />
      </div>
    </div>
  );
};

const MessageList = ({ messages, step }) => {
  const messagesEndRef = useRef(null);
  const filteredMessages = messages.filter(message => message.step === step);

  // Scroll to bottom whenever the messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [filteredMessages]);

  return (
    <div className="messages-list">
      {filteredMessages.map((message, index) => (
        <Message
          key={index}
          {...message}
        />
      ))}
      <div ref={messagesEndRef} /> {/* Invisible element to scroll to */}
    </div>
  );
};


const Message = ({
  text,
  isUser,
  isTyping,
  id,
  onEndTyping,
  currentTypingId,
  type,
}) => {
  return (
    <div className={isUser ? "user-message" : "ai-message"}>
      {isTyping && currentTypingId === id ? (
        <p>
          <b>Step Bot</b>: {text}
        </p>
      ) : (
        <p>
          <b>{isUser ? "User" : "Step Bot"}</b>: {text}
        </p>
      )}
    </div>
  );
};



const MessageForm = ({ onSendMessage, currentQuestion,isQASessionOver }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (currentQuestion && currentQuestion.answer === "") {
      setMessage(currentQuestion.question);
    }
  }, [currentQuestion]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentQuestion && message !== currentQuestion.question) {
      onSendMessage(message, currentQuestion);
    } else {
      onSendMessage(message);
    }
    setMessage("");
  };

  return (
    <form onSubmit={handleSubmit} className="message-form" style={{ display: 'flex', alignItems: 'center', gap: '1%' }}>
      <textarea
        type="text"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        className="message-input"
        style={{
          width: '80%',
          minHeight: '60px', // 최소 높이
          overflowY: 'hidden' // 스크롤바 숨기기
        }}
        disabled={isQASessionOver}/>
      <button type="submit" className="send-button"
        style={{
          marginLeft: '1%',
          width: '10%',
          height: '50px',

        }}
        disabled={!message || isQASessionOver}>
        Send
      </button>
    </form>
  );
};


export default Chat2;
