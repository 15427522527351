import * as React from 'react';
import { Box, CssBaseline } from '@mui/material';
import 'react-data-grid/lib/styles.css';
import { useEffect, useState, useRef } from 'react';
import '../../styles/ConsultingTable.css';
import Textarea from '@mui/joy/Textarea';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Button} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {updatestatus} from '../Utils';
const API_URL = process.env.REACT_APP_API_ORIGIN;

export default function CreatePersona() {
  const [rows, setRows] = useState([]);
  const [clientUid, setClientUid] = useState('');
  const [editIdx, setEditIdx] = useState(-1); 
  const [open,setOpen] = useState(false);//제출확인창
  const [openSnackbar,setOpenSnackbar] = useState(false);//수정완료창
  const navigate = useNavigate();
  const [persona1, setPersona1] = useState({});
  const [persona2, setPersona2] = useState({});
  const [persona3, setPersona3] = useState({});
  const [navopen, setNavOpen] = useState(true);
  const [question, setQuestion] = useState(["항목1", "항목2", "항목3", "항목4", "항목5", "항목6", "항목7", "항목8", "항목9", "항목10"]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let client_uid;

        if (localStorage.getItem('userType') === 'consultant')
        {
          const clientId = localStorage.getItem('clientId');
          const clientUidResponse = await axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
              headers: {
                  'Content-Type': 'application/json',
              },
          });
          client_uid = (clientUidResponse.data);
        }
        else//client가 접속
        {
          client_uid = (localStorage.getItem('uid'));
        }
        console.log("!",client_uid)
        setClientUid(client_uid)

        const [response] = await Promise.all([
          fetch(`${API_URL}/persona/getPersonaQuestionsByUid/uid=${client_uid}`),
        ]);
        const data = await response.json();
        console.log("questions",data);

        if (data.length === 0) {
          for (let i = 0; i < 10; i++) {
            const question = {
              questionId: clientUid + "_" + (i + 1),
              question: `항목${i + 1}`,
            };
            data.push(question);
          }
        }

        console.log("data의 길이가 0임으로 10개 새롭게 추가",data);

        // data의 .questionId를 "_"를 기준으로 split
        for (let i = 0; i < data.length; i++) {
          const questionId = data[i].questionId;
          const splitQuestionId = questionId.split('_');
          data[i].questionId = splitQuestionId[1];
        }

        // questionId를 기준으로 정렬
        data.sort((a, b) => a.questionId - b.questionId);


        setRows(data);
        setQuestion(data.map((item) => item.question));
        const personaResponse1 = await fetch(`${API_URL}/persona/getPersonaByPersonaId/uid_number=${client_uid}_1`);
        const persona1_data = await personaResponse1.json();
        console.log("persona 1",persona1_data);

        const personaResponse2 = await fetch(`${API_URL}/persona/getPersonaByPersonaId/uid_number=${client_uid}_2`);
        const persona2_data = await personaResponse2.json();
        console.log("persona 2",persona2_data);

        const personaResponse3 = await fetch(`${API_URL}/persona/getPersonaByPersonaId/uid_number=${client_uid}_3`);
        const persona3_data = await personaResponse3.json();
        console.log("persona 3",persona3_data);

        setPersona1(persona1_data);
        setPersona2(persona2_data);
        setPersona3(persona3_data);
      }

      catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();

  }, []);

  useEffect(() => {
    if (rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        updateQuestion(i);
        console.log("update question", i);
      }
    }
  }, [rows]);

  const getUidById = async (clientId) => {
    try {
      const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
      const clientUid = await clientUidResponse.text();
      return clientUid;
    }
    catch (error) {
      console.error('Error getting clientId:', error);
    }
  }

  const sendUpdateToServer = async (persona_idx, question_idx) => {
    try {
      const personaId = `${clientUid}_${persona_idx}`;
      const questionId = question_idx;
      let updatedAnswer;
  
      switch(persona_idx) {
        case 1:
          updatedAnswer = persona1[`answer${question_idx}`];
          break;
        case 2:
          updatedAnswer = persona2[`answer${question_idx}`];
          break;
        default:
          updatedAnswer = persona3[`answer${question_idx}`];
          break;
      }
  
      console.log("Persona data:", persona1);  // 전체 데이터 로그
      console.log("Sending message:", updatedAnswer);
  
      const response = await axios.post(
        `${API_URL}/persona/setPersonaAnswer/uid_number=${personaId}/question_id=${questionId}`, 
        JSON.stringify({ answer: updatedAnswer }), 
        {
          headers: {
            'Content-Type': 'application/json'  // 콘텐트 타입 명시
          }
        } 
      );
  
      console.log('Updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating data:', error);
      // 여기에서 사용자에게 오류를 알리는 UI 업데이트가 필요할 수 있습니다.
    }
  };

  const sendUpdateNameToServer = async (persona_idx) => {
    try {
      const personaId = `${clientUid}_${persona_idx}`;
      let updatedName = '';
      switch(persona_idx) {
        case 1:
          updatedName = persona1.personaName;
          break;
        case 2:
          updatedName = persona2.personaName;
          break;
        case 3:
          updatedName = persona3.personaName;
          break;
        default:
          break;
      }
      const response = await axios.post(
        `${API_URL}/persona/setPersonaName/uid_number=${personaId}`, 
        updatedName, 
        {
          headers: {
            'Content-Type': 'text/plain'  // 콘텐트 타입 명시
          }
        } 
      );
  
      console.log('Updated successfully:', response.data);
    }
    catch (error) {
      console.error('Error updating data:', error);
      // 여기에서 사용자에게 오류를 알리는 UI 업데이트가 필요할 수 있습니다.
    }
  };

  const handleEdit = (idx) => {
    setEditIdx(idx); // 편집할 행의 인덱스를 설정합니다.
  };

  const handleSave = async (persona_idx, question_idx) => {
    setEditIdx(-1); // 편집 모드를 종료합니다.
    await sendUpdateToServer(persona_idx,question_idx); // 변경된 행을 서버로 전송합니다.
    setOpenSnackbar(true);
  };

  const handleSaveName = async (persona_idx) => {
    setEditIdx(-1); // 편집 모드를 종료합니다.
    await sendUpdateNameToServer(persona_idx); // 변경된 행을 서버로 전송합니다.
    setOpenSnackbar(true);
  };

  const handleChange = (e, persona_idx, answer_idx) => {
    let updatedPersona;
    if(persona_idx === 1){
      updatedPersona = {...persona1, [answer_idx]: e.target.value};
      setPersona1(updatedPersona);
    }
    else if(persona_idx === 2){
      updatedPersona = {...persona2, [answer_idx]: e.target.value};
      setPersona2(updatedPersona);
    }
    else{
      updatedPersona = {...persona3, [answer_idx]: e.target.value};
      setPersona3(updatedPersona);
    }
  };

  const ConfirmDialog = ({onClose, onConfirm }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = async () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시

      //updateUserStatus(17);
      await updatestatus(clientUid,'insight',7);
      await updatestatus(clientUid, 'strategy',1);
  
      await onConfirm(); // 실제 작업 수행
      setConfirming(false);
    };
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>페르소나 작성을 완료하시겠습니까?</DialogTitle>
        <DialogContent>
          <DialogContentText>
              {/* <Alert severity="info">문장</Alert> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
          {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const updateUserStatus = async (updateValue) => {
    try {
      const response = await axios.post(`${API_URL}/auth/setStatus`, {
        uid: clientUid,
        status: updateValue,
      });
      console.log("userStatus updated successfully");
      localStorage.setItem('userStatus', updateValue);
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  const handleQuestionChange = (e, idx) => {
    const newRows = [...rows];
    newRows[idx].question = e.target.value;
    setRows(newRows);
  };
  
  const updateQuestion = async (idx) => {
    try {
      const response = await axios.post(`${API_URL}/persona/setPersonaQuestion/question_id=${clientUid + "_" + rows[idx].questionId}`, 
        rows[idx].question,
        {
          headers: {
            'Content-Type': 'text/plain'  // 콘텐트 타입 명시
          }
        } 
      )
      ;
      console.log("Question updated successfully");
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  const toggleDrawer = () => {
    setNavOpen(!navopen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, marginTop: 15, marginLeft: 5, marginRight: 5, marginBottom: 20, backgroundColor: 'white' }}>
        {/* <Table2/> */}
        <table className="MyTable">
          <thead>
            <tr>
              <th rowSpan={2} colSpan={2} style={{ textAlign: 'center' }}>특징</th>
              <th colSpan={3} style={{ textAlign: 'center' }}>SOM시장 후보</th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center' }} onClick={() => handleEdit(100)}>
                {editIdx === 100 ? (
                  <textarea
                    value={persona1.personaName}
                    onChange={(e) => handleChange(e, 1, 'personaName')}
                    onBlur={() => handleSaveName(1)}
                  />
                ) : (
                  <span>페르소나 1: {persona1.personaName}</span>
                )}
              </th>
              <th style={{ textAlign: 'center' }} onClick={() => handleEdit(200)}>
                {editIdx === 200 ? (
                  <textarea
                    value={persona2.personaName}
                    onChange={(e) => handleChange(e, 2, 'personaName')}
                    onBlur={() => handleSaveName(2)}
                  />
                ) : (
                  <span>페르소나 2: {persona2.personaName}</span>
                )}</th>
              <th style={{ textAlign: 'center' }} onClick={() => handleEdit(300)}>
                {editIdx === 300 ? (
                  <textarea
                    value={persona3.personaName}
                    onChange={(e) => handleChange(e, 3, 'personaName')}
                    onBlur={() => handleSaveName(3)}
                  />
                ) : (
                  <span>페르소나 3: {persona3.personaName}</span>
                )}</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td style={{ textAlign: 'center' }}>{idx + 1}</td>
                  {/* <td style={{whiteSpace: 'pre-wrap'}}>{item.question}</td> */}
                  <td style={{ position: 'relative' }} onClick={() => handleEdit(idx * 1000)}> 
                  {editIdx === idx*1000 ? (
                      <textarea
                        value={item.question}
                        onChange={(e) => handleQuestionChange(e, idx)}
                        onBlur={() => updateQuestion(idx)}
                        style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                      />
                    ) : (
                      <span>{item.question}</span>
                    )}
                  </td>

                  <td onClick={() => handleEdit(idx)} style={{ position: 'relative' }}>
                    {editIdx === idx ? (
                      <textarea
                        value={persona1[`answer${idx + 1}`]}
                        onChange={(e) => handleChange(e, 1, `answer${idx + 1}`)}
                        onBlur={() => handleSave(1, idx + 1)}
                        style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                      />
                    ) : (
                      <span>{persona1[`answer${idx + 1}`]}</span>
                    )}
                  </td>
                  <td onClick={() => handleEdit(idx)} style={{ position: 'relative' }}>
                    {editIdx === idx ? (
                      <textarea
                        style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                        value={persona2[`answer${idx + 1}`]}
                        onChange={(e) => handleChange(e, 2, `answer${idx + 1}`)}
                        onBlur={() => handleSave(2, idx + 1)}
                      />
                    ) : (
                      <span>{persona2[`answer${idx + 1}`]}</span>
                    )}</td>
                  <td onClick={() => handleEdit(idx)} style={{ position: 'relative' }}>
                    {editIdx === idx ? (
                      <textarea
                        style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                        value={persona3[`answer${idx + 1}`]}
                        onChange={(e) => handleChange(e, 3, `answer${idx + 1}`)}
                        onBlur={() => handleSave(3, idx + 1)}
                      />
                    ) : (
                      <span>{persona3[`answer${idx + 1}`]}</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Fab color="secondary"
          variant="extended"
          onClick={() => setOpen(true)}
          sx={{
            position: 'fixed',
            width: 130,
            bottom: 30,
            right: 30,
            borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
            boxShadow: '0, 0, 0, 0.5',
          }} >
          <span>작성완료</span>
          <RightArrow />
        </Fab>
        <Fab color="primary"
                    varient="extended"
                    onClick={() => { window.location.href = `${API_URL}/file/download/insight_${clientUid}.pptx`; }}
                    sx={{
                        position: 'fixed',
                        width: 140,
                        bottom: 30,
                        right: 180,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>Insight pptx <br /> 다운로드</span>
                </Fab>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            수정사항이 저장되었습니다!
          </Alert>
        </Snackbar>
        <ConfirmDialog onClose={() => setOpen(false)} onConfirm={() => navigate('/ConsultantFile')} />
      </Box>
    </Box>
  );
}