import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { TextField, Paper, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import axios from 'axios';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import styles from '../../styles/factTable.module.css';
import CategoryTable from './CategoryTable';

const API_URL = process.env.REACT_APP_API_ORIGIN;


const Document1 = React.forwardRef(({dataform, questions, moveToNextStep, setIsQASessionOver, updateBusinessType, userStatus }, ref) => {
  const theme = useTheme();

  const [answerlist, setAnswerlist] = useState([]);
  const [answerStatus, setAnswerStatus] = useState(0);//모두 답변됐는지

  const updatedQuestions = useMemo(() => [...questions], [questions]);
  const [data,setData] = useState(dataform)
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [saveSpinner, setSaveSpinner] = useState(false);
  const categoryTableRef = useRef(null);
  const [showCategory, setShowCategory] = useState(userStatus > 1);

  const uid = localStorage.getItem('uid');



  useEffect(() => {

    //답변한 것만
    console.log("data확인", data)
    setAnswerStatus(0);

    const checkAnswerNumber = data.filter(q => q[0].answerText && q[1].answerText).length;
    const submitdata = data.flatMap(q => {
      return [q[0], q[1]].filter(item => item.uid && item.answerText);
    });// 수정한거 있으면 저장하기 위해
    setAnswerlist(submitdata)
    console.log("개수확인", checkAnswerNumber, data.length)

    if (checkAnswerNumber === data.length) {
      setIsQASessionOver(true);
      setAnswerStatus(1);
      console.log("호출")
    }
  }, [data])

  useEffect(() => {
    if (showCategory && categoryTableRef.current) {
      categoryTableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showCategory]);

  const classifyCategory1 = () => {

    let type1
    let category1_valuechain = 0;
    let category1_platform = 0;
    data.forEach(row => {
      if ([1, 3, 5, 7, 9].includes(row[0].questionId) && row[0].answerText === '예') {
        category1_valuechain++;
      }
      if ([2, 4, 6, 8, 10].includes(row[1].questionId) && row[1].answerText === '예') {
        category1_platform++;
      }
    });
    
    console.log("사업유형",category1_valuechain,category1_platform)
    // 사업유형
    if (category1_valuechain > category1_platform)
      type1 = "밸류체인";
    else
      type1 = "플랫폼";
    return type1
  }

  const handleCheckboxChange = (index, questionIndex) => {
    const newData = [...data];
      newData[index][questionIndex].uid = uid;
      newData[index][(questionIndex + 1) % 2].uid = uid;
      newData[index][questionIndex].answerText = "예";
      newData[index][(questionIndex + 1) % 2].answerText = "아니오";
    setData(newData);
  };

  const setCategoryToServer = async (categoryData) => {
    try {
        const response = await axios.post(`${API_URL}/auth/setBusinessType`, {
            uid: uid,
            businessType: categoryData,
        });
        console.log(response.data);
    } catch (error) {
        console.error('Error submitting category:', error);
    }
};

  //제출 dialog
  const ConfirmDialog = ({ onClose }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirmClick = async () => {
      setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
    
      // setTimeout을 Promise로 래핑하여 await를 사용
      await new Promise(resolve => setTimeout(resolve, 2000));
    
      await saveAnswersToServer();
      const category = classifyCategory1();
      // setCategory1(category);
      await updateBusinessType(category);
      setShowCategory(true);
      setOpen(false);
      setConfirming(false);
    };
    
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>답변을 제출하시겠습니까?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            답변이 최종 저장되고 수정할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
            {confirming ? <CircularProgress size={24} /> : '확인'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const AlertDialog = ({ onClose }) => {
    return (
      <Dialog open={showAlert} onClose={onClose}>

        <DialogTitle>답변을 완료한 후 제출해주세요.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning" >
              답변 목록에 빈 답변이 있습니다.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const hadleSaveAnswer = async () => { // 임시 저장버튼
    setSaveSpinner(true)
    await saveAnswersToServer();
    setTimeout(() => {
      setSaveSpinner(false)
      setOpenSnackbar(true)

    }, 1000);
  }

  const handleSubmit = () => { //제출 버튼
    if (answerStatus === 1) {

      setOpen(true)
    }
    else {
      setShowAlert(true)
    }
  };

  const saveAnswersToServer = async () => { //답변 저장 api호출
    try {
      const response = await axios.post(`${API_URL}/api/answers/submit`, answerlist);
      console.log(response.data);


    } catch (error) {
      console.error('Error submitting documents:', error);
    }
  };

  return (
    <Paper className={styles.factTable} style={{ fontFamily: '"Pretendard-Regular", Arial, Roboto',lineHeight: "1.4"  }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <div className="table-container">
                <table style={{marginBottom:"20px"}}>
                  <thead>
                    <tr>
                      <th colSpan="5"  style={{backgroundColor : '#BDD7EE'}}>사업모델 유형</th>
                    </tr>
                  </thead>
                   <tbody>
                  {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="cell">{row[0]?.questionClass}</td>
                      <td className="cell checkbox-cell">
                        <input
                          type="checkbox"
                          checked={row[0]?.answerText === "예"}
                          onChange={() => handleCheckboxChange(rowIndex, 0)}
                          disabled={showCategory}
                        />
                      </td>
                      <td className="cell">{row[0]?.question}</td>
                      {row[1] && (
                        <>
                          <td className="cell checkbox-cell">
                            <input
                              type="checkbox"
                              checked={row[1]?.answerText === "예"}
                              onChange={() => handleCheckboxChange(rowIndex, 1)}
                              disabled={showCategory}
                            />
                          </td>
                          <td className="cell">{row[1]?.question}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
               </table>
           </div>
           <div ref={categoryTableRef}></div>
           <div>
           {showCategory && <CategoryTable />}
           </div>

      {!showCategory && <Fab
        varient="extended"
        onClick={hadleSaveAnswer}
        sx={{
          color: 'common.white',
          bgcolor: green[500],
          '&:hover': {
            bgcolor: green[600],
          },
          position: 'fixed',
          width: 100,
          bottom: 30,
          right: 150,
          borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
          boxShadow: '0, 0, 0, 0.5',
        }}
        disabled={saveSpinner} // 작업이 진행 중일 때 버튼을 비활성화합니다.
      >
        {saveSpinner ? <CircularProgress size={24} /> : <span>임시저장</span>} {/* 스피너 또는 텍스트 표시 */}
      </Fab>}
      {!showCategory && <Fab color="secondary"
        varient="extended"
        onClick={handleSubmit}
        sx={{
          position: 'fixed',
          width: 100,
          bottom: 30,
          right: 30,
          borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
          boxShadow: '0, 0, 0, 0.5',
        }} >
        <span>제출</span>
        <RightArrow />
      </Fab>}
      {showCategory && <Fab color="primary"
        varient="extended"
        onClick={() => {
          setTimeout(() => {
            moveToNextStep();
          }, 1000);
        }}
      
        sx={{
          position: 'fixed',
          width: 100,
          bottom: 30,
          right: 30,
          borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
          boxShadow: '0, 0, 0, 0.5',
        }} >
        <span>다음</span>
        <RightArrow />
      </Fab>}

      <ConfirmDialog onClose={() => setOpen(false)} />
      <AlertDialog onClose={() => setShowAlert(false)} />
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          답변이 저장되었습니다!
        </Alert>
      </Snackbar>
    </Paper>

  );
});
export default Document1;
