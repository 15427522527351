import React, { useState, useEffect } from "react";
import { useRef } from "react";


const Chat = ({ currentQuestion, setNextQuestion, isQASessionOver, step, category1, buttonType}) => {
  const [messages, setMessages] = useState([
    {text: "안녕하세요. 고객님께서 구상하고 계신 아이디어를 진단하고 필요한 조언을 주고자 합니다.  먼저 구상하고 있는 사업이 어떤 유형의 사업 모델인지를 알고자 합니다. 보여지는 화면에는 각 영역마다 2개의 항목이 있습니다. 2개의 항목 중에서 더 적합하다고 생각하는 항목을 선택해주십시오.", isUser: false , step: step }
  ]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [currentTypingId, setCurrentTypingId] = useState(null);
  const [category,setCategory] = useState(null);
  const messagesEndRef = useRef(null);

  
  useEffect(() => {
    const completionMessage = "모든 답변을 완료했습니다. 답변한 내용이 맞는지 확인해주시고 수정사항이 있으면 수정한 후 제출해주십시오.";

    if (isQASessionOver) {
      checkDuplicateAndAdd(completionMessage)
    }
  }, [isQASessionOver]);

  useEffect(() => {
    const category_data = category1 ? category1 : null;
    console.log("1",category1);
    console.log("2",category_data);
    setCategory(category_data);
    if(category_data == null)
      return;
    const categoryText = `고객님께서 구상하고 계신 사업은 ${category_data} 사업모델로 판단됩니다. 사업모델에는 플랫폼형과 밸류체인형이 있으며 다음과 같은 특징을 가지고 있습니다.`;
    checkDuplicateAndAdd(categoryText);
  }, [category1]);

  const checkDuplicateAndAdd = (checkMessage) => {
    setMessages(prevMessages => {
      // 이전 메시지들의 텍스트와 현재 step을 기준으로 중복 체크
      const isMessageDuplicate = prevMessages.some(message => 
        message.text === checkMessage && message.step === step
      );
  
      console.log(isMessageDuplicate);
  
      // 중복되지 않은 경우에만 새 메시지 추가
      if (!isMessageDuplicate) {
        return [...prevMessages, { text: checkMessage, isUser: false, step: step }];
      } else {
        return prevMessages; // 중복이면 이전 메시지 상태 그대로 반환
      }
    });
  };
  
  return (
    <div className="app">
      <div className="chat-box" style={{ fontFamily: '"Pretendard-Regular", Arial, Roboto', fontWeight:100}}>
      <h1 style={{marginLeft:'20px'}}>🤖 Step Bot</h1>
            <MessageList
              messages={messages}
              step={step}
            />
      </div>
    </div>
  );
};

const MessageList = ({ messages, step }) => {
  const messagesEndRef = useRef(null);
  const filteredMessages = messages.filter(message => message.step === step);

  // Scroll to bottom whenever the messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [filteredMessages]);

  return (
    <div className="messages-list">
      {filteredMessages.map((message, index) => (
        <Message
          key={index}
          {...message}
        />
      ))}
      <div ref={messagesEndRef} /> {/* Invisible element to scroll to */}
    </div>
  );
};


const Message = ({
  text,
  isUser,
  isTyping,
  id,
  onEndTyping,
  currentTypingId,
  type,
}) => {
  return (
    <div className={isUser ? "user-message" : "ai-message"}>
      {isTyping && currentTypingId === id ? (
        <p>
          <b>Step Bot</b>: {text}
        </p>
      ) : (
        <p>
          <b>{isUser ? "User" : "Step Bot"}</b>: {text}
        </p>
      )}
    </div>
  );
};



const MessageForm = ({ onSendMessage, currentQuestion,isQASessionOver }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (currentQuestion && currentQuestion.answer === "") {
      setMessage(currentQuestion.question);
    }
  }, [currentQuestion]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentQuestion && message !== currentQuestion.question) {
      onSendMessage(message, currentQuestion);
    } else {
      onSendMessage(message);
    }
    setMessage("");
  };

  return (
    <form onSubmit={handleSubmit} className="message-form" style={{ display: 'flex', alignItems: 'center', gap: '1%' }}>
      <textarea
        type="text"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        className="message-input"
        style={{
          width: '80%',
          minHeight: '60px', // 최소 높이
          overflowY: 'auto'
        }}
        disabled={isQASessionOver}/>
      <button type="submit" className="send-button"
        style={{
          marginLeft: '1%',
          width: '10%',
          height: '50px',

        }}
        disabled={!message || isQASessionOver}>
        Send
      </button>
    </form>
  );
};

export default Chat;
