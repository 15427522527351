import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Axios from 'axios';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const FisaTool = ({ page }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const uid = localStorage.getItem('uid');
    if (!uid) {
      console.error('No uid found in local storage');
      return;
    }

    const fetchPdf = async () => {
      try {
        // 페이지에 따른 파일 이름 생성
        const fileName = `fisa_methodology_${page}.pdf`;
        const response = await Axios.get(`${API_URL}/file/download/${fileName}`, {
          responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
        setPdfUrl(`${pdfUrl}#page=1&toolbar=0`);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, [page]);

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ width: '100%', height: '100vh', border: 'none' }}
          ></iframe>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default FisaTool;
