import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider, Menu, MenuItem } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { updatestatus } from '../Utils';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const ConsultantFile = () => {
    const clientId = localStorage.getItem('clientId');
    const [open, setOpen] = useState(true);
    const [openconfirmfact, setOpenConfirmFact] = useState(false);
    const [openconfirminsight, setOpenConfirmInsight] = useState(false);
    const [openconfirmstrategy, setOpenConfirmStrategy] = useState(false);
    const [status, setStatus] = useState('-1'); // 초기 상태값은 0로 설정
    const navigate = useNavigate();
    const [uid, setUid] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [insightSelectedFile, setInsightSelectedFile] = useState(null);
    const [strategySelectedFile, setStrategySelectedFile] = useState(null);
    const [actionSelectedFile, setActionSelectedFile] = useState(null);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [userData, setUserData] = useState(null);
    const theme = useTheme();
    const [backgroundList, setBackgroundList] = useState([]);
    const [isBackgroundOpen, setIsBackgroundOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null); // 메뉴 앵커

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // 메뉴가 열릴 기준점을 설정
    };

    const handleClose = () => {
        setAnchorEl(null); // 메뉴 닫기
    };

    const handleMenuItemClick = async (fileName) => {
        try {
            const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
            const uid = uidResponse.data;

            const response = await Axios.get(`${API_URL}/file/download/${fileName}`, {
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);

            // 새 창에서 PDF 파일을 엽니다
            window.open(pdfUrl, '_blank');

        } catch (error) {
            console.error('Error fetching PDF:', error);
        }

        setAnchorEl(null); // 메뉴 닫기
    };

    const handleBackgroundFileClick = async (fileName) => {
        try {
            const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
            const uid = uidResponse.data;
            const response = await Axios.get(`${API_URL}/file/download/${fileName}`, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);
            setPdfUrl(pdfUrl); // PDF 파일을 보여줄 수 있도록 상태 업데이트
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };


    const sendEmails = async (body) => {
        console.log(userData);
        if (userData) {
            try {
                for (const user of userData) {
                    const emailData = new URLSearchParams();
                    emailData.append('from', 'FISA@stepstonekr.com');
                    emailData.append('to', user.email);
                    emailData.append('subject', '단계 완료 알림입니다.');
                    emailData.append('body', body);

                    await axios.post(`${API_URL}/api/email/send`, emailData, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    });
                }
                console.log('Emails sent successfully');
            } catch (error) {
                console.error('Error sending emails:', error);
            }
        }
    };

    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
        window.location.reload(); // 확인 버튼을 클릭하면 페이지 이동
    };

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };

    const handleDialog = (title, message, isError = false) => {
        setDialogTitle(title);
        setDialogMessage(message);
        if (isError) {
            setOpenErrorDialog(true);
        } else {
            setOpenSuccessDialog(true);
        }
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // 클라이언트 아이디로부터 uid 가져오기
                console.log(clientId)
                const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                setUid(uidResponse.data);
                const backgroundresponse = await Axios.get(`${API_URL}/file/list/background_${uidResponse.data}`);
                setBackgroundList(backgroundresponse.data); // 리스트 저장

                const engageresponse = await axios.get(`${API_URL}/consultant/getConsultEngagement/clientUid=${uidResponse.data}`);
                console.log(engageresponse);

                // Assuming response.data contains an array of consultants
                const clientIds = new Set(engageresponse.data.map(item => item.clientId));

                const userdata = await fetch(`${API_URL}/consultant/getUserList`);
                const engagedata = await userdata.json();
                console.log(engagedata);

                // Filter to keep only users with ids in the consultantIds set
                const filteredUsers = engagedata.filter(user => clientIds.has(user.id));
                setUserData(filteredUsers);

                // uid를 사용하여 상태 가져오기
                const response = await Axios.get(`${API_URL}/auth/getStatus`, {
                    params: {
                        uid: uidResponse.data
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // Handle the response as needed
                console.log(response.data);
                setStatus(response.data);
                localStorage.setItem('userStatus', response.data);
            } catch (error) {
                // Handle errors
                console.error('Error submitting form:', error);
            }
        };

        // fetchData 함수를 호출하여 비동기 작업 실행
        fetchData();
    }, []); // 빈 배열을 두어 마운트될 때 한 번만 실행되도록 함

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]); // 파일 선택 시 호출되는 함수
    };

    const handleInsightFileChange = (event) => {
        setInsightSelectedFile(event.target.files[0]); // 파일 선택 시 호출되는 함수
    };

    const handleStrategyFileChange = (event) => {
        setStrategySelectedFile(event.target.files[0]); // 파일 선택 시 호출되는 함수
    };

    const handleActionFileChange = (event) => {
        setActionSelectedFile(event.target.files[0]); // 파일 선택 시 호출되는 함수
    };

    const handleFileUpload = async () => {
        try {
            const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const uid = uidResponse.data; // uid 값 추출
            const formData = new FormData();

            //파일이 pdf인지 확인
            if (selectedFile.name.split('.').pop() !== 'pdf') {
                handleDialog('업로드 실패', '파일 형식이 올바르지 않습니다. PDF 파일을 업로드해주세요.');
                return;
            }

            const modifiedFileName = `fact_${uid}.pdf`; // uid를 사용하여 파일 이름 생성
            formData.append('file', selectedFile, modifiedFileName);
            sendEmails('고객님의 Fact 단계 보고서가 업로드되었습니다. 확인해주세요.<br><br> 이 이메일은 발신 전용 이메일 입니다. 회신하시더라도 답변되지 않습니다.');

            // 파일 업로드 요청
            await Axios.post(`${API_URL}/file/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // 파일 업로드 후 필요한 작업 수행
            console.log('File uploaded successfully!');

            setTimeout(() => {
                handleDialog('업로드 성공', '파일 업로드가 성공적으로 완료되었습니다.');
            }, 2000);

            // try {
            //     const response = await Axios.post(`${API_URL}/auth/setStatus`, {
            //         uid: uid,
            //         status: 11,
            //     });
            //     console.log("userStatus updated successfully");
            //     localStorage.setItem('userStatus', 11);
            //     return response.data;
            // } catch (error) {
            //     // 요청이 실패하면 에러를 반환
            //     throw error;
            // }

            await updatestatus(uid, 'fact', 99);


        } catch (error) {
            console.error('Error uploading file:', error);
            handleDialog('업로드 실패', '업로드에 실패했습니다. 잠시 후 다시 시도해주십시오.');
        }
    };

    const handleInsightFileUpload = async () => {
        try {
            const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const uid = uidResponse.data; // uid 값 추출
            const formData = new FormData();
            const modifiedFileName = `insight_${uid}.pdf`; // uid를 사용하여 파일 이름 생성

            //파일이 pdf인지 확인
            if (insightSelectedFile.name.split('.').pop() !== 'pdf') {
                handleDialog('업로드 실패', '파일 형식이 올바르지 않습니다. PDF 파일을 업로드해주세요.');
                return;
            }

            formData.append('file', insightSelectedFile, modifiedFileName);
            sendEmails('고객님의 Insight 단계 보고서가 업로드되었습니다. 확인해주세요. <br><br>이 이메일은 발신 전용 이메일 입니다. 회신하시더라도 답변되지 않습니다.');

            // 파일 업로드 요청
            await Axios.post(`${API_URL}/file/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // 파일 업로드 후 필요한 작업 수행
            console.log('File uploaded successfully!');

            setTimeout(() => {
                handleDialog('업로드 성공', '파일 업로드가 성공적으로 완료되었습니다.');
            }, 2000);

            // try {
            //     const response = await Axios.post(`${API_URL}/auth/setStatus`, {
            //         uid: uid,
            //         status: 21,
            //     });
            //     console.log("userStatus updated successfully");
            //     localStorage.setItem('userStatus', 21);
            //     return response.data;
            // } catch (error) {
            //     // 요청이 실패하면 에러를 반환
            //     throw error;
            // }

            await updatestatus(uid, 'insight', 99);
            await updatestatus(uid, 'strategy', 1);

        } catch (error) {
            console.error('Error uploading file:', error);
            handleDialog('업로드 실패', '업로드에 실패했습니다. 잠시 후 다시 시도해주십시오.');
        }
    };

    const handleStrategyFileUpload = async () => {
        try {
            const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const uid = uidResponse.data; // uid 값 추출
            const formData = new FormData();

            //파일이 pdf인지 확인
            if (strategySelectedFile.name.split('.').pop() !== 'pdf') {
                handleDialog('업로드 실패', '파일 형식이 올바르지 않습니다. PDF 파일을 업로드해주세요.');
                return;
            }

            const modifiedFileName = `strategy_${uid}.pdf`; // uid를 사용하여 파일 이름 생성
            formData.append('file', strategySelectedFile, modifiedFileName);
            sendEmails('고객님의 Strategy 단계가 완료되었습니다. 확인해주세요. <br><br> 이 이메일은 발신 전용 이메일 입니다. 회신하시더라도 답변되지 않습니다.');

            // 파일 업로드 요청
            await Axios.post(`${API_URL}/file/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // 파일 업로드 후 필요한 작업 수행
            console.log('File uploaded successfully!');

            setTimeout(() => {
                handleDialog('업로드 성공', '파일 업로드가 성공적으로 완료되었습니다.');
            }, 2000);

            // try {
            //     const response = await Axios.post(`${API_URL}/auth/setStatus`, {
            //         uid: uid,
            //         status: 31,
            //     });
            //     console.log("userStatus updated successfully");
            //     localStorage.setItem('userStatus', 40);
            //     return response.data;
            // } catch (error) {
            //     // 요청이 실패하면 에러를 반환
            //     throw error;
            // }

            await updatestatus(uid, 'strategy', 99);

        } catch (error) {
            console.error('Error uploading file:', error);
            handleDialog('업로드 실패', '업로드에 실패했습니다. 잠시 후 다시 시도해주십시오.');
        }
    };

    const handleActionFileUpload = async () => {
        try {
            const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const uid = uidResponse.data; // uid 값 추출
            const formData = new FormData();
            const modifiedFileName = `fact_${uid}.pdf`; // uid를 사용하여 파일 이름 생성
            formData.append('file', actionSelectedFile, modifiedFileName);

            // 파일 업로드 요청
            await Axios.post(`${API_URL}/file/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // 파일 업로드 후 필요한 작업 수행
            console.log('File uploaded successfully!');

        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        setSelectedFile(file);
    };

    const handleInsightDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        setInsightSelectedFile(file);
    };

    const handleStrategyDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        setStrategySelectedFile(file);
    };

    const handleActionDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        setActionSelectedFile(file);
    };

    const FactConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
            await onConfirm(); // 실제 작업 수행
            setOpenConfirmFact(false);
            setConfirming(false);
        };
        return (
            <Dialog open={openconfirmfact} onClose={onClose}>
                <DialogTitle>파일을 업로드 하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">업로드 된 파일을 고객이 확인할 수 있습니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const InsightConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
            await onConfirm(); // 실제 작업 수행
            setOpenConfirmInsight(false);
            setConfirming(false);
        };
        return (
            <Dialog open={openconfirminsight} onClose={onClose}>
                <DialogTitle>파일을 업로드 하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">업로드 된 파일을 고객이 확인할 수 있습니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const StrategyConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
            await onConfirm(); // 실제 작업 수행
            setOpenConfirmStrategy(false);
            setConfirming(false);
        };
        return (
            <Dialog open={openconfirmstrategy} onClose={onClose}>
                <DialogTitle>파일을 업로드 하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">업로드 된 파일을 고객이 확인할 수 있습니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const factstatus = status % 100;
    const insightstatus = Math.floor((status % 10000) / 100);
    const strategystatus = Math.floor((status % 1000000) / 10000);
    const actionstatus = Math.floor(status / 1000000);

    const routes = [
        { path: `/FactFile/${uid}`, label: <p>Fact 보고서 <br /> 확인하기</p>, check: factstatus },
        { path: `/InsightFile/${uid}`, label: <p>Insight 보고서 <br /> 확인하기</p>, check: insightstatus },
        { path: `/StrategyFile/${uid}`, label: <p>Strategy 보고서 <br /> 확인하기</p>, check: strategystatus },
        { path: '/ActionDetail', label: <p>Action 보고서 <br /> 확인하기</p>, check: actionstatus }
    ];

    const handleButtonClick = (route) => {
        if (route.check === 99) {
            navigate(route.path);
        } else {
            console.log("이 작업을 수행할 수 있는 충분한 권한이 없습니다.");
        }
    };


    console.log("status", status);
    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column', // 세로 방향으로 정렬
                        alignItems: 'center', // 가로 중앙 정렬
                        height: '100vh',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3} style={{ height: '100%' }}>
                            {/* 그리드 1: Fact */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {/* 고객 참고자료 확인하기 버튼 */}
                                {/* <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: backgroundList.length > 0 ? theme.palette.primary.main : undefined,
                                        color: backgroundList.length > 0 ? theme.palette.primary.contrastText : undefined,
                                    }}
                                    disabled={backgroundList.length === 0} // 리스트가 비어 있으면 비활성화
                                >
                                    고객 참고자료 확인하기
                                </Button> */}

                                {/* 메뉴: 고객 참고자료 리스트 */}
                                {/* <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {backgroundList.map((fileName, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={() => handleMenuItemClick(fileName)}
                                        >
                                            {index + 1}번 자료
                                        </MenuItem>
                                    ))}
                                </Menu> */}
                                <Button
                                    variant="contained"
                                    onClick={() => navigate('/consultantfact1')}
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: factstatus >= 2 ? (factstatus < 4 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: factstatus >= 2 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={factstatus < 2}
                                >
                                    시장유형 진단하기 <br /> 질문 수정하기
                                </Button>

                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: factstatus >= 4 ? (factstatus < 5 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: factstatus >= 4 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={factstatus < 4} // 상태가 9 이상이면 활성화
                                    onClick={() => factstatus >= 4 && navigate('/ConsultingTable')}
                                >
                                    사업아이디어 진단<br />
                                </Button>
                                {/* Tam/Sam 프롬프트 생성 페이지 */}
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px', width: '100%',
                                        backgroundColor: factstatus >= 5 ? (factstatus < 6 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: factstatus >= 5 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={factstatus < 5} // 상태가 5 이상이면 활성화
                                    onClick={() => factstatus >= 5 && navigate('/TamSam')}
                                >
                                    Fact 보고서 <br /> 작성하기
                                </Button>
                                {/* Fact pptx 다운로드하기 */}
                                {/* <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 5  ? (status < 15 ?theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 5 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 5} // 상태가 9 이상이면 활성화
                                    onClick={() => { if (status >= 5) window.location.href = `${API_URL}/file/download/fact_${uid}.pptx`; }}
                                >
                                    Fact pptx <br />다운로드하기
                                </Button> */}
                            </Grid>
                            {/* 그리드 2: Insight */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: insightstatus >= 5 ? (insightstatus < 6 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: insightstatus >= 5 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={insightstatus < 5} // 상태가 19 이상이면 활성화
                                    onClick={() => insightstatus >= 5 && navigate('/InsightTable')}
                                >
                                    SOM 후보 도출 <br />
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: insightstatus >= 6 ? (insightstatus < 7 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: insightstatus >= 6 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={insightstatus < 6} // 상태가 19 이상이면 활성화
                                    onClick={() => insightstatus >= 6 && navigate('/tamsamInsight')}
                                >
                                    Insight 보고서 <br />작성하기
                                </Button>
                                {/* <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: status >= 17 ? (status < 21 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: status >= 17 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={status < 17} // 상태가 9 이상이면 활성화
                                    onClick={() => { if (status >= 17) window.location.href = `${API_URL}/file/download/insight_${uid}.pptx`; }}
                                >
                                    Insight pptx <br />다운로드하기
                                </Button> */}
                            </Grid>
                            {/* 그리드 3: Strategy */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: strategystatus >= 1 ? (strategystatus < 3 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: strategystatus >= 1 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={strategystatus < 1} // 상태가 29 이상이면 활성화
                                    onClick={() => strategystatus >= 1 && navigate('/EvaluatePersona')}
                                >
                                    SOM 후보 평가 <br />최종 SOM 구체화
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: strategystatus >= 3 ? (strategystatus < 9 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: strategystatus >= 3 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={strategystatus < 3}
                                    onClick={() => strategystatus >= 3 && navigate('/StrategyGTM')}
                                >
                                    GTM 전략 <br />
                                </Button>
                                {/* strategy pptx 다운로드하기 */}
                                <Button
                                    variant="contained"
                                    style={{
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: strategystatus >= 9 ? (strategystatus < 999 ? theme.palette.primary.main : '#1561ac') : undefined,
                                        color: strategystatus >= 9 ? theme.palette.primary.contrastText : undefined
                                    }}
                                    disabled={strategystatus < 9} // 상태가 29 이상이면 활성화
                                    onClick={() => { if (strategystatus >= 9) window.location.href = `${API_URL}/file/download/strategy_${uid}.pptx`; }}
                                >
                                    Strategy 보고서 <br />작성하기
                                </Button>
                            </Grid>
                            {/* 그리드 4: Action */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    style={{ width: '100%' }}
                                    disabled={actionstatus < 9} // 상태가 39 이상이면 활성화
                                    onClick={() => actionstatus >= 9 && navigate('/ActionDetail')}
                                >
                                    Action 보고서 <br />작성하기
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>



                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3} style={{ height: '100%' }}>
                            {/* 그리드 1: Fact */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div
                                    style={{ width: '100%', height: '100px', border: '2px dashed #ccc', textAlign: 'center', cursor: 'pointer' }}
                                    onDrop={handleDrop}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onClick={() => document.getElementById('fact-file-input').click()} // 클릭 시 파일 탐색기 열기
                                >
                                    {selectedFile ? (
                                        <p style={{ lineHeight: '30px' }}>{selectedFile.name}</p>
                                    ) : (
                                        <p style={{ lineHeight: '30px' }}>파일을 드래그 하거나 <br />클릭하여 파일을 찾으세요.</p>
                                    )}
                                </div>
                                <input
                                    id="fact-file-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <Button
                                    variant="contained"
                                    disabled={!selectedFile || factstatus < 5} // 파일이 선택되지 않은 경우 비활성화
                                    onClick={() => setOpenConfirmFact(true)}
                                    style={{ marginTop: '10px', width: '100%' }}
                                >
                                    Fact 보고서 <br /> 업로드하기
                                </Button>
                            </Grid>

                            {/* 그리드 2: Insight */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div
                                    style={{ width: '100%', height: '100px', border: '2px dashed #ccc', textAlign: 'center', cursor: 'pointer' }}
                                    onDrop={handleInsightDrop}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onClick={() => document.getElementById('insight-file-input').click()} // 클릭 시 파일 탐색기 열기
                                >
                                    {insightSelectedFile ? (
                                        <p style={{ lineHeight: '30px' }}>{insightSelectedFile.name}</p>
                                    ) : (
                                        <p style={{ lineHeight: '30px' }}>파일을 드래그 하거나 <br />클릭하여 파일을 찾으세요.</p>
                                    )}
                                </div>
                                <input
                                    id="insight-file-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleInsightFileChange}
                                />
                                <Button
                                    variant="contained"
                                    disabled={!insightSelectedFile || insightstatus < 6} // 파일이 선택되지 않은 경우 비활성화
                                    onClick={() => setOpenConfirmInsight(true)}
                                    style={{ marginTop: '10px', width: '100%' }}
                                >
                                    Insight 보고서 <br /> 업로드하기
                                </Button>
                            </Grid>

                            {/* 그리드 3: Strategy */}
                            <Grid item xs={3}>
                                <div
                                    style={{ width: '100%', height: '100px', border: '2px dashed #ccc', textAlign: 'center', cursor: 'pointer' }}
                                    onDrop={handleStrategyDrop}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onClick={() => document.getElementById('strategy-file-input').click()} // 클릭 시 파일 탐색기 열기
                                >
                                    {strategySelectedFile ? (
                                        <p style={{ lineHeight: '30px' }}>{strategySelectedFile.name}</p>
                                    ) : (
                                        <p style={{ lineHeight: '30px' }}>파일을 드래그 하거나 <br />클릭하여 파일을 찾으세요.</p>
                                    )}
                                </div>
                                <input
                                    id="strategy-file-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleStrategyFileChange}
                                />
                                <Button
                                    variant="contained"
                                    disabled={!strategySelectedFile || strategystatus < 9} // 파일이 선택되지 않은 경우 비활성화
                                    onClick={() => setOpenConfirmStrategy(true)}
                                    style={{ marginTop: '10px', width: '100%' }}
                                >
                                    Strategy 보고서 <br /> 업로드하기
                                </Button>
                            </Grid>

                            {/* 그리드 4: Action */}
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div
                                    style={{ width: '100%', height: '100px', border: '2px dashed #ccc', textAlign: 'center', cursor: 'pointer' }}
                                    onDrop={handleDrop}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onClick={() => document.getElementById('action-file-input').click()} // 클릭 시 파일 탐색기 열기
                                >
                                    {selectedFile ? (
                                        <p style={{ lineHeight: '30px' }}>{selectedFile.name}</p>
                                    ) : (
                                        <p style={{ lineHeight: '30px' }}>파일을 드래그 하거나 <br />클릭하여 파일을 찾으세요.</p>
                                    )}
                                </div>
                                <input
                                    id="action-file-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <Button
                                    variant="contained"
                                    disabled={!selectedFile || actionstatus < 99} // 파일이 선택되지 않은 경우 비활성화
                                    onClick={() => actionstatus >= 99 && navigate('/ActionDetail')}
                                    style={{ marginTop: '10px', width: '100%' }}
                                >
                                    Action 보고서 <br /> 업로드하기
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>

                    {/* Add the report viewing buttons */}
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3} style={{ height: '100%' }}>
                            {routes.map((route, index) => (
                                <Grid item xs={3} key={index}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        disabled={route.check < 99}
                                        onClick={() => handleButtonClick(route)}
                                        style={{
                                            height: '100%',
                                            cursor: route.check === 99 ? 'pointer' : 'not-allowed',
                                            boxShadow: route.check === 99 ? theme.shadows[5] : 'none',
                                            backgroundColor: route.check === 99 ? theme.palette.primary.main : theme.palette.grey[300],
                                            color: route.check === 99 ? theme.palette.primary.contrastText : theme.palette.text.primary
                                        }}
                                    >
                                        {route.label}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccessDialog} autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseErrorDialog} autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
            <FactConfirmDialog onClose={() => setOpenConfirmFact(false)} onConfirm={handleFileUpload} />
            <InsightConfirmDialog onClose={() => setOpenConfirmInsight(false)} onConfirm={handleInsightFileUpload} />
            <StrategyConfirmDialog onClose={() => setOpenConfirmStrategy(false)} onConfirm={handleStrategyFileUpload} />
            {/* 백그라운드 파일 리스트 */}
            {isBackgroundOpen && (
                <div style={{ marginBottom: '20px' }}>
                    {backgroundList.map((fileName, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            onClick={() => handleBackgroundFileClick(fileName)}
                            style={{ marginBottom: '5px', width: '100%' }}
                        >
                            {index + 1}번 자료
                        </Button>
                    ))}
                </div>
            )}

            {pdfUrl && (
                <iframe
                    src={pdfUrl}
                    title="PDF Viewer"
                    style={{ width: '100%', height: '100vh', border: 'none' }}
                ></iframe>
            )}
        </div>
    );
};

export default ConsultantFile;
