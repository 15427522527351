import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Alert, CircularProgress } from '@mui/material';
const API_URL = process.env.REACT_APP_API_ORIGIN;

const drawerWidth = 240;

export default function Consulting() {
    const toggleDrawer = () => {
        setOpen(!open);
      };
  const [open, setOpen] = React.useState(true);
  const [consultingData, setConsultingData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();

  // Consultant의 정보
  var userType = localStorage.getItem('userType');
  var uid = localStorage.getItem('uid');

  function getStatusText(status) {
    switch (status) {
      case 1:
        return "승인"; // Approved
      case -1:
        return "거부"; // Rejected
      case 0:
        return "승인 대기 중"; // Pending approval
      default:
        return "상태 미정"; // Undefined status or any other default message you want
    }
  }

  const handlePaperClick = (consulting) => {
    console.log('Paper clicked:', consulting);
    //navigate to ConstultingTable.js
    navigate('/ConsultantFile');
    //send array clientId to localStorage
    localStorage.setItem('clientId', consulting.clientId);
  };

  // Consultant와 Consulting으로 연결된 Customer를 백앤드로부터 가져옴
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getConsultEngagement/consultantUid=` + uid);
        let data = await response.json(); // Assuming 'data' is an array        
        if (Array.isArray(data) && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            console.log(data);
            const usernameResponse = await fetch(`${API_URL}/auth/getUsernameByUserId/userId=` + data[i].clientId);
            const username = await usernameResponse.text();
            data[i].username = username;
            // data = data.map(item => ({ ...item, username }));

            // console.log("username", username);
            
          }
        }
      
        console.log("data", data);
      
        setConsultingData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally{
        setLoading(false);
      }
    }
    fetchData();
  }, []); // 빈 배열을 두번째 매개변수로 전달하여 컴포넌트가 마운트될 때만 실행



// 컬럼 정의
const columns = [
    { field: 'businessName', headerName: '사업명', flex: 1,headerClassName: 'header-bold'  },
    { field: 'username', headerName: '고객', flex: 1 },
    { field: 'startDate', headerName: '시작일', flex: 1 },
    { field: 'endDate', headerName: '상태', flex: 1, valueGetter: (params) => params.row.endDate || '진행 중' },
  ];

  const rows = consultingData.map((consulting, index) => ({
    id: index, // DataGrid는 고유 id 필요
    businessName: consulting.businessName || '사업명 미정',
    username: consulting.username || '무기명',
    startDate: consulting.startDate || '시작일 미상',
    endDate: consulting.endDate || '진행 중',
    originalData: consulting // 원본 데이터를 저장
  }));
return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
    
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2 ,fontWeight: 600}}>
                컨설팅 목록
              </Typography>
            {loading ? (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
              <CircularProgress />
              <span style={{ marginTop: '10px', fontWeight: 'bold' }}>컨설팅 목록 불러오는중..</span>
            </div>
            ) : consultingData && consultingData.length === 0 ? (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                컨설팅이 존재하지 않습니다. 컨설팅을 생성해주세요.
              </div>
            ) : (
              <div style={{ height: '75vh', width: '100%' }}>
                <Alert severity="info">컨설팅을 클릭하면 이동합니다.</Alert>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10, 20]}
                  onRowClick={(params) => handlePaperClick(params.row.originalData)}
                  sx={{
                    '& .MuiDataGrid-root': {
                      border: '3px solid #ddd', // 테두리 추가 (옅은 회색)
                      borderRadius: '8px', // 모서리를 둥글게
                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // 테이블 그림자 추가
                      backgroundColor: '#ffffff', // 테이블 배경색
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: '#f5f5f5', // 헤더 배경색 (연한 회색)
                      color: '#333', // 헤더 텍스트 색상
                      fontWeight: 'bold',
                      borderBottom: '2px solid #ccc', // 헤더 하단 테두리
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 'bold', // 컬럼 헤더 제목 굵게
                      fontSize: '1rem', // 텍스트 크기 조정
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      backgroundColor: '#fafafa', // 테이블 배경색 (약간 더 밝은 회색)
                    },
                    '& .MuiDataGrid-row': {
                      '&:hover': {
                        backgroundColor: '#f0f0f0', // 행 호버 시 배경색 (밝은 파란색)
                        cursor: 'pointer',
                        // boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.1)', // 호버 시 약간의 강조 효과
                      },
                    },
                    '& .MuiDataGrid-cell': {
                      borderBottom: '1px solid #e0e0e0', // 셀 하단 테두리
                    },
                    '& .MuiDataGrid-footerContainer': {
                      backgroundColor: '#f5f5f5', // 하단 페이지네이션 배경색
                      borderTop: '1px solid #ccc', // 페이지네이션 상단 테두리
                    },
                  }}
                />

              </div>
            )}
          </Container>
        </Box>
    </Box>
);
}

