import * as React from 'react';
import Box from '@mui/material/Box';
import 'react-data-grid/lib/styles.css';
import { useEffect, useState, useMemo } from 'react';
import '../../styles/ConsultingTable.css';
import Textarea from '@mui/joy/Textarea';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Button} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_ORIGIN;


export default function ComparePersona() {
  const [rows, setRows] = useState([]);
  const [clientUid, setClientUid] = useState('');
  const [editIdx, setEditIdx] = useState(-1); 
  const [open,setOpen] = useState(false);//제출확인창
  const [openSnackbar,setOpenSnackbar] = useState(false);//수정완료창
  const navigate = useNavigate();
  const [persona1, setPersona1] = useState({});
  const [persona2, setPersona2] = useState({});
  const [persona3, setPersona3] = useState({});


  useEffect(() => {
    const fetchData = async () => {
      try {
        let client_uid;

        if (localStorage.getItem('userType') === 'consultant')
        {
          const clientId = localStorage.getItem('clientId');
          const clientUidResponse = await axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
              headers: {
                  'Content-Type': 'application/json',
              },
          });
          client_uid = (clientUidResponse.data);
        }
        else//client가 접속
        {
          client_uid = (localStorage.getItem('uid'));
        }
        console.log("!",client_uid)
        setClientUid(client_uid)

        const [response] = await Promise.all([
          fetch(`${API_URL}/persona/getPersonaQuestionsByUid/uid=${client_uid}`),
        ]);
        const data = await response.json();
        console.log("questions",data);

        // data의 .questionId를 "_"를 기준으로 split
        for (let i = 0; i < data.length; i++) {
          const questionId = data[i].questionId;
          const splitQuestionId = questionId.split('_');
          data[i].questionId = splitQuestionId[1];
        }

        // questionId를 기준으로 정렬
        data.sort((a, b) => a.questionId - b.questionId);

        setRows(data);
        console.log("row",rows);

        const personaResponse1 = await fetch(`${API_URL}/persona/getPersonaByPersonaId/uid_number=${client_uid}_1`);
        const persona1_data = await personaResponse1.json();
        console.log("persona 1",persona1_data);

        const personaResponse2 = await fetch(`${API_URL}/persona/getPersonaByPersonaId/uid_number=${client_uid}_2`);
        const persona2_data = await personaResponse2.json();
        console.log("persona 2",persona2_data);

        const personaResponse3 = await fetch(`${API_URL}/persona/getPersonaByPersonaId/uid_number=${client_uid}_3`);
        const persona3_data = await personaResponse3.json();
        console.log("persona 3",persona3_data);
        
        // console.log("data",questionsWithSavedAnswer);
        setPersona1(persona1_data);
        setPersona2(persona2_data);
        setPersona3(persona3_data);
      }

      
      catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
    console.log('rows',rows);

  }, []);

  const getUidById = async (clientId) => {
    try {
      const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
      const clientUid = await clientUidResponse.text();
      return clientUid;
    }
    catch (error) {
      console.error('Error getting clientId:', error);
    }
  }

  const sendUpdateToServer = async (persona_idx, question_idx) => {
    try {
      const personaId = `${clientUid}_${persona_idx}`;
      const questionId = question_idx;
      let updatedAnswer;
  
      switch(persona_idx) {
        case 1:
          updatedAnswer = persona1[`answer${question_idx}`];
          break;
        case 2:
          updatedAnswer = persona2[`answer${question_idx}`];
          break;
        default:
          updatedAnswer = persona3[`answer${question_idx}`];
          break;
      }
  
      console.log("Persona data:", persona1);  // 전체 데이터 로그
      console.log("Sending message:", updatedAnswer);
  
      const response = await axios.post(
        `${API_URL}/persona/setPersonaAnswer/uid_number=${personaId}/question_id=${questionId}`, 
        JSON.stringify({ answer: updatedAnswer }), 
        {
          headers: {
            'Content-Type': 'application/json'  // 콘텐트 타입 명시
          }
        } 
      );
  
      console.log('Updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating data:', error);
      // 여기에서 사용자에게 오류를 알리는 UI 업데이트가 필요할 수 있습니다.
    }
  };
  
  const handleEdit = (idx) => {
    setEditIdx(idx); // 편집할 행의 인덱스를 설정합니다.
  };

  const handleSave = async (persona_idx, question_idx) => {
    setEditIdx(-1); // 편집 모드를 종료합니다.
    await sendUpdateToServer(persona_idx,question_idx); // 변경된 행을 서버로 전송합니다.
    setOpenSnackbar(true);
  };

  const handleChange = (e, persona_idx, answer_idx) => {
    let updatedPersona;
    if(persona_idx === 1){
      updatedPersona = {...persona1, [answer_idx]: e.target.value};
      setPersona1(updatedPersona);
    }
    else if(persona_idx === 2){
      updatedPersona = {...persona2, [answer_idx]: e.target.value};
      setPersona2(updatedPersona);
    }
    else{
      updatedPersona = {...persona3, [answer_idx]: e.target.value};
      setPersona3(updatedPersona);
    }
  };

return (

  <Box sx={{ height: "100%" ,marginTop:20 , marginLeft: 5, marginRight: 5, marginBottom: 20 ,backgroundColor: 'white'}}>
        {/* <Table2/> */}
        <table className="MyTable">
      <thead>
        <tr >
          <th rowSpan={2} colSpan={2} style={{ textAlign: 'center' }}>특징</th>
          <th colSpan={3} style={{ textAlign: 'center' }}>SOM시장 후보</th>
        </tr>
        <tr>
          <th style={{ textAlign: 'center' }}>페르소나 1: {persona1.personaName}</th>
          <th style={{ textAlign: 'center' }}>페르소나 2: {persona2.personaName}</th>
          <th style={{ textAlign: 'center' }}>페르소나 3: {persona3.personaName}</th>
        </tr>
      </thead>
      <tbody>
      {rows.map((item, idx) => {
        // 병합되어야 할 셀인지 확인
        return (
          <tr key={idx}>
              <td style={{ textAlign: 'center' }}>{idx+1}</td>
              <td>{item.question}</td>
              <td onClick={() => handleEdit(idx)} style={{ position: 'relative' }}>
                {editIdx === idx ? (
                  <textarea
                    value={persona1[`answer${idx + 1}`]}
                    onChange={(e) => handleChange(e, 1, `answer${idx+1}`)}
                    onBlur={() => handleSave(1,idx+1)}
                    style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                  />

                ) : (
                  <span>{persona1[`answer${idx+1}`]}</span>
                )}
              </td>
              <td onClick={() => handleEdit(idx)} style={{ position: 'relative' }}>
                {editIdx === idx ? (
                  <textarea
                    style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                    value={persona2[`answer${idx + 1}`]}
                    onChange={(e) => handleChange(e, 2, `answer${idx+1}`)}
                    onBlur={() => handleSave(2,idx+1)}
                  />
                ) : (
                  <span>{persona2[`answer${idx+1}`]}</span>
                )}</td>
              <td onClick={() => handleEdit(idx)} style={{ position: 'relative' }}>
                {editIdx === idx ? (
                  <textarea
                    style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                    value={persona3[`answer${idx + 1}`]}
                    onChange={(e) => handleChange(e, 3, `answer${idx+1}`)}
                    onBlur={() => handleSave(3,idx+1)}
                  />
                ) : (
                        <span>{persona3[`answer${idx+1}`]}</span>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: 'bottom', 
          horizontal: 'center',
        }}>
          <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          수정사항이 저장되었습니다!
        </Alert>
      </Snackbar>
  </Box>
  
);
}

