import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider, Typography, Divider, Button } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const InsightReport = () => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/home');
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };



    return (
            <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900] }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left" sx={{mb:2}}>
                                    Insight단계 Report
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sx={{ height: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {/* 여기에 페이지의 중앙에 텍스트를 추가하십시오. */}
                                <Typography variant="h6" align="center">
                                    Insight 단계의 모든 질문에 답변을 완료하셨습니다.
                                </Typography>
                                <Typography variant="h6" align="center">
                                    고객님께서 하고자 하는 사업에 대한 SOM(Serviceable Obtainable Market) 시장과
                                </Typography>
                                <Typography variant="h6" align="center">
                                    목표 고객에 대한 특성을 도출하도록 하겠습니다.
                                </Typography>
                                <Typography variant="h6" align="center">
                                    완료되면 email로 알려드리겠습니다. 
                                </Typography>
                                <Grid container spacing={2} justifyContent="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={handleButtonClick}
                                    sx={{mt:5}}
                                >
                                    홈으로
                                </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
    );
};

export default InsightReport;
