import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Documents from '../Customer/Document';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
const API_URL = process.env.REACT_APP_API_ORIGIN;


const drawerWidth = 240;

export default function Dashboard() {

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [open, setOpen] = React.useState(false);
  const [activeView, setActiveView] = React.useState('upload');
  const [uploadedFileData, setUploadedFileData] = React.useState([]);

  

  const readExcel = async (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      if (!e.target) return;
      const bufferArray = e.target.result;
      const fileInformation = XLSX.read(bufferArray, {
        type: 'buffer',
        cellText: false,
        cellDates: true,
      });
      const sheetName = fileInformation.SheetNames[0];
      const rawData = fileInformation.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(rawData);
      
      // Assuming mappingDataToTable is a function that formats the data
      if (data) {
        const formattedData = mappingDataToTable(data);
        setUploadedFileData(formattedData);
      }
    };
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    readExcel(file);
  };

  //User

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(uploadedFileData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "QuestionsAnswers");

    const exportFileName = "QuestionsAnswers.xlsx";
    XLSX.writeFile(wb, exportFileName);
  };

  const [question] = React.useState([
    { question: "질문1: 업종은 무엇인가요?", answer: "" },
    // ... other predefined questions
  ]);


return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
    
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* Upload Button */}
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                  id="raised-button-file"
                />
                <label htmlFor="raised-button-file">
                {/* <AuthToken> */}
                  <Button variant="contained" component="span">
                    Upload Excel File
                  </Button>
                {/* </AuthToken> */}
                </label>
              </Grid>
              <Grid item xs={6}>
                {/* Documents Component with new questions and answers */}
                <Documents questions={uploadedFileData} />
                {/* Download Button */}
                {/* <AuthToken> */}
                <Button variant="contained" onClick={
                  
                  downloadExcel}>
                  Download Questions and Answers
                </Button>
                {/* </AuthToken> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  </Box>
);
}

function mappingDataToTable(data) {
  // Implement the logic to format the data as needed
  return data; // For example purpose
}
