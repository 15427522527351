import React, { useState } from 'react';
import { Box, CssBaseline, ThemeProvider, Typography, Divider, Button, List, ListItem, ListItemText, IconButton, Grid } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete'; // 삭제 아이콘
import Container from '@mui/material/Container';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_ORIGIN;

const BackgroundInfo = () => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const [selectedFiles, setSelectedFiles] = useState([]); // 여러 파일을 저장하는 배열
    const [openconfirmfact, setOpenConfirmFact] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);

    const handleDialog = (title, message, isError = false) => {
        setDialogTitle(title);
        setDialogMessage(message);
        if (isError) {
            setOpenErrorDialog(true);
        } else {
            setOpenSuccessDialog(true);
        }
    };

    const handleCloseSuccessDialog = () => {
        setOpenSuccessDialog(false);
        navigate('/maincustomer'); // 확인 버튼을 클릭하면 페이지 이동
    };

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };

    const handleButtonClick = () => {
        navigate('/maincustomer');
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    // 파일 드래그 앤 드롭 핸들러
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const newFiles = [...event.dataTransfer.files]; // 새로 드래그한 파일들
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]); // 기존 파일과 새 파일 합치기
    };

    // 파일 탐색기에서 파일 선택 핸들러
    const handleFileChange = (event) => {
        const newFiles = [...event.target.files]; // 새로 선택된 파일들
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]); // 이전 파일들에 새 파일들 추가
    };

    // 파일 목록에서 클릭하여 파일 삭제하는 핸들러
    const handleFileDelete = (fileIndex) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex)); // 해당 인덱스의 파일을 삭제
    };

    const handleFileUpload = async () => {
        try {
            const uid = localStorage.getItem('uid');

            for (let i = 0; i < selectedFiles.length; i++) {
                const formData = new FormData();
                const file = selectedFiles[i];

                // 파일이 pdf인지 확인
                if (file.name.split('.').pop() !== 'pdf') {
                    handleDialog('업로드 실패', '파일 형식이 올바르지 않습니다. PDF 파일을 업로드해주세요.');
                    return;
                }

                // 파일 이름 수정: background_{uid}_{i + 1}.pdf
                const modifiedFileName = `background_${uid}_${i + 1}.pdf`;
                formData.append('file', file, modifiedFileName);

                // 파일 업로드 요청
                await Axios.post(`${API_URL}/file/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            // 모든 파일 업로드가 완료된 후 성공 메시지
            setTimeout(() => {
                handleDialog('업로드 성공', '파일 업로드가 성공적으로 완료되었습니다.');
            }, 2000);

        } catch (error) {
            console.error('Error uploading file:', error);
            handleDialog('업로드 실패', '업로드에 실패했습니다. 잠시 후 다시 시도해주십시오.');
        }
    };

    const FactConfirmDialog = ({ onClose, onConfirm }) => {
        const [confirming, setConfirming] = useState(false);

        const handleConfirmClick = async () => {
            setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
            await onConfirm(); // 실제 작업 수행
            setOpenConfirmFact(false);
            setConfirming(false);
        };
        return (
            <Dialog open={openconfirmfact} onClose={onClose}>
                <DialogTitle>파일을 업로드 하시겠습니까?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">업로드 된 파일을 컨설턴트가 확인할 수 있습니다.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
                        {confirming ? <CircularProgress size={24} /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const defaultTheme = createTheme();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900] }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left">
                                    고객 참고자료 제출
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid container justifyContent="center" alignItems="center" sx={{ height: '50vh' }}>
                                <Typography variant="h6" align="center">
                                    고객님께서 하고자 하는 사업의 배경을 설명할 수 있는 참고자료를 업로드 할 수 있습니다.
                                </Typography>
                                <Typography variant="h6" align="center">
                                    사업계획서, 아이디어 설명 자료 등이 있으신 고객께서는 pdf 형식으로 아래에 업로드 해주시기 바랍니다.
                                </Typography>
                                <Typography variant="h6" align="center">
                                    해당 자료가 없으신 고객께서는 다음으로 넘어가서 질문에 답변하여 주십시오.
                                </Typography>
                                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    {/* 드래그 앤 드롭 영역 및 파일 목록 */}
                                    <div
                                        style={{ width: '100%', padding: '20px', border: '2px dashed #ccc', textAlign: 'center', cursor: 'pointer', marginBottom: '20px' }}
                                        onDrop={handleDrop}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        onClick={() => document.getElementById('fact-file-input').click()} // 클릭 시 파일 탐색기 열기
                                    >
                                        {selectedFiles.length > 0 ? (
                                            <List sx={{ width: '100%' }}>
                                                {selectedFiles.map((file, index) => (
                                                    <ListItem
                                                        key={index}
                                                        secondaryAction={
                                                            <IconButton
                                                                edge="end"
                                                                onClick={(e) => {
                                                                    e.stopPropagation(); // 클릭 이벤트 전파 방지 (파일 탐색기 열리지 않도록)
                                                                    handleFileDelete(index);
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemText primary={file.name} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : (
                                            <Typography variant="h6" align="center">
                                                파일을 드래그 앤 드롭하거나 클릭하여 업로드할 파일을 선택하세요.
                                            </Typography>
                                        )}
                                    </div>
                                    <input
                                        id="fact-file-input"
                                        type="file"
                                        style={{ display: 'none' }}
                                        multiple // 여러 파일 선택 가능하게 수정
                                        onChange={handleFileChange}
                                    />

                                    {/* 업로드 버튼 */}
                                    <Button
                                        variant="contained"
                                        disabled={selectedFiles.length === 0} // 파일이 선택되지 않은 경우 비활성화
                                        onClick={() => setOpenConfirmFact(true)}
                                        style={{ marginTop: '10px', width: '100%' }}
                                    >
                                        고객 참고자료 업로드하기
                                    </Button>
                                </Grid>

                                <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            onClick={handleButtonClick}
                                            style={{ width: '100%' }} // 동일한 너비 설정
                                        >
                                            다음
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{dialogMessage}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSuccessDialog} autoFocus>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{dialogMessage}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseErrorDialog} autoFocus>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
                <FactConfirmDialog onClose={() => setOpenConfirmFact(false)} onConfirm={handleFileUpload} />
            </Box>
        </ThemeProvider>
    );
};

export default BackgroundInfo;
